import config from "config/config";
import getTokens from "api/getTokens";

const  { baseUrl, clientUrl } = config;
/* *********************

********************** */

export default function twoFactor(user, code) {
  // const { refresh } = getTokens();
  const endpoint = `${baseUrl}/v1/auth/verify-otp`;
  let access = "";
  const tokens = getTokens();

  if (tokens && tokens.access && tokens.access.token) {
    access = tokens.access.token;
  } else {
    window.location.href = "/login";
  }

  const options = {
    method: "POST",
    mode: "cors",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `${clientUrl || ""}`,
      "Access-Control-Allow-Credentials": true,
      Authorization: `bearer ${access}`,
    },
    body: JSON.stringify({ user, code }),
  };
  return fetch(endpoint, options)
    .then((response) => {
      if (response.status < 200 || response.status > 299)
        throw new Error();

      return response.json();
    })
    .catch((e) => {
      console.log("error", e);
    });
}
