const dataBreach = {
  en: {
    translation: {
      date: "Date",
      threat_actor_name: "Threat actor",
      description: "Description",
      victim_nation: "Nation",
      attack_type: "Attack type",
      total_records: "Total records",
      records_found: "Records found",
      columns: "Columns",
      results: "Results",
      details: "Details",
      name: "Name",
    },
  },

  it: {
    translation: {
      date: "Data",
      threat_actor_name: "Threat Actor",
      description: "Descrizione",
      victim_nation: "Nazione",
      attack_type: "Tipo di attacco",
      total_records: "Totale registrazioni",
      records_found: "Registrazioni trovate",
      columns: "Colonne",
      results: "Risultati",
      details: "Dettagli",
      name: "Nome",
    },
  },
};

export default dataBreach;
