// @mui material components
import Grid from "@mui/material/Grid";
import getRequests from "api/reportsrequests/getRequests";
import print from "api/print/print";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// MUI Icons
import IconButton from "@mui/material/IconButton";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ForwardIcon from "@mui/icons-material/Forward";
import Tooltip from "@mui/material/Tooltip";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import moment from "moment";
import ServerError from "utilities/ServerError";

function Alerts() {
  const [reports, setReports] = useState([]);
  const [serverError, setServerError] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    getRequests(1, 3)
    .then((res) => {
        if (res?.results?.length > 0) setReports(res.results);
    })
    .catch((err) => {
        setServerError(true);
        // eslint-disable-next-line no-console
        console.log("error while getting reports", err);
    });
  }, []);

  function getDate(date) {
    return moment(date).format("DD-MM-YYYY");
  }

  function getTime(date) {
    return moment(date).format("HH:mm");
  }

  function getTextStatus(status) {
    if (status && status === "pending") {
        return <div className="report-status-text pending">{t(`analytics.report_pending`)}</div>;
    }
    return <div className="report-status-text error">{t(`analytics.report_error`)}</div>;
  }

  return (
    <Grid item xs={12} md={6} lg={5} paddingTop="0px">
      <MDBox
        className="boxes-border-radius alerts"
        sx={{
          background: "white",
          boxShadow:
            "0rem 0.25rem 0.375rem -0.0625rem rgb(0 0 0 / 10%), 0rem 0.125rem 0.25rem -0.0625rem rgb(0 0 0 / 6%)",
        }}
      >
        <MDBox className="home-box-header" sx={{ background: "#2C74E1" }}>
          <MDTypography variant="header3" color="light" fontWeight="bold">
            {t(`analytics.latest_reports`)}
          </MDTypography>
        </MDBox>
        {!serverError ? (
          <MDBox p={2}>
            {reports && reports.length > 0 ? (
              reports.map((report) => (
                <div
                  key={report.id.toString()}
                  className="alert-notification-container"
                >
                  <div className="alert-notification-date-container">
                    <span>
                      <CalendarTodayIcon />
                      {report.date ? getDate(report.date) : ""}
                    </span>
                    <span>{report.date ? getTime(report.date) : ""}</span>
                  </div>
                  <div className="alert-notification-text-container">
                    <div className="report-dot-status-container">
                        <span>Stato:</span><div className={`report-dot-status ${report.status}`} />
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                      <div style={{ lineHeight: "20px" }}>
                          {
                              report.status && report.status === "fulfilled" ?
                              <IconButton
                                  size="small"
                                  disableRipple
                                  style={{ fontSize: "14px", padding: "0", border: "none", color: "var(--blue-color)" }}
                                  onClick={() => {
                                      print(report.pathS3.replaceAll("/", "_"), report.indexKey)
                                        .then((res) => res.blob())
                                        .then((blob) => {
                                          const fileURL = URL.createObjectURL(blob);
                                          // Open the URL on new Window
                                          const pdfWindow = window.open();
                                          pdfWindow.location.href = fileURL;
                                        })
                                        .catch((err) => {
                                          // eslint-disable-next-line
                                          console.log("errore frontend print", err);
                                        });
                                    }}
                              >
                                  {t('analytics.open')}
                              </IconButton> :
                              getTextStatus(report.status)
                          }
                      </div>
                      <div style={{ lineHeight: "20px" }}>
                          {
                              report.stealer && report.stealer.id ?
                              <Tooltip title={t('analytics.details_stealer')} placement="top">
                                <IconButton
                                    size="small"
                                    disableRipple
                                    style={{ fontSize: "14px", padding: "0", border: "none", color: "var(--blue-color)" }}
                                    onClick={() => {
                                        window.open(`/detailsStealer?${report.stealer.id}`, "_blank").focus();
                                    }}
                                >
                                  <ForwardIcon />
                                    {/* {t('analytics.details_stealer')} */}
                                </IconButton>
                              </Tooltip> :
                              null
                          }
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <h5>{t("analytics.no_reports")}</h5>
            )}
          </MDBox>
        ) : (
          <MDBox className="server-error-container">
            <ServerError />
          </MDBox>
        )}
      </MDBox>
    </Grid>
  );
}

export default Alerts;
