
const handleOrderBy = function(orderBy) {
  let order = "sortDisabled";

  if (orderBy === "domain") {
    order = "domain:asc";
  } else if (orderBy === "domainReverse") {
    order = "domain:desc";
  } else if (orderBy === "date") {
    order = "dateProcessed:asc,date:asc";
  } else if (orderBy === "dateReverse") {
    order = "dateProcessed:desc,date:desc";
  } else if (orderBy === "nation") {
    order = "countryCode:asc";
  } else if (orderBy === "nationReverse") {
    order = "countryCode:desc";
  } else if (orderBy === "hash") {
    order = "filelistHash:asc";
  } else if (orderBy === "hashReverse") {
    order = "filelistHash:desc";
  }

  return order;
};

export {
  handleOrderBy
};

