/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";
import { useTranslation } from "react-i18next";

import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

import forgotPassword from "api/auth/forgotPassword";
import addLog from "api/logs/addLog";
import MDTypography from "components/MDTypography";

// Import icons
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Alert } from "@mui/material";
import Error from "../components/error/Error";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [passwordSent, setpasswordSent] = useState(false);
  const [invalidPasswordError, setInvalidPasswordError] = useState(false);
  const [helpMessage, setHelpMessage] = useState(false);
  const { t } = useTranslation();

  const validateEmail = (em) =>
    String(em)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );

  const requestChangePassword = () =>
    forgotPassword(email)
      .then((resp) => {
        // eslint-disable-next-line
        console.log("login success", resp);
        const logToSend = {
          email,
          type: "Forgot password",
          date: new Date(),
        };
        addLog(logToSend);
        if (validateEmail(email)) {
          setpasswordSent(true);
          setInvalidPasswordError(false);
        } else {
          setInvalidPasswordError(true);
        }
      })
      .catch((err) => {
        // eslint-disable-next-line
        console.log("error login", err);
        const logToSend = {
          email,
          type: "Forgot Password Failed",
          date: new Date(),
        };
        addLog(logToSend);
      });

  // TODO gestire errori

  return (
    <BasicLayout>
      <Card>
        <MDBox pt={2} pb={3}>
          <MDBox component="form" role="form">
            {!passwordSent ? (
              <>
                <MDBox mb={2}>
                  <MDInput
                    label="Email"
                    autoFocus
                    fullWidth
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && !passwordSent) {
                        e.preventDefault();
                        requestChangePassword();
                      }
                    }}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </MDBox>
                <MDBox mt={4} mb={1}>
                  <MDButton
                    style={{ background: "#669FF6", color: "white" }}
                    fullWidth
                    onClick={() => {
                      requestChangePassword();
                    }}
                  >
                    {t("passwordRecovery.reset_password")}
                  </MDButton>
                </MDBox>
              </>
            ) : (
              <MDBox
                sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "20px" }}
              >
                {/* Header */}
                <MDBox display="flex" flexDirection="column" alignItems="center" gap={1} mt={2}>
                  <CheckCircleOutlineIcon
                    color="success"
                    sx={{ width: 50, height: 50 }}
                    width={30}
                  />
                  <MDTypography variant="body1" fontSize={22} textAlign="center">
                    {t("passwordRecovery.check_your_email")}
                  </MDTypography>
                </MDBox>

                {/* Body */}
                <MDTypography variant="body1" fontSize="small" textAlign="center">
                  {t("passwordRecovery.email_sent_message")}
                </MDTypography>

                {/* Help */}
                <MDBox display="flex" flexDirection="column" alignItems="center">
                  {/* Button */}
                  <MDButton
                    disableRipple
                    color="info"
                    variant="text"
                    onClick={() => setHelpMessage(!helpMessage)}
                    sx={{
                      ":hover": {
                        textDecoration: "underline",
                      },
                    }}
                  >
                    <MDTypography color="info" fontSize={12} ml={1}>
                      {t("passwordRecovery.email_not_sent")}
                    </MDTypography>
                  </MDButton>

                  {/* Message */}
                  {helpMessage ? (
                    <Alert icon={false} fontSize={12} color="info">
                      <MDTypography fontSize={12} ml={1}>
                        {t("passwordRecovery.email_not_sent_message")}
                      </MDTypography>
                    </Alert>
                  ) : null}
                </MDBox>
              </MDBox>
            )}
            {invalidPasswordError ? <Error /> : null}
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default ForgotPassword;
