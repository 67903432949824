
/**
 * @readonly
 * @enum {number}
 */
export const ReasonToShow = {
  NONE: 0,
  CREDENTIALS: 1,
  COOKIE: 2,
  USERNAME: 3,
  CREDITCARDS: 4,
  IPS: 5,

  /**
   * @param {ReasonToShow} r
   * @param {Function} t
   * @returns {String}
   */
  getText: function(r, t) {
    switch (r) {
      case ReasonToShow.USERNAME:
        return t("stealers.username");
      case ReasonToShow.CREDENTIALS:
        return t("stealers.credentials");
      case ReasonToShow.COOKIE:
        return t("stealers.cookies");
      case ReasonToShow.CREDITCARDS:
        return t("stealers.creditcards");
      case ReasonToShow.IPS:
        return t("stealers.ip");
      default:
        return "";
    }
  },
};

/**
 * @param {Object} payload
 * @returns {ReasonToShow[]}
 */
export function getReasonToShow(payload) {
  
  const ret = [];

  if (!ret.includes(ReasonToShow.CREDENTIALS)) {
    const combos = payload?.results?.some(r => r?.results?.combos?.length > 0);
    if (combos)
      ret.push(ReasonToShow.CREDENTIALS);
  }

  if (!ret.includes(ReasonToShow.CREDENTIALS)) {
    const domains = payload?.results?.some(r => r?.results?.domains?.length > 0);
    if (domains)
      ret.push(ReasonToShow.CREDENTIALS);
  }

  if (!ret.includes(ReasonToShow.CREDENTIALS)) {
    const paths = payload?.results?.some(r => r?.results?.paths?.length > 0);
    if (paths)
      ret.push(ReasonToShow.CREDENTIALS);
  }

  if (!ret.includes(ReasonToShow.CREDENTIALS)) {
    const passwordsApps = payload?.results?.some(r => r?.results?.passwordsApps?.length > 0);
    if (passwordsApps)
      ret.push(ReasonToShow.CREDENTIALS);
  }

  if (!ret.includes(ReasonToShow.COOKIE)) {
    const cookies = payload?.results?.some(r => r?.results?.cookies?.length > 0);
    if (cookies)
      ret.push(ReasonToShow.COOKIE);
  }

  if (!ret.includes(ReasonToShow.CREDENTIALS)) {
    const usernamesAndDomains = payload?.results?.some(r => r?.results?.usernamesAndDomains?.length > 0);
    if (usernamesAndDomains)
      ret.push(ReasonToShow.CREDENTIALS);
  }

  if (!ret.includes(ReasonToShow.CREDENTIALS)) {
    const usernames = payload?.results?.some(r => r?.results?.usernames?.length > 0);
    if (usernames)
      ret.push(ReasonToShow.CREDENTIALS);
  }

  if (!ret.includes(ReasonToShow.CREDITCARDS)) {
    const creditcards = payload?.results?.some(r => r?.results?.creditcards?.length > 0);
    if (creditcards)
      ret.push(ReasonToShow.CREDITCARDS);
  }

  if (!ret.includes(ReasonToShow.IPS)) {
    const ips = payload?.results?.some(r => r?.results?.ips?.length > 0);
    if (ips)
      ret.push(ReasonToShow.IPS);
  }

  return ret;
};

