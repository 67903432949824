// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import MDInput from "components/MDInput";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import IconButton from "@mui/material/IconButton";

function TableCreditCards({ elements }) {
    const [isOpen, setIsOpen] = useState(true);
    const [filterNumber, setFilterNumber] = useState("");
    const [elementsToView, setElementsToView] = useState([]);
    const { t } = useTranslation();

    useEffect(() => { }, [elements]);

    function setContent() {
        const elementsCopy = [].concat(elements);
        const realElements = elementsCopy.filter(item => item.card.includes(filterNumber));
        setElementsToView(realElements);
    }

    useEffect(() => {
        setContent();
    }, []);

    useEffect(() => {
        setContent();
    }, [filterNumber]);

    console.log("elementsToView", elementsToView);

    function getContent(contents) {
        return contents.map((elem, index) => (
                <tr key={`credit-cards-for-client-${index.toString()}`}>
                    <td>
                        {elem.holder}
                    </td>
                    <td>{elem.card}</td>
                    <td>
                        {elem.expire}
                    </td>
                    <td>
                      {t(`stealers.${elem.cvv ? 'available' : 'not_available'}`)}
                    </td>
                </tr>
            ));
    }

    return (
        <div className="details-stealer-table-container">
            <div className="popupLabel">
                <IconButton
                    onClick={() => {
                        setIsOpen(!isOpen);
                    }}
                    style={{ color: "#344767" }}
                    size="small"
                    disableRipple
                >
                    <CreditCardIcon fontSize="medium" style={{ marginRight: "10px" }} />
                    <span className="details-stealer-table-title">
                        {t('stealers.creditcards')}
                    </span>
                    {isOpen ? <ArrowDropUpIcon fontSize="medium" /> : <ArrowDropDownIcon fontSize="medium" />}
                </IconButton>
            </div>
            {isOpen ? (
                <>
                    <span>
                        <MDInput
                            type="input"
                            label={t('stealers.card_number')}
                            value={filterNumber}
                            onChange={(e) => {
                                setFilterNumber(e.target.value);
                            }}
                            style={{ marginBottom: "10px", marginRight: "10px" }}
                        />
                    </span>
                    <div
                        className="table_users_list"
                        style={{ marginBottom: "50px" }}
                    >
                        <span className="elem-cell" />
                        <table className="table-content" cellSpacing="0" cellPadding="0">
                            <thead>
                                <tr>
                                    <th style={{ width: "30%" }}>
                                        {t("stealers.holder")}
                                    </th>
                                    <th style={{ width: "30%" }}>
                                        {t("stealers.card_number")}
                                    </th>
                                    <th style={{ width: "30%" }}>
                                        {t("stealers.expiration")}
                                    </th>
                                    <th stype={{ width: "10%" }}>
                                        {t("stealers.cvv")}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {elementsToView && elementsToView.length > 0 ? getContent(elementsToView) : null}
                            </tbody>
                        </table>
                    </div>
                </>
            ) : null}
        </div>
    );
}

// Typechecking props for the TableCreditCards
TableCreditCards.propTypes = {
    // eslint-disable-next-line
    elements: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default TableCreditCards;
