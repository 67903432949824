import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import IconButton from "@mui/material/IconButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Pagination from "layouts/dashboards/stealers/pagination";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

function TabNumbers({ data, showGoToDetails }) {
  const [originalData, setOriginalData] = useState([]);
  const [dataToShow, setDataToShow] = useState([]);
  // eslint-disable-next-line
  const [recordsShow, setRecordsShow] = useState(10);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const navigate = useNavigate();
  const { t } = useTranslation();

  function dynamicSort(property) {
    let sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      // eslint-disable-next-line
      property = property.substr(1);
    }
    // eslint-disable-next-line
    return function (a, b) {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      // eslint-disable-next-line
      const result = a[property] > b[property] ? -1 : a[property] < b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }

  function getArrayData() {
    const newDataToShow = [].concat(originalData);
    const newArraySliced = newDataToShow.slice((page - 1) * recordsShow, page * recordsShow);
    const pagesToSet = Math.ceil(data.length / recordsShow);
    setPages(pagesToSet);
    setDataToShow(newArraySliced);
  }

  useEffect(() => {
    data.sort(dynamicSort("value"));
    setOriginalData(data);
    getArrayData();
  }, []);

  useEffect(() => {
    getArrayData();
  }, [page, recordsShow, originalData]);

  useEffect(() => {
    setPage(1);
    getArrayData();
  }, [recordsShow]);

  useEffect(() => {
    data.sort(dynamicSort("value"));
    setOriginalData(data);
    getArrayData();
  }, [data]);

  return (
    <Card sx={{ height: "100%", position: "relative" }}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
        <MDTypography variant="h6">{t("statistics.countries")}</MDTypography>
      </MDBox>
      <div style={{ position: "absolute", right: "10px", top: "10px" }}>
        <select
          onChange={(e) => {
            setRecordsShow(e.target.value);
          }}
          value={recordsShow}
          className="selectDefault"
          style={{
            padding: "0.75rem",
            borderRadius: "0.375rem",
            borderColor: "#d2d6da",
            marginBottom: "30px",
          }}
        >
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={30}>30</option>
        </select>
      </div>
      <MDBox mt={3} px={2}>
        <Grid container alignItems="center">
          <div className="table_numbers_container">
            <div className="table_numbers_row intro_row">
              <div className="table_numbers_row_label">{t("statistics.country")}</div>
              <div className="table_numbers_row_value">{t("statistics.attacks")}</div>
            </div>
            {dataToShow.map((el, index) => (
              <div
                className="table_numbers_row"
                key={`table-row-nations-${index.toString()}-${el.value}`}
              >
                <div className="table_numbers_row_label">
                  {el.label && el.label.length > 2 ? el.label : t(`countryCode.${el.label}`)}
                </div>
                <div className="table_numbers_row_value">{el.value}</div>
              </div>
            ))}
          </div>
          {pages && pages > 1 ? (
            <div className="pagination_for_table_numbers">
              <Pagination setPage={setPage} pages={pages} active={page} />
            </div>
          ) : null}
          {showGoToDetails ? (
            <div style={{ margin: "30px auto", width: "100%", textAlign: "center" }}>
              <IconButton
                onClick={() => {
                  navigate("/detailStatisticsNations");
                  // window.location.replace(`/detailStatisticsNations`);
                }}
                size="small"
                disableRipple
              >
                {t("statistics.countries_details")}
              </IconButton>
            </div>
          ) : null}
        </Grid>
      </MDBox>
    </Card>
  );
}

TabNumbers.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    })
  ).isRequired,
  showGoToDetails: PropTypes.bool,
};

TabNumbers.defaultProps = {
  showGoToDetails: true,
};

export default TabNumbers;
