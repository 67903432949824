import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMaterialUIController } from "context";
import ChangePassword from "layouts/dashboards/user/changePassword";
import AddCredential from "layouts/dashboards/user/addCredential";

// Material Dashboard 2 PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// @mui material components
import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import SweetAlert from "react-bootstrap-sweetalert";
import moment from "moment";

// @mui material Icons
// import AccessTimeIcon from "@mui/icons-material/AccessTime";
import IconButton from "@mui/material/IconButton";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ServerError from "utilities/ServerError";

function UserPage() {
  // eslint-disable-next-line
  const [controller, dispatch] = useMaterialUIController();
  const { user, customers } = controller;
  const [changingPassword, setChangingPassword] = useState(false);
  const [changePasswordSuccess, setChangePasswordSuccess] = useState(false);
  const [changePasswordError, setChangePasswordError] = useState(false);
  const [addingCredential, setAddingCredential] = useState(false);
  const [clientForCredential, setClientForCredential] = useState("");
  const [addingCredentialsResult, setAddingCredentialsResult] = useState("");
  const { t } = useTranslation();

  function getCustomerData(customer) {
    console.log("customer", customer);
    return (
        <div style={{ fontSize: "16px" }}>
            {
                customer?.vatId ? (
                    <div>
                        {t('user.vat_id')}: <span style={{ fontWeight: "bold" }}>{customer.vatId}</span>
                    </div>
                ) : null
            }
            {
                customer?.serviceName ? (
                    <div>
                        {t('user.service')}: <span style={{ fontWeight: "bold" }}>{customer.serviceName}</span>
                    </div>
                ) : null
            }
            <div style={{ marginTop: "10px" }}>
                {
                    customer?.email?.length > 0 ? (
                        <div className="user_info_section_container">
                            {t('user.emails_linked')}:{" "}
                            {
                                customer.email.map((email, index) => (
                                    <span style={{ fontWeight: "bold" }} key={`email-notifications-${customer.id}-${index.toString()}`}>
                                        {email}{index < (customer.email.length - 1) ? ", " : null}
                                    </span>
                                ))
                            }
                        </div>
                    ) : null
                }
                {
                    customer?.creditcards?.length > 0 ? (
                        <div className="user_info_section_container">
                            {t('user.credit_cards')}:{" "}
                            {
                                customer.creditcards.map((cc, index) => (
                                    <span style={{ fontWeight: "bold" }} key={`credit-card-${customer.id}-${index.toString()}`}>
                                        {cc}{index < (customer.creditcards.length - 1) ? ", " : null}
                                    </span>
                                ))
                            }
                        </div>
                    ) : null
                }
                {
                    customer?.combolists?.length > 0 ? (
                        <div className="user_info_section_container">
                            {t('user.combolists')}:{" "}
                            {
                                customer.combolists.map((combo, index) => (
                                    <span style={{ fontWeight: "bold" }} key={`combolists-${customer.id}-${index.toString()}`}>
                                        {combo}{index < (customer.combolists.length - 1) ? ", " : null}
                                    </span>
                                ))
                            }
                        </div>
                    ) : null
                }
                {
                    customer?.cookies?.length > 0 ? (
                        <div className="user_info_section_container">
                            {t('user.cookies')}:{" "}
                            <ul style={{ paddingLeft: "30px" }}>
                                {
                                    customer.cookies.map((cookie, index) => (
                                        <li key={`cookies-${customer.id}-${index.toString()}`}>
                                            {cookie.url || ""} - {cookie.name || ""}
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    ) : null
                }
                {
                    customer?.cookiesClient?.length > 0 ? (
                        <div className="user_info_section_container">
                            {t('user.cookies_client')}:{" "}
                            <ul style={{ paddingLeft: "30px" }}>
                                {
                                    customer.cookiesClient.map((cookie, index) => (
                                        <li key={`cookiesClient-${customer.id}-${index.toString()}`}>
                                            {cookie.url || ""} - {cookie.name || ""} - {cookie.user || ""}
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    ) : null
                }
                {
                    customer?.passwords?.length > 0 ? (
                        <div className="user_info_section_container">
                            {t('user.domains_to_monitor_passwords')}:{" "}
                            {
                                customer.passwords.map((pw, index) => (
                                    <span style={{ fontWeight: "bold" }} key={`passwords-${customer.id}-${index.toString()}`}>
                                        {pw}{index < (customer.passwords.length - 1) ? ", " : null}
                                    </span>
                                ))
                            }
                        </div>
                    ) : null
                }
                {
                    customer?.passwordsUrl?.length > 0 ? (
                        <div className="user_info_section_container">
                            {t('user.domains_to_monitor_passwords_url')}:{" "}
                            <ul style={{ paddingLeft: "30px" }}>
                                {
                                    customer.passwordsUrl.map((pw, index) => (
                                        <li key={`passwordsUrl-${customer.id}-${index.toString()}`}>
                                            {pw.url || ""} - {pw.domain || "N/A"}
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    ) : null
                }
                {
                    customer?.passwordsApps?.length > 0 ? (
                        <div className="user_info_section_container">
                            {t('user.domains_to_monitor_passwords_apps')}:{" "}
                            {
                                customer.passwordsApps.map((pw, index) => (
                                    <span style={{ fontWeight: "bold" }} key={`passwordsApps-${customer.id}-${index.toString()}`}>
                                        {pw.domain || ""}{pw.wildcard ? "*" : ""}{index < (customer.passwordsApps.length - 1) ? ", " : null}
                                    </span>
                                ))
                            }
                        </div>
                    ) : null
                }
                {
                    customer?.usernames?.length > 0 ? (
                        <div className="user_info_section_container">
                            {t('user.usernames_to_monitor')}:{" "}
                            {
                                customer.usernames.map((pw, index) => (
                                    <span style={{ fontWeight: "bold" }} key={`usernames-${customer.id}-${index.toString()}`}>
                                        {pw}{index < (customer.passwords.length - 1) ? ", " : null}
                                    </span>
                                ))
                            }
                        </div>
                    ) : null
                }
                {
                    customer?.ransomwares?.length > 0 ? (
                        <div className="user_info_section_container">
                            {t('user.ransomware_to_monitor')}:{" "}
                            <ul style={{ paddingLeft: "30px" }}>
                                {
                                    customer.ransomwares.map((ransomware, index) => (
                                        <li key={`ransomwares-${customer.id}-${index.toString()}`}>
                                            {ransomware.victim || "No victim"} - {ransomware.sector || "No sector"} - {ransomware.gang || "No gang"} - {ransomware.domain || "No domain"} - {ransomware.country || "No country"} - {ransomware.company_size || "No company size"}
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    ) : null
                }
                {
                    customer?.service?.length > 0 && customer.service.includes("combo") ?
                    <div style={{ marginTop: "50px" }}>
                        <div style={{ fontSize: "14px" }}>{t('user.add_credential_explanation')}</div>
                        <IconButton
                            onClick={() => {
                                setAddingCredential(true);
                                setClientForCredential(customer.id);
                            }}
                            className="blue-color"
                            size="small"
                            disableRipple
                        >
                            {t('user.add_credential')}
                        </IconButton>
                    </div> :
                    null
                }
            </div>
        </div>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <h2>{user?.name ? t('user.hello') + " " + user.name : t('user.hello')}</h2>
      {
        addingCredential ?
        (
            <AddCredential
                closeFunction={() => {
                    setAddingCredential(false);
                }}
                clientForCredential={clientForCredential}
                successFunction={() => {
                    setAddingCredential(false);
                    setAddingCredentialsResult("success");
                }}
                errorFunction={() => {
                    setAddingCredential(false);
                    setAddingCredentialsResult("error");
                }}
            />
        )
        : null
      }
      {
        addingCredentialsResult ?
        (
            <SweetAlert
                style={{ display: "block", position: "relative", textAlign: "left" }}
                title={addingCredentialsResult === "success" ? t("user.add_credential_success_title") : t("user.add_credential_error_title")}
                success
                onConfirm={() => {
                    setAddingCredentialsResult("");
                }}
                onCancel={() => {
                    setAddingCredentialsResult("");
                }}
                closeOnClickOutside
                confirmBtnText={t("ok")}
                confirmBtnCssClass="popupCloseButton"
                confirmBtnStyle={{
                    boxShadow: "none",
                }}
            >
                {addingCredentialsResult === "success" ? t("user.add_credential_success_text") : t("user.add_credential_error_text")}
            </SweetAlert>
        )
        : null
      }
      {
        changingPassword ?
        (
            <ChangePassword
                closeFunction={() => {
                    setChangingPassword(false);
                }}
                successFunction={() => {
                    setChangingPassword(false);
                    setChangePasswordSuccess(true);
                }}
                errorFunction={() => {
                    setChangingPassword(false);
                    setChangePasswordError(true);
                }}
            />
        )
        : null
      }
      {
        changePasswordSuccess ?
        (
            <SweetAlert
                style={{ display: "block", position: "relative", textAlign: "left" }}
                title={t("user.change_password")}
                success
                onConfirm={() => {
                    setChangePasswordSuccess(false);
                }}
                onCancel={() => {
                    setChangePasswordSuccess(false);
                }}
                closeOnClickOutside
                confirmBtnText={t("ok")}
                confirmBtnCssClass="popupCloseButton"
                confirmBtnStyle={{
                    boxShadow: "none",
                }}
            >
                {t("user.change_password_success_text")}
            </SweetAlert>
        )
        : null
      }
      {
        changePasswordError ?
        (
            <SweetAlert
                style={{ display: "block", position: "relative", textAlign: "left" }}
                title={t("user.change_password")}
                success={false}
                error
                onConfirm={() => {
                    setChangePasswordError(false);
                }}
                onCancel={() => {
                    setChangePasswordError(false);
                }}
                closeOnClickOutside
                confirmBtnText={t("ok")}
                confirmBtnCssClass="popupCloseButton"
                confirmBtnStyle={{
                    boxShadow: "none",
                }}
            >
                {t("user.change_password_error_text")}
            </SweetAlert>
        )
        : null
      }
      {user?.id ? (
        <MDBox className="boxes-border-radius" padding={2.3} sx={{ background: "white", marginTop: "50px" }}>
          {
            user?.email ? (
                <div>
                    {t('user.email')}: <span style={{ fontWeight: "bold" }}>{user.email}</span>
                </div>
            ) : null
          }
          {
            customers && customers.length > 0 ? (
                <div>
                    {t('user.you_have')} <span style={{ fontWeight: "bold" }}>{customers.length}</span> {t('user.customers')}:
                    <div style={{ paddingLeft: "15px" }}>
                        {
                            customers.map((customer) => (
                                <div key={`customer_short_list_${customer.id.toString()}`} style={{ fontWeight: "bold" }}>
                                    - {customer.name || ""}
                                </div>
                            ))
                        }
                    </div>
                </div>
            ) : (
                <div>
                    {t('user.no_clients')}
                </div>
            )
          }
          <div style={{ display: "flex", justifyContent: "end", marginTop: "20px" }}>
            <IconButton
                onClick={() => {
                    // TODO change password
                    setChangingPassword(true);
                }}
                className="blue-color"
                size="small"
                disableRipple
            >
                {t('user.change_password')}
            </IconButton>
          </div>
        </MDBox>
      ) : (
        <ServerError />
      )}
      {
        customers && customers.length > 0 ? (
            customers.map((customer, index) => (
                <div key={`customer-big-${customer.id}`} style={{ marginTop: "30px" }}>
                    <MDBox className="boxes-border-radius" padding={2.3} sx={{ background: "white", marginTop: "50px" }}>
                        <h4 style={{ marginBottom: "20px" }}>{customer.name || t('user.customer')}</h4>
                        {getCustomerData(customer)}
                    </MDBox>
                </div>
            ))
        ) :
        null
      }
    </DashboardLayout>
  );
}

export default UserPage;
