// @mui material components
import Grid from "@mui/material/Grid";
// import Divider from "@mui/material/Divider";
import getNotificationsByCustomersShort from "api/notification/getNotificationsByCustomersShort";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// MUI Icons
// import AccessTimeIcon from "@mui/icons-material/AccessTime";
import IconButton from "@mui/material/IconButton";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import LinkIcon from "@mui/icons-material/Link";

// @mui material Icons
// import Icon from "@mui/material/Icon";
// import AccessTimeIcon from "@mui/icons-material/AccessTime";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// Material Dashboard 2 PRO React example components
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { useMaterialUIController } from "context";

import moment from "moment";
import ServerError from "utilities/ServerError";
// import DefaultDoughnutChart from "examples/Charts/DoughnutCharts/DefaultDoughnutChart";
// import { Calculate } from "@mui/icons-material";

function Alerts() {
  // eslint-disable-next-line
  const [controller, dispatch] = useMaterialUIController();
  const { user, customers } = controller;
  const [notifications, setNotifications] = useState([]);
  const [serverError, setServerError] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    // console.log("customers", customers);
    if (user && customers && customers.length > 0) {
      getNotificationsByCustomersShort()
        .then((res) => {
          // const alerts = res.filter((item, index) => index <= 1);
          if (res.length > 0) setNotifications(res);
        })
        .catch((err) => {
          setServerError(true);
          // eslint-disable-next-line no-console
          console.log("error while getting notifications", err);
        });
      // const alerts = [
      //   {
      //     customer: "greenenergybank.it",
      //     date: "2022-11-22T11:15:52.181Z",
      //     id: "637caf68c325b3474639807e",
      //     stealer: "637caf3724d3ce0652f28990",
      //     type: "stealer_cookie_found",
      //   },
      //   {
      //     customer: "greenenergybank.it",
      //     date: "2022-11-22T11:15:52.181Z",
      //     id: "637caf68c325b3474639807d",
      //     stealer: "637caf3724d3ce0652f28990",
      //     type: "stealer_cookie_found",
      //   },
      //   {
      //     customer: "greenenergybank.it",
      //     date: "2022-11-22T11:15:52.178Z",
      //     id: "637caf68c325b3474639807b",
      //     stealer: "637caf3724d3ce0652f28990",
      //     type: "stealer_password_found",
      //   },
      // ];
    }
  }, [user, customers]);

  function getTitle(type) {
    if (type.includes("-")) {
      return t(`analytics.title_${type.split("-")[1]}`);
    }
    return t(`analytics.title_${type}`);
  }

  function getText(type) {
    if (type.includes("-")) {
      return `${type.split("-")[0]} ${t(`analytics.text_${type.split("-")[1]}`)}`;
    }
    return t(`analytics.text_${type}`);
  }

  function getDate(date) {
    return moment(date).format("DD-MM-YYYY");
  }

  function getTime(date) {
    return moment(date).format("HH:mm");
  }

  function getColor(type) {
    const str = type.replace(/[0-9]/g, "");
    if (str === "-credentials_found") {
      return "#CF1A1A";
    }
    return "#0BC0A0";
  }

  return (
    <Grid item xs={12} md={6} lg={5} paddingTop="0px">
      <MDBox
        className="boxes-border-radius alerts"
        sx={{
          background: "white",
          boxShadow:
            "0rem 0.25rem 0.375rem -0.0625rem rgb(0 0 0 / 10%), 0rem 0.125rem 0.25rem -0.0625rem rgb(0 0 0 / 6%)",
        }}
      >
        <MDBox className="home-box-header" sx={{ background: "#2C74E1" }}>
          <MDTypography variant="header3" color="light" fontWeight="bold">
            {t(`analytics.alerts`)}
          </MDTypography>
        </MDBox>
        {!serverError ? (
          <MDBox p={2}>
            {notifications && notifications.length > 0 ? (
              notifications.map((notification) => (
                <div
                  key={notification._id.toString()}
                  className="alert-notification-container"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (notification.type === "ransomware") {
                      window.open(`/ransomwares`, "_self");
                    } else {
                      window.open(`/alerts#${notification._id.toString()}`, "_self");
                    }
                  }}
                >
                  <div className="alert-notification-date-container">
                    <span>
                      <CalendarTodayIcon />
                      {notification.date ? getDate(notification.date) : ""}
                    </span>
                    <span>{notification.date ? getTime(notification.date) : ""}</span>
                  </div>
                  <div className="alert-notification-text-container">
                    <div
                      style={{ color: getColor(notification.type) }}
                      className="alert-notification-title"
                    >
                      {notification.type ? getTitle(notification.type) : ""}
                    </div>
                    <div className="alert-notification-text">
                      {notification.type ? getText(notification.type) : ""}
                    </div>
                    {/* {
                      notification.stealers && notification.stealers.length > 0 ?
                        <MDTypography mb={1} fontSize="medium">
                          {
                            notification.stealers.map((st) => (
                              <span key={`stealer-in-notification-${notification._id.toString()}-${st.toString()}`}>
                                <IconButton
                                  onClick={() => {
                                    window.open(`/detailsStealer?${st}`, "_blank").focus();
                                  }}
                                  className="notification-link"
                                  size="small"
                                  disableRipple
                                >
                                  <LinkIcon sx={{ marginRight: "10px" }} />{st}
                                </IconButton><br />
                              </span>
                            ))
                          }
                        </MDTypography> :
                        null
                    } */}
                  </div>
                </div>
              ))
            ) : (
              <h5>{t("analytics.no_alerts")}</h5>
            )}
          </MDBox>
        ) : (
          <MDBox className="server-error-container">
            <ServerError />
          </MDBox>
        )}
        {/* <MDBox mt={2} display="flex" justifyContent="space-around">
          <MDTypography variant="button" color="dark" fontWeight="light">
            12:30
          </MDTypography>
          <MDBox>
            <MDTypography component="div" variant="button" color="dark" fontWeight="bold">
              Stolen credentials risk
            </MDTypography>
            <MDTypography
              component="div"
              variant="caption"
              color="dark"
              fontWeight="regular"
              mb={2}
            >
              We have found an attack on your account..
            </MDTypography>
            <Divider sx={{ border: "1px solid lightgrey", margin: "0px" }} />
          </MDBox>
        </MDBox>
        <MDBox mt={2} display="flex" justifyContent="space-around">
          <MDBox display="flex" alignItems="flex-start">
            {/* <AccessTimeIcon className="dashboard-alerts-time" />
            <MDTypography variant="button" ml={1} color="dark" fontWeight="light">
              12:30
            </MDTypography>
          </MDBox>
          <MDBox>
            <MDTypography component="div" variant="button" color="dark" fontWeight="bold">
              Stolen credentials risk
            </MDTypography>
            <MDTypography
              component="div"
              variant="caption"
              color="dark"
              fontWeight="regular"
              mb={2}
            >
              We have found an attack on your account..
            </MDTypography>
            <Divider sx={{ border: "1px solid lightgrey", margin: "0px" }} />
          </MDBox>
        </MDBox>
        <MDBox mt={2} display="flex" justifyContent="space-around">
          <MDBox display="flex" alignItems="flex-start">
            {/* <AccessTimeIcon className="dashboard-alerts-time" />
            <MDTypography variant="button" ml={1} color="dark" fontWeight="light">
              12:30
            </MDTypography>
          </MDBox>
          <MDBox>
            <MDTypography component="div" variant="button" color="dark" fontWeight="bold">
              Stolen credentials risk
            </MDTypography>
            <MDTypography
              component="div"
              variant="caption"
              color="dark"
              fontWeight="regular"
              mb={2}
            >
              We have found an attack on your account..
            </MDTypography>
          </MDBox>
        </MDBox> */}
      </MDBox>
    </Grid>
  );
}

export default Alerts;
