import config from "config/config";
import getTokens from "api/getTokens";

const  { baseUrl, clientUrl } = config;

/* *********************
  email
  password
********************** */

export default function getStealers(
  page,
  limit = 50,
  type = "",
  status = "error",
  dateFrom = "",
  dateTo = "",
  orderBy = ""
) {
  let order = "sortDisabled";
  if (orderBy === "domain") {
    order = "domain:asc";
  } else if (orderBy === "domainReverse") {
    order = "domain:desc";
  } else if (orderBy === "date") {
    order = "lastAttempt:asc";
  } else if (orderBy === "dateReverse") {
    order = "lastAttempt:desc";
  } else if (orderBy === "nation") {
    order = "countryCode:asc";
  } else if (orderBy === "nationReverse") {
    order = "countryCode:desc";
  } else if (orderBy === "hash") {
    order = "filelistHash:asc";
  } else if (orderBy === "hashReverse") {
    order = "filelistHash:desc";
  }
  const endpoint = `${baseUrl}/v1/notifications/get-messages?limit=${limit}&page=${page}&sortBy=${order}&status=${status}&type=${type}&dateFrom=${dateFrom}&dateTo=${dateTo}`;
  let access = "";
  const tokens = getTokens();
  if (tokens && tokens.access && tokens.access.token) {
    access = tokens.access.token;
  } else {
    window.location.href = "/login";
  }
  const options = {
    method: "GET",
    mode: "cors",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `${clientUrl || ""}`,
      "Access-Control-Allow-Credentials": true,
      Authorization: `bearer ${access}`,
    },
  };
  // console.log('login endpoint', {endpoint, BODY });
  return fetch(endpoint, options)
    .then((response) => {
      if (response.status !== 200 && response.status !== 201 && response.status !== 204) {
        throw new Error();
      }
      return response.json();
    })
    .catch((e) => {
      // eslint-disable-next-line
      console.log("error", e);
    });
}
