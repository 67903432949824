/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-chartjs-2 components
import { Bar } from "react-chartjs-2";

// @mui material components
import Card from "@mui/material/Card";
// import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Popover from "@mui/material/Popover";

// ReportsBarChart configurations
import configs from "examples/Charts/BarCharts/ReportsBarChart/configs";

// import moment from "moment";
// import Datetime from "react-datetime";

// Import icons
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

function ReportsBarChart({ color, chart, ransomwareCounter, ransomwareCountryCounter, country }) {
  const { data, options } = configs(chart.labels || [], chart.datasets || {});
  // eslint-disable-next-line
  // const [controller, dispatch] = useMaterialUIController();
  // const { statisticsStart, statisticsEnd } = controller;
  // const [showStatisticsDate, setShowStatisticsDate] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const ctx =
      document && document.getElementById("home-ransomware-chart")
        ? document.getElementById("home-ransomware-chart").getContext("2d")
        : null;

    // Background gradient
    let backgroundGradient = null;
    if (ctx) {
      backgroundGradient = ctx.createLinearGradient(0, 0, 0, 100);
      backgroundGradient.addColorStop(0.4, "rgb(220, 58, 246)");
      backgroundGradient.addColorStop(1, "rgb(44, 116, 225)");
    }

    if (data && data.datasets && data.datasets[0]) {
      data.datasets[0].backgroundColor = backgroundGradient;
    }
  }, [data]);

  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Card sx={{ height: "100%", background: "transparent", boxShadow: "none" }}>
      <MDTypography varialnt="h6" fontSize="medium" fontWeight="bold" pt={2} pl={4}>
        {t(`analytics.ransomware_attacks`)}
      </MDTypography>
      <MDTypography
        variant="caption"
        fontSize="small"
        fontWeight="regular"
        sx={{ color: "#669FF6" }}
        pl={4}
      >
        {t("analytics.last_12_months")}
      </MDTypography>
      <MDBox pt={2} pb={2} pl={4} display="flex">
        <HelpOutlineIcon
          className="home-question-mark"
          aria-owns={open ? "mouse-over-popover" : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        />
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: "none",
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <MDTypography fontSize="small">{t("analytics.ransomware_question_mark")}</MDTypography>
        </Popover>
        {ransomwareCounter >= 0 ? (
          <MDBox width="50%" position="relative">
            <MDTypography
              fontSize="1.5em"
              variant="h3"
              color="dark"
              fontWeight="bold"
              sx={{ lineHeight: "1" }}
            >
              {ransomwareCounter}
              <MDTypography
                variant="caption"
                fontSize="small"
                fontWeight="regular"
                display="block"
                sx={{ color: "#669FF6" }}
              >
                {t(`analytics.worldwide`)}
              </MDTypography>
            </MDTypography>
          </MDBox>
        ) : null}
        {ransomwareCountryCounter >= 0 && country ? (
          <MDBox width="50%">
            <MDTypography
              fontSize="1.5em"
              variant="h3"
              color="dark"
              fontWeight="bold"
              sx={{ lineHeight: "1" }}
            >
              {ransomwareCountryCounter}
              <MDTypography
                variant="caption"
                fontSize="small"
                fontWeight="regular"
                display="block"
                sx={{ color: "#669FF6" }}
              >
                In {t(`countryCode.${country}`)}
              </MDTypography>
            </MDTypography>
          </MDBox>
        ) : null}
        {/* <MDTypography variant="h6" textTransform="capitalize" border={1}>
          {title}
        </MDTypography>
        <MDTypography component="div" variant="button" color="text" fontWeight="light">
          {description}
        </MDTypography>
        <MDBox display="flex" alignItems="center">
          <MDTypography variant="button" color="text" lineHeight={1} sx={{ mt: 0.15, mr: 0.5 }}>
            <Icon>schedule</Icon>
          </MDTypography>
          <MDTypography variant="button" color="text" fontWeight="light">
            {date}
          </MDTypography>
        </MDBox> */}
      </MDBox>
      <MDBox pt={2} pb={1} pr={5} pl={4}>
        <div>
          <MDTypography varialnt="h6" fontSize="medium" fontWeight="bold">
            {t(`analytics.title_attacks_trend`)}
          </MDTypography>
          <MDTypography
            variant="caption"
            fontSize="small"
            fontWeight="regular"
            display="block"
            sx={{ color: "#669FF6" }}
          >
            {t("analytics.last_12_months")}
          </MDTypography>
          {/* <CalendarTodayIcon fontSize="medium" style={{ marginRight: "10px" }} /> */}
          {/* <select
            defaultValue="all"
            style={{
              maxWidth: "70px",
              fontSize: "0.6em",
            }}
            onChange={(e) => {
              switch (e.target.value) {
                case "all":
                  setStatisticsEnd(dispatch, "");
                  setStatisticsStart(dispatch, "");
                  if (showStatisticsDate) {
                    setShowStatisticsDate(false);
                  }
                  break;
                case "last_year":
                  setStatisticsStart(dispatch, moment().subtract(1, "years").format("L"));
                  setStatisticsEnd(dispatch, moment().format("L"));
                  if (showStatisticsDate) {
                    setShowStatisticsDate(false);
                  }
                  break;
                case "2022":
                  setStatisticsStart(dispatch, moment("01/01/2022").format("L"));
                  setStatisticsEnd(dispatch, moment("12/31/2022").format("L"));
                  if (showStatisticsDate) {
                    setShowStatisticsDate(false);
                  }
                  break;
                case "2021":
                  setStatisticsStart(dispatch, moment("01/01/2021").format("L"));
                  setStatisticsEnd(dispatch, moment("12/31/2021").format("L"));
                  if (showStatisticsDate) {
                    setShowStatisticsDate(false);
                  }
                  break;
                case "2020":
                  setStatisticsStart(dispatch, moment("01/01/2020").format("L"));
                  setStatisticsEnd(dispatch, moment("12/31/2020").format("L"));
                  if (showStatisticsDate) {
                    setShowStatisticsDate(false);
                  }
                  break;
                case "custom":
                  setShowStatisticsDate(true);
                  break;
                default:
                  setStatisticsStart(dispatch, moment("01/01/2000").format("L"));
                  setStatisticsEnd(dispatch, moment().format("L"));
                  if (showStatisticsDate) {
                    setShowStatisticsDate(false);
                  }
                  break;
              }
            }}
            className="headerCustomersDropdown"
          >
            <option value="all">{t("statistics.always")}</option>
            <option value="last_year">{t("statistics.last_year")}</option>
            <option value="2022">{t("statistics.2022")}</option>
            <option value="2021">{t("statistics.2021")}</option>
            <option value="2020">{t("statistics.2020")}</option>
            <option value="custom">{t("statistics.custom")}</option>
          </select> */}
          {/* {showStatisticsDate ? (
            <>
              <Datetime
                inputProps={{
                  placeholder: t("statistics.start_statistics"),
                  value: statisticsStart || "",
                  style: { marginRight: "10px" },
                }}
                closeOnSelect
                timeFormat={false}
                value={statisticsStart || ""}
                onChange={(e) => {
                  setStatisticsStart(dispatch, moment(e).format("L"));
                }}
              />
              <Datetime
                inputProps={{
                  placeholder: t("statistics.end_statistics"),
                  value: statisticsEnd || "",
                }}
                closeOnSelect
                timeFormat={false}
                value={statisticsEnd || ""}
                onChange={(e) => {
                  setStatisticsEnd(dispatch, moment(e).format("L"));
                }}
              />
            </>
          ) : null} */}
        </div>
        {useMemo(
          () => (
            <MDBox variant="gradient" bgColor="white" pt={1} pb={2} height="12rem">
              <Bar id="home-ransomware-chart" data={data} options={options} />
            </MDBox>
          ),
          [chart, color]
        )}
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of ReportsBarChart
ReportsBarChart.defaultProps = {
  color: "dark",
  ransomwareCounter: 0,
  ransomwareCountryCounter: 0,
  country: "IT",
  // description: "",
};

// Typechecking props for the ReportsBarChart
ReportsBarChart.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  ransomwareCounter: PropTypes.number,
  ransomwareCountryCounter: PropTypes.number,
  country: PropTypes.string,
  // title: PropTypes.string.isRequired,
  // description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  // date: PropTypes.string.isRequired,
  chart: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.array, PropTypes.object])).isRequired,
};

export default ReportsBarChart;
