const stealer = {
  en: {
    translation: {
      attack_details: "Attack details",
      add_hidden_results: "Add hidden results",
      brand: "Brand",
      nation: "Nation",
      date_from: "Start date",
      date_to: "End date",
      date: "Date",
      domain: "Domain",
      reason: "Stolen data",
      complete: "Complete",
      incomplete: "Incomplete",
      completes: "Completes",
      incompletes: "Incompletes",
      info: "Info",
      all: "All",
      to_manage: "To manage",
      stealer_to_manage: "Stealer to manage",
      stealer_edited_on: "Stealer edited on ",
      country: "Country",
      tags: "Tags",
      duplicate: "Duplicate",
      machine_id: "Machine ID",
      hash: "Hash",
      so: "SO",
      ip_address: "IP Address",
      ip: "IP",
      date_of_compromise: "Date of compromise",
      acquisition_date: "Acquisition date",
      machine_name: "Machine name",
      reason_visual: "Stolen data",
      username: "Username",
      timezone: "Timezone",
      usage_type: "Usage type",
      city: "City",
      organisation: "Organisation",
      as_name: "ASname",
      filelist: "File list",
      open_details: "Open details",
      path: "Path",
      organization: "Organization",
      urls: "URLS",
      credentials: "Credentials",
      cookies: "Cookies",
      creditcards: "Credit cards",
      card: "Card",
      card_number: "Card number",
      holder: "Holder",
      expiration: "Expiration",
      cvv: "CVV",
      edit_stealer: "Edit stealer",
      ip_geo_data_text: "Geolocation data was retrieved by ip, may not be totally accurate",
      operative_system: "Operative system",
      language: "Language",
      record_updated: "Record updated",
      elements_displayed: "Elements displayed:",
      record_updated_text: "Record has been updated succesfully",
      no_stealer_yet: "There are no stealers yet",
      no_customer_associated: "No customer associated to the stealer",
      is_really_duplicate: "Is the element really duplicate?",
      make_stealer_visible: "Make stealer visible",
      hide_stealer: "Hide stealer",
      download_stealers_csv: "Download csv file with stealers data",
      request_details: "Request a detailed incident response report",
      request_details_text: "It will be mailed to you in about 4 business hours.",
      request: "Request",
      print: "Print",
      already_requested_pending: "You have already requested a detailed incident response report.",
      already_requested_pending_text:
        "We are currently processing your request, please, wait a little more",
      already_requested: "You have already requested a detailed incident response report.",
      already_requested_text: "We have processed your request, check your eamil",
      login: "Login",
      password: "Password",
      password_strength: "Password strength",
      cookie_name: "Cookie name",
      cookie_value: "Cookie value",
      characters_length: "characters long",
      stolen_credentials: "Stolen digital identity",
      credentials_label1: "stolen",
      credentials_label2: "credentials",
      ytd_label1: "total attacks",
      ytd_label2: "YTD",
      passwords_label1: "stolen",
      passwords_label2: "passwords",
      not_detected: "not detected",
      cookies_label1: "stolen",
      cookies_label2: "cookies",
      last_period_label1: "attacks",
      last_period_label2: "last 3 months",
      error_retrieve_file_from_s3:
        "Error retrieving details file, try again later or contact our support at +39 0583 164 7166",
      open: "Open",
      report_ready: "Your report is ready",
      report_ready_subtitle: `To see the file, click on the "Open" button`,
      get_original_item_id: "Get original item id",
      managed: "You have already seen this",
      manage: "Mark as managed",
      managed_label: "You have already marked this element as managed",
      not_managed_yet: "You have not managed this yet",
      already_managed: "You have already managed this",
      yes: "Yes",
      no: "No",
      instant_report: "Instant report",
      instant_report_confirm:
        'Do you want to generate the report immediatly? Choose "No" to schedule it.',
      available: "Available",
      not_available: "Not available",
      not_available_short: "N/A",
      status: "Status",
      origin: "Origin",
      reason_to_show: "Alert cause",
      ip_paragraph:
        "The IP address was found within data extracted by malware, which means this machine has likely interacted with or has been controlled by the malicious software. This might include unauthorized access, manipulation of data, or other malicious activities. In the context of a cybersecurity breach involving malware, discovering the IP address of a client's machine among exfiltrated data indicates that the client's system has been compromised. This scenario suggests unauthorized access and potential data leakage from the client's system.",
      active: "Active",
      expired: "Expired",
      expiration_date: "Expiration date",
    },
  },
  it: {
    translation: {
      attack_details: "Dettagli attacco",
      add_hidden_results: "Aggiungi risultati nascosti",
      brand: "Brand",
      nation: "Nazione",
      date_from: "Data inizio",
      date_to: "Data fine",
      date: "Data",
      domain: "Dominio",
      reason: "Dato rubato",
      complete: "Completo",
      incomplete: "Incompleto",
      completes: "Completi",
      incompletes: "Incompleti",
      info: "Info",
      all: "Tutti",
      to_manage: "Da gestire",
      stealer_to_manage: "Stealer da gestire",
      stealer_edited_on: "Stealer modificato il ",
      country: "Paese",
      tags: "Tags",
      duplicate: "Duplicate",
      machine_id: "Machine ID",
      hash: "Hash",
      so: "SO",
      ip_address: "Indirizzo IP",
      ip: "IP",
      date_of_compromise: "Data di compromissione",
      acquisition_date: "Data di acquisizione",
      machine_name: "Nome macchina",
      reason_visual: "Dato rubato",
      username: "Username",
      timezone: "Timezone",
      usage_type: "Usage type",
      city: "Città",
      organisation: "Organizzazione",
      as_name: "ASname",
      filelist: "File list",
      open_details: "Apri dettaglio",
      path: "Path",
      organization: "Organizzazione",
      urls: "URL",
      credentials: "Credenziali",
      cookies: "Cookies",
      creditcards: "Carte di credito",
      card: "Carta",
      card_number: "Numero carta",
      holder: "Proprietario",
      expiration: "Scadenza",
      cvv: "CVV",
      edit_stealer: "Modifica stealer",
      ip_geo_data_text:
        "I dati della localizzazione sono ricavati tramite IP, quindi potrebbero non essere totalmente affidabili",
      operative_system: "Sistema operativo",
      language: "Lingua",
      record_updated: "Riga aggiornata",
      elements_displayed: "Elementi visualizzati:",
      record_updated_text: "La riga è stata aggiornata con successo",
      no_stealer_yet: "Non ci sono ancora instanze di stealer",
      no_customer_associated: "Nessun cliente associato allo stealer",
      is_really_duplicate: "Questo elemento è effettivamente un duplicato?",
      make_stealer_visible: "Rendi lo stealer visibile",
      hide_stealer: "Nascondi lo stealer",
      download_stealers_csv: "Scarica il file csv con i dati degli stealer",
      request_details: "Richiedi un report dettagliato dell'incidente.",
      request_details_text: "Ti sarà inviato in circa 4 ore lavorative",
      request: "Richiedi",
      print: "Stampa",
      already_requested_pending: "Hai già richiesto un report dettagliato dell'incidente.",
      already_requested_pending_text:
        "Stiamo processando la tua richiesta, per favore, aspetta un altro po'",
      already_requested: "Hai già richiesto un report dettagliato dell'incidente.",
      already_requested_text: "Abbiamo processato la tua richiesta, controlla la tua email",
      login: "Login",
      password: "Password",
      password_strength: "Efficacia password",
      cookie_name: "Nome cookie",
      cookie_value: "Valore cookie",
      characters_length: "caratteri",
      stolen_credentials: "Identità digitali rubate",
      stolen: "rubate",
      credentials_label1: "credenziali",
      credentials_label2: "rubate",
      ytd_label1: "attacchi totali",
      ytd_label2: "da un anno",
      passwords_label1: "password",
      passwords_label2: "rubate",
      not_detected: "non rilevata",
      cookies_label1: "cookies",
      cookies_label2: "rubati",
      last_period_label1: "attacchi",
      last_period_label2: "ultimi 3 mesi",
      error_retrieve_file_from_s3:
        "Errore nel recupero del dettaglio del file, riprova in sequito o contatta la nostra assistenza al +39 0583 164 7166",
      open: "Apri",
      report_ready: "Il report che hai richiesto è pronto",
      report_ready_subtitle: `Per vedere il file, clicca su "Apri"`,
      get_original_item_id: "Vedi original item id",
      managed: "Già visto",
      manage: "Segna come gestito",
      managed_label: "Hai già segnalato questo elemento come gestito",
      not_managed_yet: "Non lo hai ancora gestito",
      already_managed: "Hai già gestito questo stealer",
      yes: "Sì",
      no: "No",
      instant_report: "Report istantaneo",
      instant_report_confirm:
        'Vuoi generare il report immediatamente? Scegli "No" per programmarlo.',
      available: "Disponibile",
      not_available: "Non disponibile",
      not_available_short: "N/D",
      status: "Stato",
      origin: "Origine",
      reason_to_show: "Motivo alert",
      ip_paragraph:
        "L'indirizzo IP è stato trovato all'interno dei dati estratti dal malware, il che significa che questa macchina probabilmente ha interagito con o è stata controllata dal software dannoso. Ciò potrebbe includere accessi non autorizzati, manipolazione dei dati o altre attività dannose. Nel contesto di una violazione della sicurezza informatica coinvolgente malware, scoprire l'indirizzo IP di una macchina cliente tra i dati esfiltrati indica che il sistema del cliente è stato compromesso. Questo scenario suggerisce un accesso non autorizzato e una potenziale perdita di dati dal sistema del cliente.",
      active: "Attivo",
      expired: "Scaduto",
      expiration_date: "Scadenza",
    },
  },
};

export default stealer;
