/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Card from "@mui/material/Card";

import MDBox from "components/MDBox";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

import credentialConfirmation from "api/auth/credentialConfirmation";

function ConfirmEmail() {
  const { t } = useTranslation();
  const [statusValidation, setStatusValidation] = useState("");

  function tryValidation() {
    const url = window.location.href;
    const splitted = url.split("token=");
    if (splitted && splitted[1]) {
        const token = splitted[1];
        setStatusValidation("checking");
        credentialConfirmation(token)
        .then((res) => {
            if (res) {
              setStatusValidation("success");
            } else {
              setStatusValidation("error");
            }
        })
        .catch((err) => {
            // eslint-disable-next-line
            console.log("error while validating email", err);
            setStatusValidation("error");
        });
    } else {
        setStatusValidation("no_code");
    }
  }

  useEffect(() => {
    tryValidation();
  }, [])

  function getContent() {
    switch (statusValidation) {
      case "success":
        return (
          <div className="email_validation_success">
            <h3>
              {t('credentialConfirmation.success_title')}
            </h3>
            <p>
              {t('credentialConfirmation.success_text')}
            </p>
          </div>
        );
      case "checking":
        return (
          <div className="email_validation_checking">
            <h3>
              {t('credentialConfirmation.checking_title')}
            </h3>
            <p>
              {t('credentialConfirmation.checking_text')}
            </p>
          </div>
        );
      case "error":
        return (
          <div className="email_validation_error">
            <h3>
              {t('credentialConfirmation.error_title')}
            </h3>
            <p>
              {t('credentialConfirmation.error_text')}
            </p>
          </div>
        );
      case "no_code":
        return (
          <div className="email_validation_no_code">
            <h3>
              {t('credentialConfirmation.no_code_title')}
            </h3>
            <p>
              {t('credentialConfirmation.no_code_text')}
            </p>
          </div>
        );
      default:
        case "no_code":
          return (
            <div className="email_validation_no_code">
              <h3>
                {t('credentialConfirmation.no_code_title')}
              </h3>
              <p>
                {t('credentialConfirmation.no_code_text')}
              </p>
            </div>
          );
    }
  }

  return (
    <BasicLayout>
      <Card>
        <MDBox pt={2} pb={3}>
          <MDBox component="form" role="form">
            {/* SWITCH CON STATUS PER FAR VEDERE COSE */}
            {getContent()}
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default ConfirmEmail;
