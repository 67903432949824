// @mui material components
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import getTokens from "api/getTokens";
import addCode from "api/codes/addCode";
import getCodes from "api/codes/getCodes";
import MDInput from "components/MDInput";
import Grid from "@mui/material/Grid";
import SweetAlert from "react-bootstrap-sweetalert";

// Material Dashboard 2 PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import { useMaterialUIController } from "context";
import { useNavigate } from "react-router-dom";

const SERVICES_AVAILABLE = ["insurance", "combo"]

function Codes() {
  // eslint-disable-next-line
  const [controller, dispatch] = useMaterialUIController();
  const { user } = controller;
  const [pages, setPages] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [note, setNote] = useState("");
  const [codes, setCodes] = useState([]);
  const [codesNumber, setCodesNumber] = useState(1);
  const [codeServices, setCodeServices] = useState([]);
  const [duration, setDuration] = useState(1);
  const [addingCode, setAddingCode] = useState(false);
  const [addedPopup, setAddedPopup] = useState(false);
  const [newCreated, setNewCreated] = useState([]);
  const [redeemInfo, setRedeemInfo] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorEmail, setErrorEmail] = useState(false);
  const [isRequestReady, setIsRequestReady] = useState(false);
  const [popup, setPopup] = useState(false);
  const [serverError, setServerError] = useState({
    ransomwaresCounters: false,
    ransomwaresTable: false,
  });
  const navigate = useNavigate();
  const { t } = useTranslation();

  function setRequest(res) {
    if (res.results && res.results.length > 0) {
      setCodes(res.results);
      setIsRequestReady(true);
    }
    if (res && res.length > 0) {
      setCodes(res);
      setIsRequestReady(true);
    } else if (res.length === 0 || (res.results && res.results.length === 0)) {
      setCodes([]);
      setIsRequestReady(true);
    }
  }

  function refreshCodes() {
    setIsRequestReady(false);
    // if (!client || client === "*") {
    getCodes(page, limit)
      .then((res) => {
        setPages(res.totalPages);
        setRequest(res);
      })
      .catch((err) => {
        setServerError({ ...serverError, ransomwaresTable: true });
        // eslint-disable-next-line no-console
        console.log("error", err);
      });
  }

  useEffect(() => {
    refreshCodes();
  }, [
    page,
    limit,
  ]);

  function getInput(type, editFunction, defaultValue) {
    return (
      <MDInput
        type="input"
        label={type}
        fullWidth
        defaultValue={defaultValue}
        onChange={(e) => {
          editFunction(e.target.value);
        }}
        style={{ marginBottom: "20px", marginTop: "20px" }}
      />
    );
  }

  function getSelect(options, editFunction, defaultValue) {
    return (
      <div style={{ marginTop: "20px", fontSize: "18px" }}>
        Durata:{" "}
        <select
          onChange={(e) => {
            editFunction(e.target.value);
          }}
          defaultValue={defaultValue}
          className="selectDefault"
          style={{
            width: "initial",
            padding: "0.35rem",
            borderRadius: "0.375rem",
            borderColor: "#d2d6da",
          }}
        >
          {options.map((el, index) => (
            <option value={el} key={`option-role-${index.toString()}`}>
              {el}
            </option>
          ))}
        </select>{" "}mesi
      </div>
    );
  }

  function getServices(services) {
    return services.join(", ");
  }

  function getServicesSelection() {
    return (
      <div className="services-selection-container">
        {
          SERVICES_AVAILABLE.map((ser, index) => (
            <div key={`${ser}-${index.toString()}`} className="service-selection">
              <IconButton
                onClick={() => {
                  const newCodeServices = [].concat(codeServices);
                  if (newCodeServices.includes(ser)) {
                    const indexToRemove = newCodeServices.indexOf(ser);
                    newCodeServices.splice(indexToRemove, 1);
                  } else {
                    newCodeServices.push(ser);
                  }
                  setCodeServices(newCodeServices);
                }}
                className={`service-to-select ${codeServices.includes(ser) ? "is-selected" : ""}`}
                size="small"
                disableRipple
              >
                {ser}
              </IconButton>
            </div>
          ))
        }
      </div>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <h2 className="page-title" style={{ marginBottom: "50px" }}>
        {t("codes.codes")}
      </h2>
      <div style={{ marginBottom: "50px" }}>
        <IconButton
          onClick={() => {
            setAddingCode(true);
          }}
          className="default-button"
          size="small"
          disableRipple
        >
          {t("codes.add_code")}
        </IconButton>
      </div>
      {
        addingCode ?
        <SweetAlert
          style={{ display: "block" }}
          title={t("codes.add_code")}
          onConfirm={() => {
            if (duration && codeServices && codeServices.length > 0) {
              addCode({
                services: codeServices,
                duration,
                note: note || "",
                codeNumbers: Number(codesNumber)
              })
                .then(res => {
                  // console.log("res", res);
                  setAddedPopup(true);
                  setNewCreated(res);
                  refreshCodes();
                })
                .catch(err => {
                  // eslint-disable-next-line
                  console.log("err", err);
                })
              setAddingCode(false);
            }
          }}
          confirmBtnText="Ok"
          // confirmBtnCssClass={classes.button + " " + classes.success}
          confirmBtnCssClass="popupCloseButton"
          confirmBtnStyle={{
            boxShadow: "none",
          }}
        >
          {/* servizi */}
          <div style={{ marginBottom: "5px", fontSize: "18px" }}>{t("codes.services")}:</div>
          {getServicesSelection()}
          {/* durata */}
          {getSelect([1, 3, 6, 12, 24], setDuration, duration)}
          {/* note */}
          {getInput(t("codes.note"), setNote, note)}
          {/* numero codici */}
          {getInput(t("codes.code_numbers"), setCodesNumber, codesNumber)}
        </SweetAlert> :
        null
      }
      {
        addedPopup && newCreated?.length > 0 ?
        <SweetAlert
          style={{ display: "block" }}
          title={t("codes.codes_added")}
          onConfirm={() => {
            setAddedPopup(false);
          }}
          confirmBtnText="Ok"
          // confirmBtnCssClass={classes.button + " " + classes.success}
          confirmBtnCssClass="popupCloseButton"
          confirmBtnStyle={{
            boxShadow: "none",
          }}
        >
          <div className="code-created-list">
            {
              newCreated.map(code => (
                <div key={`code-created-${code}`} className="code-created-in-list">
                  {code}
                </div>
              ))
            }
          </div>
        </SweetAlert> :
        null
      }
      {
        redeemInfo && redeemInfo.user ?
        <SweetAlert
          style={{ display: "block" }}
          title={t("codes.code_redeemed")}
          onConfirm={() => {
            setRedeemInfo(null);
          }}
          confirmBtnText="Ok"
          // confirmBtnCssClass={classes.button + " " + classes.success}
          confirmBtnCssClass="popupCloseButton"
          confirmBtnStyle={{
            boxShadow: "none",
          }}
        >
          <div style={{ fontSize: "18px" }}>{t("codes.redeemed_by")}: <span style={{ fontWeight: "bold" }}>{redeemInfo?.user?.name}</span></div>
          <div style={{ fontSize: "18px" }}>{t("codes.when")}: <span style={{ fontWeight: "bold" }}>{redeemInfo?.dateClaim ? moment(redeemInfo.dateClaim).format("YYYY-MM-DD HH:mm") : ""}</span></div>
          <div style={{ fontSize: "18px" }}>{t("codes.with_ip")}: <span style={{ fontWeight: "bold" }}>{redeemInfo?.redeemedByIp}</span></div>
        </SweetAlert> :
        null
      }
      {codes && codes.length > 0 ? (
        <div className="table_users_list">
          <table className="table-content" cellSpacing="0" cellPadding="0">
            <thead>
              <tr>
                <th style={{ textAlign: "center" }}>{t("codes.date")}</th>
                <th style={{ textAlign: "center" }}>{t("codes.code")}</th>
                <th style={{ textAlign: "center" }}>{t("codes.status")}</th>
                <th style={{ textAlign: "center" }}>{t("codes.services")}</th>
              </tr>
            </thead>
            <tbody>
              {codes.map((code, index) => (
                <tr key={index.toString()} style={{ textAlign: "center" }}>
                  <td>{code.date ? moment(code.date).format("DD/MM/YYYY") : null}</td>
                  <td>{code.code || ""}</td>
                  <td>
                    {code.status || ""}
                    {code.status === "redeemed" ?
                      <IconButton
                        onClick={() => {
                          setRedeemInfo(code);
                        }}
                        style={{ color: "green" }}
                        size="small"
                        disableRipple
                      >
                        <Icon fontSize="medium">check</Icon>
                      </IconButton> : null
                    }
                  </td>
                  <td>{code.services && code.services.length > 0 ? getServices(code.services) : ""}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {pages && pages > 1 ? <Pagination setPage={setPage} pages={pages} active={page} /> : null}
        </div>
      ) : (
        <div>{t("codes.no_code_yet")}</div>
      )}
    </DashboardLayout>
  );
}

export default Codes;
