const messages = {
  en: {
    translation: {
      date: "Date",
      info: "Info",
      type: "Type",
      status: "Status",
      no_message_yet: "There is no message yet",
      all: "All",
      message_details: "Details",
      errors_number: "Errors number",
      error_message: "Error message",
      to: "To",
      subject: "Subject",
      message: "Message",
      text: "Text",
      url: "Url",
      content_type: "ContentType",
    },
  },
  it: {
    translation: {
      date: "Data",
      info: "Info",
      type: "Tipo",
      status: "Stato",
      no_message_yet: "Non ci sono ancora messaggi",
      all: "Tutto",
      message_details: "Dettagli",
      errors_number: "Numero errori",
      error_message: "Messaggio di errore",
      to: "Destinatario",
      subject: "Oggetto",
      message: "Messaggio",
      text: "Testo",
      url: "Url",
      content_type: "ContentType",
    },
  },
};

export default messages;
