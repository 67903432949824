const lensPhishing = {
  en: {
    translation: {
      lensPhishing_details: "Email analysis detail",
      date_from: "Start date",
      date_to: "End date",
      date: "Date",
      domain: "Domain",
      info: "Info",
      all: "All",
      country: "Country",
      email: "Email",
      record_updated: "Record updated",
      elements_displayed: "Elements displayed:",
      record_updated_text: "Record has been updated succesfully",
      no_lens_yet: "There are no lens phishing yet",
      customer: "Customer",
      user: "User",
      mail_object: "Object",
      response: "Response",
      time: "Analysis time",
      operator: "Operator",
      view: "View",
      threat_found: "Threat found",
      safe: "Safe",
      progress: "Progress",
      danger: "Danger",
      sender: "Sender",
      messages_analyzed_label1: "messages",
      messages_analyzed_label2: "analyzed",
      messages_progress_label1: "messages in",
      messages_progress_label2: "progress",
      messages_safe_label1: "marked as",
      messages_safe_label2: "safe",
      messages_dangerous_label1: "marked as",
      messages_dangerous_label2: "dangerous",
      checks: "Checks",
      level: "LEVEL",
      check: "CHECK",
      score: "SCORE",
    },
  },
  it: {
    translation: {
      lensPhishing_details: "Dettaglio analisi email",
      date_from: "Data inizio",
      date_to: "Data fine",
      date: "Data",
      domain: "Dominio",
      info: "Info",
      all: "Tutti",
      country: "Paese",
      email: "Email",
      record_updated: "Riga aggiornata",
      elements_displayed: "Elementi visualizzati:",
      record_updated_text: "La riga è stata aggiornata con successo",
      no_lens_yet: "Non ci sono ancora instanze di lens phishing",
      customer: "Cliente",
      user: "Utente",
      mail_object: "Oggetto email",
      response: "Responso",
      time: "Tempo di analisi",
      operator: "Operatore",
      view: "Visualizza",
      threat_found: "Minaccia",
      safe: "Sicura",
      progress: "Progress",
      danger: "Pericolosa",
      sender: "Mittente",
      messages_analyzed_label1: "messaggi",
      messages_analyzed_label2: "analizzati",
      messages_progress_label1: "messaggi in",
      messages_progress_label2: "corso",
      messages_safe_label1: "messaggi",
      messages_safe_label2: "sicuri",
      messages_dangerous_label1: "messaggi",
      messages_dangerous_label2: "pericolosi",
      checks: "Controlli",
      level: "LIVELLO",
      check: "CONTROLLO",
      score: "PUNTEGGIO",
    },
  },
};

export default lensPhishing;
