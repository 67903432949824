import config from "config/config";
import getTokens from "api/getTokens";

const  { baseUrl, clientUrl } = config;

/* *********************
  email
  password
********************** */

export default function getRansonwares(
  page,
  limit = 50,
  nation = "",
  domain = "",
  victim = "",
  gang = "",
  sector = "",
  showHidden = false,
  dateFrom = "",
  dateTo = "",
  orderBy = "",
  customFilters = false
) {
  let order = "sortDisabled";
  if (orderBy === "victim") {
    order = "victim:asc";
  } else if (orderBy === "victimReverse") {
    order = "victim:desc";
  } else if (orderBy === "victim_url") {
    order = "victim_url:asc";
  } else if (orderBy === "victim_urlReverse") {
    order = "victim_url:desc";
  } else if (orderBy === "date") {
    order = "detection:asc";
  } else if (orderBy === "dateReverse") {
    order = "detection:desc";
  } else if (orderBy === "gang" || orderBy === "group") {
    order = "gang:asc";
  } else if (orderBy === "gangReverse" || orderBy === "groupReverse") {
    order = "gang:desc";
  } else if (orderBy === "sector") {
    order = "sector:asc";
  } else if (orderBy === "sectorReverse") {
    order = "sector:desc";
  } else if (orderBy === "country") {
    order = "country:asc";
  } else if (orderBy === "countryReverse") {
    order = "country:desc";
  }
  const endpoint = `${baseUrl}/v1/ransomwares/?limit=${limit}&page=${page}&sortBy=${order}&country=${nation}&site=${domain}&victim=${victim}&gang=${gang}&sector=${sector}&showHidden=${showHidden}&dateFrom=${dateFrom}&dateTo=${dateTo}&customFilters=${customFilters ? 1 : 0}`;
  let access = "";
  const tokens = getTokens();
  if (tokens && tokens.access && tokens.access.token) {
    access = tokens.access.token;
  } else {
    window.location.href = "/login";
  }
  const options = {
    method: "GET",
    mode: "cors",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `${clientUrl || ""}`,
      "Access-Control-Allow-Credentials": true,
      Authorization: `bearer ${access}`,
    },
  };
  // console.log('login endpoint', {endpoint, BODY });
  return fetch(endpoint, options)
    .then((response) => {
      // console.log("getRansomwares", response);
      if (response.status !== 200 && response.status !== 201 && response.status !== 204) {
        throw new Error();
      }
      return response.json();
    })
    .catch((e) => {
      // eslint-disable-next-line
      console.log("error", e);
    });
}
