import React from "react";
import { useTranslation } from "react-i18next";

// MUI components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { IconButton } from "@mui/material";

// MUI icons
import CloseIcon from "@mui/icons-material/Close";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

// Types
import PropTypes from "prop-types";

export default function DemoAlert({ handleCloseBtn }) {

  const { t } = useTranslation();

  return (
    <MDBox
      p={1}
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        background: "#191919",
        color: "#fff",
      }}
    >
      <ErrorOutlineIcon sx={{ width: 16 }} />
      <MDTypography pl={1} fontSize="small" color="white">
        { t("demo_alert") }
      </MDTypography>
      <IconButton
        onClick={handleCloseBtn}
        color="white"
        sx={{
          width: "fit-content",
          borderRadius: "50%",
          position: "absolute",
          right: 1,
          cursor: "pointer",
          "&:hover": {
            color: "rgb(255,255,255)",
          },
        }}
      >
        <CloseIcon sx={{ width: 16 }} />
      </IconButton>
    </MDBox>
  );
}

DemoAlert.propTypes = {
  handleCloseBtn: PropTypes.func,
};

DemoAlert.defaultProps = {
  handleCloseBtn: PropTypes.func,
};
