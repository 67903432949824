import ransomware from "language/pages/ransomware";
import stealers from "language/pages/stealers";
import lensPhishing from "language/pages/lensPhishing";
import admin from "language/pages/admin";
import countryCode from "language/countryCode";
import countryList from "language/countryList";
import companySize from "language/companySize";
import sidenav from "language/sidenav";
import statistics from "language/pages/statistics";
import errors from "language/pages/auth/errors";
import login from "language/pages/auth/login";
import credentialConfirmation from "language/pages/auth/credentialConfirmation";
import passwordRecovery from "language/pages/auth/passwordRecovery";
import mfa from "language/pages/auth/mfa";
import alerts from "language/pages/alerts";
import codes from "language/pages/codes";
import analytics from "language/pages/analytics";
import reports from "language/pages/reports";
import messages from "language/pages/messages";
import user from "language/pages/user";
import darkWeb from "./pages/darkWeb";
import dataBreach from "./pages/dataBreach";

const resources = {
  lng: "it",
  defaultLanguage: "it",
  otherLanguages: ["it"],
  resources: {
    en: {
      translation: {
        test: "I18next test: english",
        hello: "Hello",
        cancel: "Cancel",
        close: "Close",
        save: "Save",
        ok: "Ok",
        yes: "Yes",
        no: "No",
        edit: "Edit",
        delete: "Delete",
        filters: "Filters",
        details: "Details",
        date: "Date",
        search: "Search",
        show: "Show",
        select_columns: "Customize columns shown",
        copy: "Copy",
        copied: "Copied",
        add: "Add",
        refresh: "Refresh",
        displayed: "Displayed",
        minutes: "Minutes",
        seconds: "Seconds",
        all_customers: "All customers",
        viewing: "Viewing",
        you_have: "You have",
        stealers_to_handle: "stealers to handle",
        token_expired: "Token expired",
        token_expired_text: "It seems your token expired, please, refresh your page.",
        filter: "Filter",
        demo_alert:
          "You are viewing the DEMO MODE. Many of the data contained therein are illustrative and not real.",
        ransomware: ransomware.en.translation,
        stealers: stealers.en.translation,
        lensPhishing: lensPhishing.en.translation,
        admin: admin.en.translation,
        statistics: statistics.en.translation,
        errors: errors.en.translation,
        login: login.en.translation,
        credentialConfirmation: credentialConfirmation.en.translation,
        passwordRecovery: passwordRecovery.en.translation,
        mfa: mfa.en.translation,
        alerts: alerts.en.translation,
        codes: codes.en.translation,
        analytics: analytics.en.translation,
        sidenav: sidenav.en.translation,
        countryCode: countryCode.en.translation,
        countryList: countryList.en.translation,
        companySize: companySize.en.translation,
        reports: reports.en.translation,
        messages: messages.en.translation,
        user: user.en.translation,
        password_strength: {
          "Too weak": "Too weak",
          Weak: "Weak",
          Medium: "Medium",
          Strong: "Strong",
        },
        darkWeb: darkWeb.en.translation,
        dataBreach: dataBreach.en.translation,
      },
    },
    it: {
      translation: {
        test: "I18next test: italian",
        hello: "Ciao",
        cancel: "Annulla",
        close: "Chiudi",
        save: "Salva",
        ok: "Ok",
        yes: "Si",
        no: "No",
        edit: "Modifica",
        filters: "Filtri",
        details: "Dettagli",
        delete: "Elimina",
        date: "Data",
        search: "Cerca",
        show: "Visualizza",
        select_columns: "Personalizza colonne visualizzate",
        copy: "Copia",
        copied: "Copiato",
        add: "Aggiungi",
        refresh: "Aggiorna",
        displayed: "Visualizzati",
        minutes: "Minuti",
        seconds: "Secondi",
        all_customers: "Tutti i clienti",
        viewing: "Visualizzando",
        you_have: "Hai",
        stealers_to_handle: "stealers da gestire",
        token_expired: "Token scaduto",
        token_expired_text: "Il tuo token è scaduto, per favore, ricarica la pagina.",
        filter: "Filtri",
        demo_alert:
          "Stai visualizzando la versione DEMO. Molti dei dati contenuti potrebbero essere illustrativi e non reali.",
        ransomware: ransomware.it.translation,
        stealers: stealers.it.translation,
        lensPhishing: lensPhishing.it.translation,
        admin: admin.it.translation,
        statistics: statistics.it.translation,
        errors: errors.it.translation,
        login: login.it.translation,
        credentialConfirmation: credentialConfirmation.it.translation,
        passwordRecovery: passwordRecovery.it.translation,
        mfa: mfa.it.translation,
        alerts: alerts.it.translation,
        codes: codes.it.translation,
        analytics: analytics.it.translation,
        sidenav: sidenav.it.translation,
        countryCode: countryCode.it.translation,
        countryList: countryList.it.translation,
        companySize: companySize.it.translation,
        reports: reports.it.translation,
        messages: messages.it.translation,
        user: user.it.translation,
        password_strength: {
          "Too weak": "Troppo debole",
          Weak: "Debole",
          Medium: "Media",
          Strong: "Forte",
        },
        darkWeb: darkWeb.it.translation,
        dataBreach: dataBreach.it.translation,
      },
    },
  },
};

export default resources;
