const alerts = {
    en: {
      translation: {
        elements_involved: "Elements involved in the alert: ",
      },
    },
    it: {
      translation: {
        elements_involved: "Elementi interessati dalla notifica: ",
      },
    },
  };
  
  export default alerts;
  