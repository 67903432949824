const credentialConfirmation = {
    en: {
      translation: {
        success_title: "Success",
        success_text: "You added your email to the credentials monitered successfully",
        error_title: "Error",
        error_text: "Error while adding your email to the credentails monitored",
        checking_title: "Checking",
        checking_text: "We are checking that everything is alright, please wait a moment",
        no_code_title: "No token",
        no_code_text: "Ops! It seems like there is no token to validate",
      },
    },
    it: {
      translation: {
        success_title: "Successo",
        success_text: "Hai aggiunto la tua email alle credenziali monitorate con successo",
        error_title: "Errore",
        error_text: "Errore durante l'aggiunta dell'indirizzo email alle credenziali monitorate",
        checking_title: "Controllo",
        checking_text: "Stiamo controllando che tutto sia a posto, per favore, aspetta un momento",
        no_code_title: "No token",
        no_code_text: "Ops! Sembra che non ci sia un token da validare",
      },
    },
  };
  
  export default credentialConfirmation;
  