// prop-types is a library for typechecking of props
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";
import editRansomware from "api/ransomware/editRansomware";
import uploadFile from "api/ransomware/uploadRansomwareImage";
import Autocomplete from "layouts/dashboards/stealers/autocomplete";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Grid from "@mui/material/Grid";
import moment from "moment";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import SweetAlert from "react-bootstrap-sweetalert";
import Tooltip from "@mui/material/Tooltip";
import Datetime from "react-datetime";
import { useMaterialUIController } from "context";
import config from "config/config";
import countryCode from "config/countryCode";

  export default function editRansomwareComponent(props) {
    const [victimName, setVictimName] = useState(props.element?.victim || "");
    const [victimUrl, setVictimUrl] = useState(props.element?.victim_url || "");
    const [victimGang, setVictimGang] = useState(props.element?.gang || props.element?.group || "");
    const [victimCountry, setVictimCountry] = useState(props.element?.country || "");
    const [victimSector, setVictimSector] = useState(props.element?.sector || "");
    const [victimCompanySize, setVictimCompanySize] = useState(props.element?.company_size || "");
    const [victimImage, setVictimImage] = useState(props.element?.image || "");
    const [victimDetection, setVictimDetection] = useState(props.element?.detection || "");
    const [victimIsHidden, setVictimIsHidden] = useState(props.element?.isHidden || false);

    const [controller, dispatch] = useMaterialUIController();
    const { client, user } = controller;
    const { t } = useTranslation();

    function readFile(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = () => reject();
      });
    }

    const selectAutocompleteCountry = (element) => {
        setVictimCountry(element);
    };
  
    return (
      <div>
        <SweetAlert
            style={{ display: "block", position: "relative", textAlign: "left" }}
            title=""
            onConfirm={() => {
            const newObject = {
                updateDate: new Date(),
                victim: victimName || "",
                victim_url: victimUrl || "",
                gang: victimGang || "",
                country: victimCountry || "",
                sector: victimSector || "",
                company_size: victimCompanySize || "",
                isHidden: victimIsHidden,
                image: victimImage || "",
                detection: victimDetection || "",
            };
            editRansomware(newObject, props.element.id)
                .then(() => {
                props.successFunction();
                })
                // eslint-disable-next-line
                .catch((err) => console.log("error", err));
            }}
            onCancel={() => {
            props.closeFunction();
            }}
            closeOnClickOutside
            showCancel
            cancelBtnText={t("cancel")}
            confirmBtnText={t("save")}
            confirmBtnCssClass="popupCloseButton"
            confirmBtnStyle={{
            boxShadow: "none",
            }}
            cancelBtnCssClass="popupCloseButton"
            cancelBtnStyle={{
            boxShadow: "none",
            }}
        >
            <div style={{ textAlign: "left" }}>
                <div style={{ textAlign: "left" }}>
                    <div style={{ marginBottom: "20px" }}>
                        <MDInput
                            type="input"
                            label={t("ransomware.victim")}
                            fullWidth
                            value={victimName}
                            onChange={(e) => {
                                setVictimName(e.target.value);
                            }}
                        />
                    </div>
                    <div style={{ marginBottom: "20px" }}>
                        <MDInput
                            type="input"
                            label={t("ransomware.site")}
                            fullWidth
                            value={victimUrl}
                            onChange={(e) => {
                                setVictimUrl(e.target.value);
                            }}
                        />
                    </div>
                    <div style={{ marginBottom: "20px" }}>
                        <MDInput
                        type="input"
                        label={t("ransomware.attack_group")}
                        fullWidth
                        value={victimGang}
                        onChange={(e) => {
                            setVictimGang(e.target.value);
                        }}
                        />
                    </div>
                    <div style={{ marginBottom: "20px" }}>
                        <MDInput
                            type="input"
                            label={t("ransomware.sector")}
                            fullWidth
                            value={victimSector}
                            onChange={(e) => {
                                setVictimSector(e.target.value);
                            }}
                        />
                    </div>
                    {/* DETECTION */}
                    <div style={{ position: "relative", marginBottom: "20px" }}>
                        <Datetime
                            inputProps={{
                                placeholder: t("ransomware.detection"),
                                value: victimDetection,
                            }}
                            closeOnSelect
                            timeFormat={false}
                            value={victimDetection}
                            onChange={(e) => {
                                setVictimDetection(moment(e).format("L"));
                            }}
                        />
                        {victimDetection ? (
                            <IconButton
                                onClick={() => {
                                setVictimDetection("");
                                }}
                                style={{ position: "absolute", right: "0", top: "6px" }}
                                size="small"
                                disableRipple
                            >
                                <Icon fontSize="medium">close</Icon>
                            </IconButton>
                        ) : null}
                    </div>
                    {/* COUNTRY */}
                    <Autocomplete
                        label={t("ransomware.nation")}
                        defaultValue={victimCountry ? t(`countryCode.${victimCountry}`) : ""}
                        onSelect={selectAutocompleteCountry}
                        list={Object.keys(countryCode).map((el) => ({
                            label: t(`countryCode.${el}`),
                            value: el,
                        }))}
                    />
                    {/* COMPANY SIZE */}
                    <select
                        onChange={(e) => {
                        if (e.target.value === "-") {
                            setVictimCompanySize("");
                        } else {
                            setVictimCompanySize(e.target.value);
                        }
                        }}
                        value={victimCompanySize}
                        className="selectDefault"
                        style={{
                            padding: "0.75rem",
                            borderRadius: "0.375rem",
                            borderColor: "#d2d6da",
                            marginBottom: "30px",
                            marginTop: "20px",
                        }}
                    >
                        <option value={"-"}>-</option>
                        <option value={"L"}>L ({t("companySize.L")})</option>
                        <option value={"M"}>M ({t("companySize.M")})</option>
                        <option value={"U"}>U ({t("companySize.U")})</option>
                        <option value={"S"}>S ({t("companySize.S")})</option>
                        <option value={"P"}>P ({t("companySize.P")})</option>
                    </select>
                    {/* ISHIDDEN */}
                    <div>
                        <input
                        type="checkbox"
                        style={{ marginBottom: "30px" }}
                        value={victimIsHidden}
                        onChange={() => {
                            setVictimIsHidden(!victimIsHidden);
                        }}
                        />{" "}
                        <span style={{ fontSize: "14px" }}>Is Hidden</span>
                    </div>
                    {/* <UploadRansomwareImage /> */}
                    <div style={{ fontSize: "16px" }}>Image:</div>
                    <input
                        type="file"
                        name="myImage"
                        onChange={(event) => {
                        const file = event.target.files[0];
                        let newFileName = "";
                            const opt = {
                            filename: "",
                            type: "",
                            };
                            if (file.name) {
                            opt.filename = file.name;
                            newFileName = file.name;
                            const split = file.name.split(".");
                            if (split.length > 1) {
                                opt.type = split[split.length - 1];
                            }
                            }
                            readFile(file)
                            .then((aaa) => {
                            const [dataApp, datas] = aaa.split(";", 2);
                            const [enc, base64] = datas.split(",", 2);
                            uploadFile(base64, opt)
                            // uploadFile(aaa, opt)
                                .then((resUp) => {
                                if (resUp && resUp.data && resUp.data.s3Key) {
                                    setVictimImage(resUp.data.s3Key);
                                }
                                })
                                .catch((resUpErr) => {
                                // eslint-disable-next-line
                                console.log("error upload file", resUpErr);
                                });
                            });
                        // setSelectedImage(event.target.files[0]);
                        }}
                    />
                    {
                        victimImage ?
                        <div className="ransomware_image_container">
                            <img className="ransomware_image" alt="Ransomware image" title="Ransomware image" src={`${process.env.REACT_APP_S3_RANSOMWARE_CDN}/${victimImage}`} />
                            <button
                                className="delete_image_ransomware_button"
                                type="button"
                                onClick={() => {
                                    setVictimImage("");
                                }}
                            >
                                <Icon fontSize="medium">close</Icon>
                            </button>
                        </div> : null
                    }
                </div>
            </div>
            <div className="popupButtonEdit">
                <Tooltip title={t("close")} placement="top">
                    <IconButton
                        onClick={() => {
                            props.closeFunction();
                        }}
                        size="small"
                        disableRipple
                    >
                        <Icon fontSize="medium">close</Icon>
                    </IconButton>
                </Tooltip>
            </div>
        </SweetAlert>
      </div>
    );
  }
