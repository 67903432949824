/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { Link } from "react-router-dom";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import logo from "assets/images/logo-light.png";

import login from "api/auth/login";
import addLog from "api/logs/addLog";
import MDTypography from "components/MDTypography";

import { useTranslation } from "react-i18next";
import Error from "layouts/authentication/components/error/Error";

function Basic() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [tooManyRequests, setTooManyRequests] = useState(false);
  const { t } = useTranslation();

  const performLogin = () => {
    const dataToSend = {
      email,
      password,
    };

    return login(dataToSend)
      .then((resp) => {
        if (resp.status === 429) {
          throw new Error("Too many requests!");
        }
        setError(false);
        const logToSend = {
          email,
          type: "Login Success",
          date: new Date(),
        };
        addLog(logToSend).then(() => {
          window.location.href = "/mfa";
        });
      })
      .catch((err) => {
        if (err.message === "Too many requests!") {
          setTooManyRequests(true);
          return;
        }
        setError(true);
        const logToSend = {
          email,
          type: "Login Failed",
          date: new Date(),
        };
        addLog(logToSend);
      });
  };

  // TODO gestire errori

  return (
    <BasicLayout>
      <Card>
        <img src={logo} className="auth-defenda-logo" alt="defenda-logo" />
        <MDBox pt={5} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                autoFocus
                fullWidth
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    performLogin();
                  }
                }}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                fullWidth
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    performLogin();
                  }
                }}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </MDBox>
            <Link to="/forgotPassword">
              <MDTypography
                pt={2}
                variant="body2"
                color="dark"
                opacity={0.5}
                sx={{ lineHeight: 0, fontSize: "13px", textAlign: "center" }}
              >
                {t("login.forgotPassword")}
              </MDTypography>
            </Link>
            <MDBox mt={4} mb={1}>
              <MDButton
                style={{ background: "#669FF6", color: "white" }}
                fullWidth
                onClick={() => {
                  performLogin();
                }}
              >
                {t("login.signIn")}
              </MDButton>
            </MDBox>
            {error ? <Error /> : null}
            {tooManyRequests ? (
              <MDBox mt={4} mb={1} sx={{ textAlign: "left" }}>
                <MDTypography variant="h6">{t("auth.too_many_request")}</MDTypography>
                <MDTypography fontSize="small">{t("auth.try_later")}</MDTypography>
              </MDBox>
            ) : null}
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
