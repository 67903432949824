const darkWeb = {
  en: {
    translation: {
      date: "Date",
      description: "Description",
      victim_nation: "Nation",
      attack_type: "Attack type",
      name: "Name",
      type: "Type",
      where: "Where",
      industry: "Indsustry",
      details: "Details",
      threat_actor_name: "Threat actor",
      victim: 'Victim',
      victim_url: 'Victim website',
      victim_industry: 'Victim industry',
      source_location: 'Source location',
      source_url: 'Source',
      intelligence: 'Intelligence',
      screenshot: 'Screenshot',
    },
  },

  it: {
    translation: {
      date: "Data",
      description: "Descrizione",
      victim_nation: "Nazione",
      attack_type: "Tipo attacco",
      name: "Nome",
      type: "Tipo",
      where: "Luogo",
      industry: "Indsustria",
      details: "Dettagli",
      threat_actor_name: "Threat actor",
      victim: 'Vittima',
      victim_url: 'Sito web vittima',
      victim_industry: 'Settore vittima',
      source_location: 'Accesso fonte',
      source_url: 'Fonte',
      intelligence: 'Intelligence',
      screenshot: 'Screenshot',
    },
  },
};

export default darkWeb;
