import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import changePassword from "api/users/changePassword";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import MDInput from "components/MDInput";
import SweetAlert from "react-bootstrap-sweetalert";
import Tooltip from "@mui/material/Tooltip";
import { useMaterialUIController } from "context";

export default function ChangePassword(props) {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);

  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [confirmPasswordMismatch, setConfirmPasswordMismatch] = useState(false);
  const [oldPasswordError, setOldPasswordError] = useState(false);

  const [controller, dispatch] = useMaterialUIController();
  const { user } = controller;
  const { t } = useTranslation();

  if (user && user.id) {
    return (
      <div>
        <SweetAlert
          style={{ display: "block", position: "relative", textAlign: "left" }}
          title={t("user.change_password")}
          onConfirm={() => {

            let error = false;

            if (!oldPassword) {
              setOldPasswordError(true);
              error = true;
            }

            if (!password) {
              setPasswordError(true);
              error = true;
            }

            if (!confirmPassword) {
              setConfirmPasswordError(true);
              error = true;
            }

            if (password !== confirmPassword) {
              setConfirmPasswordMismatch(true);
              error = true;
            }

            if (!error) {
              changePassword(oldPassword, password, user.id)
                .then((res) => {
                  if (res.text && res.text === "incorrect_password") {
                    props.errorFunction();
                  } else {
                    props.successFunction();
                  }
                })
                .catch((err) => console.log("error", err));
            }
          }}
          onCancel={() => {
            props.closeFunction();
          }}
          closeOnClickOutside
          showCancel
          cancelBtnText={t("cancel")}
          confirmBtnText={t("save")}
          confirmBtnCssClass="popupCloseButton"
          confirmBtnStyle={{
            boxShadow: "none",
          }}
          cancelBtnCssClass="popupCloseButton"
          cancelBtnStyle={{
            boxShadow: "none",
          }}
        >
          <div style={{ textAlign: "left" }}>
            <div style={{ textAlign: "left" }}>
              <div style={{ marginBottom: "20px", position: "relative" }}>
                <div>
                  <MDInput
                    type={!showOldPassword ? "password" : "input"}
                    label={t('user.old_password')}
                    fullWidth
                    value={oldPassword}
                    onChange={(e) => {
                      setOldPassword(e.target.value);
                      setOldPasswordError(false);
                    }}
                  />
                  <div className="clientInputArrayButton">
                    <IconButton
                      onClick={() => {
                        setShowOldPassword(!showOldPassword);
                      }}
                      size="small"
                      disableRipple
                    >
                      {showOldPassword ? (
                        <Icon fontSize="medium">visibility</Icon>
                      ) : (
                          <Icon fontSize="medium">lock</Icon>
                        )}
                    </IconButton>
                  </div>
                </div>
                {oldPasswordError && (
                  <div style={{ color: 'red', fontSize: '12pt', marginBottom: '10px' }}>{t("user.change_password_empty_field")}</div>
                )}
              </div>
              <div style={{ marginBottom: "20px", position: "relative" }}>
                <div>
                  <MDInput
                    type={!showPassword ? "password" : "input"}
                    label={t('user.password')}
                    fullWidth
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      setPasswordError(false);
                    }}
                  />
                  <div className="clientInputArrayButton">
                    <IconButton
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                      size="small"
                      disableRipple
                    >
                      {showPassword ? (
                        <Icon fontSize="medium">visibility</Icon>
                      ) : (
                          <Icon fontSize="medium">lock</Icon>
                        )}
                    </IconButton>
                  </div>
                </div>
                {passwordError && (
                  <div style={{ color: 'red', fontSize: '10pt', margin: '10px' }}>{t("user.change_password_empty_field")}</div>
                )}
              </div>
              <div style={{ marginBottom: "20px", position: "relative" }}>
                <div>
                  <MDInput
                    type={!showConfirmPassword ? "password" : "input"}
                    label={t('user.confirm_password')}
                    fullWidth
                    value={confirmPassword}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                      setConfirmPasswordError(false);
                      setConfirmPasswordMismatch(e.target.value !== password);
                    }}
                  />
                  <div className="clientInputArrayButton">
                    <IconButton
                      onClick={() => {
                        setShowConfirmPassword(!showConfirmPassword);
                      }}
                      size="small"
                      disableRipple
                    >
                      {showConfirmPassword ? (
                        <Icon fontSize="medium">visibility</Icon>
                      ) : (
                          <Icon fontSize="medium">lock</Icon>
                        )}
                    </IconButton>
                  </div>
                </div>
                {confirmPasswordError && (
                  <div style={{ color: 'red', fontSize: '10pt', margin: '10px' }}>{t("user.change_password_empty_field")}</div>
                )}
                {confirmPasswordMismatch && (
                  <div style={{ color: 'red', fontSize: '10pt', margin: '10px' }}>{t("user.change_password_password_mismatch")}</div>
                )}
              </div>
            </div>
          </div>
          <div className="popupButtonEdit">
            <Tooltip title={t("close")} placement="top">
              <IconButton
                onClick={() => {
                  props.closeFunction();
                }}
                size="small"
                disableRipple
              >
                <Icon fontSize="medium">close</Icon>
              </IconButton>
            </Tooltip>
          </div>
        </SweetAlert>
      </div>
    ); 
  }
}
