
/**
 * Returns the host part of an URL.
 * Handles special schemas returning a description after the host.
 * @param {String} url
 * @returns {String} host
 */
export function getHostFromUrl(url, forceSchema = null) {

  if (!url || url.trim().length <= 0)
    return "N/A";

  url = url.trim();

  try {
    if (forceSchema) url = forceSchema + "://" + url;

    const newUrl = new URL(url.trim());
    if (!newUrl) {
      return url;
    }

    if (newUrl.protocol === "android:") {

      let newHost = newUrl.host;

      // fallback for Chrome not parsing Android schema correctly
      if (newHost?.length <= 0) {
        const pos = url.indexOf("==@");
        if (pos > 0) {
          newHost = url.substring(pos + 3).replace(/^\/+|\/+$/g, '');;
        }
      }

      return `${newHost} (app)`;
    }

    return newUrl.host;

  } catch (err) {
    console.log("Error getting host", err);
    if (!forceSchema)
      return getHostFromUrl(url, "https");
    else
      return url;
  }

};

