// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import Card from "@mui/material/Card";

function StatisticsCounter({ counter, label1, label2, color, image, boxShadow, border, padding }) {
  return (
    <Card
      className="statistics-counter-card"
      sx={{ boxShadow: `${boxShadow} !important`, paddingRight: `${padding}` }}
    >
      <div className="statistics-counter-container" style={{ borderRight: `${border}` }}>
        {image ? (
          <div className="statistics-counter-image-container">
            <div className="statistics-counter-image">{image}</div>
          </div>
        ) : null}
        <div className="statistics-counter-text-container">
          <div className="statistics-counter">{counter}</div>
          {label1 ? <div>{label1}</div> : null}
          {label2 ? <div style={{ fontWeight: "bold", color }}>{label2}</div> : null}
        </div>
      </div>
    </Card>
  );
}

// Typechecking props for the StatisticsCounter
StatisticsCounter.propTypes = {
  counter: PropTypes.string.isRequired,
  label1: PropTypes.string,
  label2: PropTypes.string,
  color: PropTypes.string,
  image: PropTypes.node,
  boxShadow: PropTypes.string,
  border: PropTypes.string,
  padding: PropTypes.string,
};

StatisticsCounter.defaultProps = {
  label1: "",
  label2: "",
  color: "#000",
  image: null,
  boxShadow: "",
  border: "",
  padding: "",
};

export default StatisticsCounter;
