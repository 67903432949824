import config from "config/config";
import getTokens from "api/getTokens";
import axios from "axios";

const { baseUrl, clientUrl } = config;

export default function uploadRansomwareImage(body, { filename = null, mimeType = null }) {
  const endpoint = `${baseUrl}/v1/file/upload/`;
  let access = "";
  const tokens = getTokens();
  if (tokens && tokens.access && tokens.access.token) {
    access = tokens.access.token;
  } else {
    window.location.href = "/login";
  }
  const data = { body };
  if (filename) {
    data.filename = filename;
  }
  if (mimeType) {
    data.mimeType = mimeType;
  }
  const options = {
    headers: {
      Accept: "application/json",
      // "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `${clientUrl}`,
      "Access-Control-Allow-Credentials": true,
      Authorization: `bearer ${access}`,
    },
  };

  return axios.post(endpoint, data, options);
}
