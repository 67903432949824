import { useState, useEffect, useRef } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import { useMaterialUIController } from "context";

import getTokens from "api/getTokens";

import mfaQrCode from "api/auth/mfaQrCode";

// Two-factor authtentication input
import AuthCode from "react-auth-code-input";

// Images
import twoFactor from "api/auth/twoFactor";
import addLog from "api/logs/addLog";
import getUser from "api/users/getUser";
import logo from "assets/images/logo-light.png";

// Icons
import DoneIcon from "@mui/icons-material/Done";

// Translation
import { useTranslation } from "react-i18next";
import Error from "../components/error/Error";

function Mfa() {
  const ref = useRef(null);
  const [controller, dispatch] = useMaterialUIController();
  const { user } = controller;
  const [mfaSecret, setMfaSecret] = useState(user && user.mfaSecret ? user.mfaSecret : "");
  const [twoFactorCode, setTwoFactorCode] = useState("");
  const [qrCode, setQrCode] = useState(null);
  const [error, setError] = useState(false);
  const [secretKeyDisplay, SetSecretKeyDisplay] = useState(false);
  const [copiedKey, setCopiedKey] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    const tokens = getTokens();
    if (!user) return;

    if (!user.mfaEnabled) {
      mfaQrCode(user)
        .then((res) => {
          const file = res;
          // Encode the file using the FileReader API
          const reader = new FileReader(file);
          reader.readAsDataURL(file);
          reader.onloadend = () => {
            setQrCode(reader.result);
            // Logs data:<type>;base64,wL2dvYWwgbW9yZ...
          };
          setTimeout(() => {
            getUser(user.id).then((userRes) => {
              if (userRes && userRes.mfaSecret) {
                setMfaSecret(userRes.mfaSecret);
              }
            });
          }, 1000);
        })
        // eslint-disable-next-line
        .catch((err) => console.log("error mfaQrCode", err));
    } else if (tokens.mfa) {
      window.location.href = "/";
    }
  }, [user]);

  const validateTwoFactor = () => {
    if (user) {
      return twoFactor(user, twoFactorCode)
        .then((res) => {
          if (!res) {
            const logToSend = {
              email: user && user.email ? user.email : "",
              type: "Two factor authentication failed",
              date: new Date(),
            };
            setError(true);
            addLog(logToSend).then(() => {

            });
          } else {
            if (res.tokens?.refresh)
              localStorage.setItem("refresh", JSON.stringify(res.tokens.refresh));
            if (res.tokens?.access)
              localStorage.setItem("access", JSON.stringify(res.tokens.access));

            const logToSend = {
              email: user && user.email ? user.email : "",
              type: "Two factor authentication success",
              date: new Date(),
            };
            setError(false);
            addLog(logToSend).then(() => {
              window.location.href = "/";
            });
          }
        })
        .catch((err) => {
          // eslint-disable-next-line
          console.log("error login", err);
          const logToSend = {
            email: user && user.email ? user.email : "",
            type: "Two factor authentication failed",
            date: new Date(),
          };
          setError(true);
          addLog(logToSend).then(() => {

          });
        });
    }
    return null;
  };

  // TODO gestire errori

  // Move the focus on the "verify" button when the input fields are filled
  useEffect(() => {
    if (twoFactorCode.length === 6) ref.current.focus();
  }, [twoFactorCode]);

  // Handle input change
  const handleOnChange = (res) => {
    setTwoFactorCode(res);
  };

  return (
    <BasicLayout>
      <Card>
        <img src={logo} className="auth-defenda-logo" alt="defenda-logo" />
        {qrCode ? (
          <>
            <h5
              style={{
                textAlign: "center",
                padding: "20px 0",
                lineHeight: "1.3em",
              }}
            >
              {t("mfa.title")}
            </h5>
            <MDBox pb={3} style={{ display: "flex" }}>
              <div className="mfa-step">
                <MDTypography variant="subtitle1" fontSize="small" fontWeight="medium">
                  Step 1:
                </MDTypography>
              </div>
              <div>
                <MDTypography variant="subtitle1" fontSize="small">
                  {t("mfa.download_authenticator")}
                </MDTypography>
              </div>
            </MDBox>
            <MDBox pb={3} style={{ display: "flex" }}>
              <div className="mfa-step">
                <MDTypography variant="subtitle1" fontSize="small" fontWeight="medium">
                  Step 2:
                </MDTypography>
              </div>
              <div>
                <MDTypography variant="subtitle1" fontSize="small">
                  {t("mfa.scan_code")}
                </MDTypography>
              </div>
            </MDBox>
            <div style={{ textAlign: "center" }}>
              <img src={qrCode} title="qrmfa" alt="qr-mfa" style={{ maxWidth: "120px" }} />
            </div>
            <MDBox pb={2} textAlign="center">
              {mfaSecret && (
                <>
                  {!secretKeyDisplay ? (
                    <MDButton disableRipple onClick={() => SetSecretKeyDisplay(true)}>
                      <MDTypography
                        fontSize="small"
                        style={{
                          textDecoration: "underline",
                          color: "#669FF6",
                        }}
                      >
                        {t("mfa.cant_scan_code")}
                      </MDTypography>
                    </MDButton>
                  ) : (
                    <div className="qrcode-string">
                      <MDTypography variant="subtitle1" fontSize="small" pb={1}>
                        {t("mfa.copy_paste_key")}
                      </MDTypography>
                      {mfaSecret}
                      <MDButton
                        disableRipple
                        size="small"
                        style={{ margin: "10px 0" }}
                        onClick={() => {
                          navigator.clipboard.writeText(mfaSecret);
                          setCopiedKey(true);
                        }}
                      >
                        {copiedKey ? (
                          <span>
                            <DoneIcon /> {t("resources.copied")}
                          </span>
                        ) : (
                          <span>{t("resources.copy")}</span>
                        )}
                      </MDButton>
                    </div>
                  )}
                </>
              )}
            </MDBox>
            <MDBox pb={2} style={{ display: "flex" }}>
              <div className="mfa-step">
                <MDTypography variant="subtitle1" fontSize="small" fontWeight="medium">
                  Step 3:
                </MDTypography>
              </div>
              <div>
                <MDTypography variant="subtitle1" fontSize="small">
                  {t("mfa.enter_code_first_time")}
                </MDTypography>
              </div>
            </MDBox>
          </>
        ) : (
          <>
            <MDBox pb={2} py={2}>
              <MDTypography variant="subtitle1" fontSize="small" textAlign="center">
                {t("mfa.enter_code")}
              </MDTypography>
              <MDTypography variant="h5" textAlign="center" fontSize="medium" py={1}>
                {user.email}
              </MDTypography>
            </MDBox>
          </>
        )}
        <MDBox pb={3}>
          <MDBox /* component="form" role="form" */>
            <MDBox mb={2}>
              <AuthCode inputClassName="auth-boxes" onChange={handleOnChange} />
            </MDBox>
            <MDBox mt={3} mb={1}>
              <MDButton
                style={{ background: "#669FF6", color: "white" }}
                fullWidth
                ref={ref}
                onClick={() => {
                  validateTwoFactor();
                }}
              >
                {t("mfa.verify")}
              </MDButton>
            </MDBox>
            {error ? <Error /> : null}
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Mfa;
