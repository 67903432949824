/* eslint-disable react/no-unescaped-entities */
// @mui material components
// import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import getStealerById from "api/stealer/getStealerById";
import editStealer from "api/stealer/editStealer";
import stealerManaged from "api/stealer/stealerManaged";
import getFileCookieByExternalId from "api/cookie/getFileCookieById";
import TableContentFull from "layouts/dashboards/detailsStealer/tableContentFull";
import TableCreditCards from "layouts/dashboards/detailsStealer/tableCC";
import RequestReport from "layouts/dashboards/detailsStealer/requestReport";
import { useMaterialUIController } from "context";

// Material Dashboard 2 PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Icon from "@mui/material/Icon";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CodeIcon from '@mui/icons-material/Code';
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import DevicesIcon from "@mui/icons-material/Devices";
import LanIcon from "@mui/icons-material/Lan";
// import ImportantDevicesIcon from "@mui/icons-material/ImportantDevices";
import LanguageIcon from "@mui/icons-material/Language";
import ErrorIcon from "@mui/icons-material/Error";
import CoronavirusIcon from "@mui/icons-material/Coronavirus";
import CookieIcon from "@mui/icons-material/Cookie";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import BrowserUpdatedIcon from "@mui/icons-material/BrowserUpdated";
import MDInput from "components/MDInput";
import IconButton from "@mui/material/IconButton";
import SweetAlert from "react-bootstrap-sweetalert";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment";
import Modal from "@mui/material/Modal";

// Import images
import Report from "assets/images/credentials-details-icons/report.png";
import { getReasonToShow, ReasonToShow } from "../../../utilities/getReasonToShow";

function Stealers() {
  const [controller, _] = useMaterialUIController();
  const [stealer, setStealer] = useState([]);
  const [reasonToShow, setReasonToShow] = useState(null);
  const [stealerFromS3, setStealerFromS3] = useState(null);
  const [openStealerFromS3, setOpenStealerFromS3] = useState(false);
  const [showDomains, setShowDomains] = useState(false);
  const [showFileList, setShowFileList] = useState(false);
  const [editing, setEditing] = useState(false);
  const [popupSuccess, setPopupSuccess] = useState(false);
  const [errorRetrieveFileFromS3] = useState(false);
  const [IPAddress, setIPAddress] = useState("");
  const [Country, setCountry] = useState("");
  const [machineID, setMachineID] = useState("");
  const [machineName, setMachineName] = useState("");
  const [username, setUsername] = useState("");
  const [OS, setOS] = useState("");
  const [isHidden, setIsHidden] = useState(false);
  const [path, setPath] = useState("");
  const [timezone, setTimezone] = useState("");
  const { client, user } = controller;
  const { t } = useTranslation();

  useEffect(() => {
    const url = window && window.location && window.location.href ? window.location.href : "";
    let stealerId = "";
    if (url && url.length > 0 && url.includes("?")) {
      const arrayDivide = url.split("?");
      if (arrayDivide && arrayDivide.length > 0) {
        stealerId = arrayDivide[1];
      }
    }
    getStealerById(stealerId)
      .then((stealerFound) => {
        if (stealerFound) {
          // if (
          //   stealerFound &&
          //   (!stealerFound.managedBy ||
          //     (stealerFound.managedBy && stealerFound.managedBy.length < 1) ||
          //     (stealerFound.managedBy &&
          //       stealerFound.managedBy.length > 0 &&
          //       user &&
          //       user.id &&
          //       !stealerFound.managedBy.includes(user.id)))
          // ) {
          // stealerManaged(stealerId)
          //   .then(() => {})
          //   .catch((errManaged) => {
          //     // eslint-disable-next-line
          //   console.log("error setting stealer managed", errManaged);
          //   });
          // }
          console.log("stealerFound", stealerFound);
          setStealer(stealerFound);
          const reasonsToShow = getReasonToShow(stealerFound) || [];
          setReasonToShow(reasonsToShow.map((e) => ReasonToShow.getText(e, t)).join(", "));
          if (
            stealerFound.date &&
            stealerFound.originalItemId &&
            stealerFound.pathS3 &&
            stealerFound.indexKey &&
            user &&
            user.role &&
            user.role === "admin"
          ) {
            // const yearHash = moment(stealerFound.date).format("YYYY");
            // const weekHash = moment(stealerFound.date).format("W");
            getFileCookieByExternalId(
              stealerFound.pathS3.replaceAll("/", "_"),
              stealerFound.indexKey
              // `payloads-${yearHash}-${weekHash}-${stealerFound.originalItemId}`
            )
              .then((stealerFile) => {
                // TODO fare qualcosa con il file
                if (stealerFile) {
                  setStealerFromS3(stealerFile);
                }
                // console.log("file preso da s3", JSON.stringify(stealerFile));
              })
              // eslint-disable-next-line
            .catch((err) => console.log("error", err));
          }
        }
      })
      // eslint-disable-next-line
  .catch((err) => console.log("error", err));
  }, []);

  function getDomainShown() {
    let output = stealer.domains[0].length > 30 ? `${stealer.domains[0]}` : stealer.domains[0];
    if (
      (client && client.passwords && client.passwords.length > 0) ||
      (client.cookies && client.cookies.length) > 0
    ) {
      const indexTofind = stealer.domains.findIndex(
        (el) =>
          (client.passwords &&
            client.passwords.length > 0 &&
            client.passwords.some((inner) => inner === el)) ||
          (client.cookie &&
            client.cookie.length > 0 &&
            client.cookie.some((inner) => inner.url === el))
      );
      if (indexTofind >= 0) {
        output =
          stealer.domains[indexTofind].length > 30
            ? `${stealer.domains[indexTofind]}`
            : stealer.domains[indexTofind];
      } else {
        output = "";
      }
    } else if (stealer.domains.length > 1) {
      output += ` + ${stealer.domains.length - 1}`;
    }
    return output;
  }

  function getPassword(passwords) {

    const isClientsPassword = function(pass, client) {

      if (!pass.URL) return false;

      const passwordMatch = client.passwords?.some((cli) => pass.URL.includes(cli));
      const passwordAppsMatch = client.passwordsApps?.some((cli) => pass.URL.includes(cli.domain));

      return passwordMatch || passwordAppsMatch;
    };

    return passwords.map((pass, index) => {
      if (isClientsPassword(pass,cli)) {
        return (
          <div className="detailsStealerTableRow" key={`password-for-client-${index.toString()}`}>
            <span className="label-cell">{pass.username}</span>
            <span className="elem-cell">
              {pass?.password?.length >= 8
                ? `${pass.password.substring(0, 2)}****${pass.password.slice(-2)}`
                : pass?.password?.length >= 6
                ? `${pass.password.substring(0, 1)}****${pass.password.slice(-1)}`
                : "******"}
            </span>
          </div>
        );
      }
      return null;
    });
  }

  function getCookieToView(cookies) {
    return cookies.map((cook, index) => {
      if (
        client.cookies.some(
          (cli) =>
            /* cli.url === cook.domain */
            cook.domain && cli.url && cook.domain.includes(cli.url) && cli.name === cook.name
        )
      ) {
        return (
          <div className="detailsStealerTableRow" key={`cookie-for-client-${index.toString()}`}>
            <span className="label-cell">{cook.domain}</span>
            <span className="elem-cell">{cook.name}</span>
          </div>
        );
      }
      return null;
    });
  }

  function getFileList(fileList) {
    return fileList.map((file, fileIndex) => {
      if (file.children && file.children.length > 0) {
        return (
          <div
            key={`file-list-folder-row-${fileIndex.toString()}`}
            className="file-list-folder-row"
          >
            <div className="file-list-folder-icon-label-container">
              <Icon fontSize="medium">folder</Icon>
              <span className="file-list-folder-label">{file.name ? file.name : ""}</span>
            </div>
            <div className="file-list-folder-children-container">{getFileList(file.children)}</div>
          </div>
        );
      }
      if (file.name) {
        return (
          <div key={`file-list-file-row-${fileIndex.toString()}`} className="file-list-folder-row">
            <div className="file-list-folder-icon-label-container">
              <Icon fontSize="medium">article</Icon>
              <span className="file-list-folder-label">{file.name ? file.name : ""}</span>
            </div>
          </div>
        );
      }
      return null;
    });
  }

  function getInput(label, value, operation) {
    return (
      <div style={{ marginBottom: "20px" }}>
        <MDInput
          type="input"
          label={label}
          fullWidth
          value={value}
          onChange={(e) => {
            operation(e.target.value);
          }}
        />
      </div>
    );
  }

  function getElementToEdit() {
    return (
      <SweetAlert
        style={{ display: "block", position: "relative", textAlign: "left" }}
        title=""
        onConfirm={() => {
          // console.log("ora devo modificare");
          const newObject = { updateDate: new Date() };
          if (IPAddress) {
            Object.assign(newObject, { IPAddress });
          }
          if (Country) {
            Object.assign(newObject, { nation: Country });
          }
          if (machineID) {
            Object.assign(newObject, { machineID });
          }
          if (machineName) {
            Object.assign(newObject, { machineName });
          }
          if (username) {
            Object.assign(newObject, { username });
          }
          if (OS) {
            Object.assign(newObject, { OS });
          }
          if (isHidden) {
            Object.assign(newObject, { isHidden: true });
          } else {
            Object.assign(newObject, { isHidden: false });
          }
          editStealer(newObject, stealer.id)
            .then(() => {
              setEditing(false);
              setPopupSuccess(true);
              setStealer(Object.assign(stealer, newObject));
            })
            // eslint-disable-next-line
            .catch((err) => console.log("error", err));
        }}
        onCancel={() => {
          setEditing(false);
          if (stealer.IPAddress) {
            setIPAddress(stealer.IPAddress);
          }
          if (stealer.nation) {
            setCountry(stealer.nation);
          }
          if (stealer.machineID) {
            setMachineID(stealer.machineID);
          }
          if (stealer.machineName) {
            setMachineName(stealer.machineName);
          }
          if (stealer.username) {
            setUsername(stealer.username);
          }
          if (stealer.OS) {
            setOS(stealer.OS);
          }
          if (stealer.path) {
            setPath(stealer.path);
          }
          if (stealer.timezone) {
            setTimezone(stealer.timezone);
          }
          if (stealer.isHidden) {
            setIsHidden(stealer.isHidden);
          } else {
            setIsHidden(false);
          }
        }}
        closeOnClickOutside
        showCancel
        cancelBtnText={t("cancel")}
        confirmBtnText={t("save")}
        confirmBtnCssClass="popupCloseButton"
        confirmBtnStyle={{
          boxShadow: "none",
        }}
        cancelBtnCssClass="popupCloseButton"
        cancelBtnStyle={{
          boxShadow: "none",
        }}
      >
        <div style={{ textAlign: "left" }}>
          <div style={{ fontSize: "16px", marginBottom: "20px" }}>
            {stealer.date
              ? `${moment(stealer.date).format("DD")}/${moment(stealer.date).format("MM")}/${moment(
                  stealer.date
                ).format("YYYY")}`
              : ""}
          </div>
          <h2 style={{ fontSize: "20px", marginBottom: "30px" }}>
            {stealer.domains && stealer.domains.length > 0 ? getDomainShown(stealer.domains) : ""}
          </h2>
          {getInput("IP Address", IPAddress, setIPAddress)}
          {getInput("Nation", Country, setCountry)}
          {getInput("Machine ID", machineID, setMachineID)}
          {getInput("Machine Name", machineName, setMachineName)}
          {getInput("Username", username, setUsername)}
          {getInput("OS", OS, setOS)}
          {getInput("Path", path, setPath)}
          {getInput("Timezone", timezone, setTimezone)}
          {/* bottone per la modifica della visibilità dello stealer */}
          <div>
            <IconButton
              onClick={() => {
                setIsHidden(!isHidden);
              }}
              size="small"
              disableRipple
            >
              {isHidden ? (
                <>
                  <VisibilityOffIcon fontSize="medium" style={{ marginRight: "10px" }} />{" "}
                  {t("stealers.make_stealer_visible")}
                </>
              ) : (
                <>
                  <Icon fontSize="medium" style={{ marginRight: "10px" }}>
                    visibility
                  </Icon>{" "}
                  {t("stealers.hide_stealer")}
                </>
              )}
            </IconButton>
          </div>
        </div>
        <div className="popupButtonEdit">
          <Tooltip title={t("close")} placement="top">
            <IconButton
              onClick={() => {
                setEditing(false);
              }}
              size="small"
              disableRipple
            >
              <Icon fontSize="medium">close</Icon>
            </IconButton>
          </Tooltip>
        </div>
      </SweetAlert>
    );
  }

  if (!stealer) {
    return null;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {stealer !== "error" ? (
        <>
          <div className="popupInfoContainer">
            {showDomains ? (
              <SweetAlert
                style={{ display: "block", position: "relative", textAlign: "left" }}
                title=""
                onConfirm={() => {
                  setShowDomains(false);
                }}
                confirmBtnText={t("close")}
                confirmBtnCssClass="popupCloseButton"
                confirmBtnStyle={{
                  boxShadow: "none",
                }}
              >
                {stealer.domains && stealer.domains.length > 0 ? (
                  <div className="detail-domains-to-show">
                    {stealer.domains.map((dom, ind) => (
                      <div
                        className={`detail-domain-single ${ind % 2 === 0 ? "" : "even"}`}
                        key={`dom-to-show-${ind.toString()}`}
                      >
                        {dom}
                      </div>
                    ))}
                  </div>
                ) : null}
                <div className="popupButtonEdit">
                  <Tooltip title={t("close")} placement="top">
                    <IconButton
                      onClick={() => {
                        setShowDomains(false);
                      }}
                      size="small"
                      disableRipple
                    >
                      <Icon fontSize="medium">close</Icon>
                    </IconButton>
                  </Tooltip>
                </div>
              </SweetAlert>
            ) : null}
            {showFileList ? (
              <SweetAlert
                style={{ display: "block", position: "relative", textAlign: "left" }}
                title=""
                onConfirm={() => {
                  setShowFileList(false);
                }}
                confirmBtnText={t("close")}
                confirmBtnCssClass="popupCloseButton"
                confirmBtnStyle={{
                  boxShadow: "none",
                }}
              >
                <div style={{ textAlign: "left" }}>{getFileList(stealer.fileList)}</div>
                <div className="popupButtonEdit">
                  <Tooltip title={t("close")} placement="top">
                    <IconButton
                      onClick={() => {
                        setShowFileList(false);
                      }}
                      size="small"
                      disableRipple
                    >
                      <Icon fontSize="medium">close</Icon>
                    </IconButton>
                  </Tooltip>
                </div>
              </SweetAlert>
            ) : null}
            {editing && stealer.id ? getElementToEdit() : null}
            <Modal
              open={openStealerFromS3}
              onClose={() => setOpenStealerFromS3(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <div
                className="boxes-border-radius"
                style={{
                  maxWidth: "600px",
                  maxHeight: "90%",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  background: "white",
                  padding: "25px",
                  overflow: "scroll",
                }}
              >
                <pre style={{ textAlign: "left", fontSize: "12px" }}>
                  {JSON.stringify(stealerFromS3, null, 2)}
                </pre>
              </div>
            </Modal>
            {popupSuccess ? (
              <SweetAlert
                style={{ display: "block" }}
                title={t("stealers.record_updated")}
                success
                onConfirm={() => {
                  setPopupSuccess(false);
                }}
                confirmBtnText="Ok"
                confirmBtnCssClass="popupCloseButton"
                confirmBtnStyle={{
                  boxShadow: "none",
                }}
              >
                {t("stealers.record_updated_text")}
              </SweetAlert>
            ) : null}
          </div>
          <div className="attack-details-container">
            <h2 className="attack-details-title">{t("stealers.attack_details")}</h2>
          </div>
          <div className="stealer-details-container">
            {stealer.date ? (
              <div className="popupLabel">
                <CalendarTodayIcon fontSize="medium" style={{ marginRight: "10px" }} />
                {/* <img src={Calendar} alt="calendar-img" className="details-pages-icons" /> */}
                <span style={{ fontWeight: "bold" }}>{t(`stealers.date_of_compromise`)}</span>
                <span>
                  {moment(new Date(stealer.date)).utc().format("DD/MM/YYYY - HH:mm")}
                  {stealer.timezone ? ` (${stealer.timezone})` : null}
                </span>
              </div>
            ) : null}
            {stealer.dateProcessed ? (
              <div className="popupLabel">
                <BrowserUpdatedIcon fontSize="medium" style={{ marginRight: "10px" }} />
                {/* <img src={Calendar} alt="calendar-img" className="details-pages-icons" /> */}
                <span style={{ fontWeight: "bold" }}>{t(`stealers.acquisition_date`)}</span>
                <span>
                  {moment(new Date(stealer.dateProcessed)).utc().format("DD/MM/YYYY - HH:mm")} UTC
                </span>
              </div>
            ) : null}
            {stealer.countryCode ? (
              <div className="popupLabel">
                <Tooltip title={t("stealers.ip_geo_data_text")} placement="top-start" arrow={false}>
                  <div style={{ display: "flex" }}>
                    <LocationOnIcon fontSize="medium" style={{ marginRight: "10px" }} />
                    <span style={{ fontWeight: "bold" }}>{t(`stealers.country`)}</span>
                    <span>{t(`countryCode.${stealer.countryCode}`)}</span>
                    {stealer.geoInfo && stealer.geoInfo.stateProv ? (
                      <span> - {stealer.geoInfo.stateProv}</span>
                    ) : null}
                    {stealer.geoInfo && stealer.geoInfo.district ? (
                      <span> - {stealer.geoInfo.district}*</span>
                    ) : null}
                  </div>
                </Tooltip>
              </div>
            ) : null}
            {reasonToShow ? (
              <div className="popupLabel">
                <div style={{ display: "flex" }}>
                  <ErrorIcon fontSize="medium" style={{ marginRight: "10px" }} />
                  <span style={{ fontWeight: "bold" }}>{t(`stealers.reason_to_show`)}</span>
                  <span>{reasonToShow}</span>
                </div>
              </div>
            ) : null}
            <div className="popupLabel">
              <div style={{ display: "flex" }}>
                <CoronavirusIcon fontSize="medium" style={{ marginRight: "10px" }} />
                <span style={{ fontWeight: "bold" }}>{t(`stealers.origin`)}</span>
                {stealer.isCombolist ? <span>Phishing</span> : <span>Malware</span>}
              </div>
            </div>
            {stealer.brand ? (
              <div className="popupLabel">
                <div style={{ display: "flex" }}>
                  <CodeIcon fontSize="medium" style={{ marginRight: "10px" }} />
                  <span style={{ fontWeight: "bold" }}>{t(`stealers.brand`)}</span>
                  <span>{stealer.brand}</span>
                </div>
              </div>
            ) : null}
            {/* {stealer.language ? (
              <div className="popupLabel">
                <Icon fontSize="medium">flag</Icon>
                <span>{t("stealers.language")}: </span>
                <span style={{ fontWeight: "bold" }}>{stealer.language}</span>
              </div>
            ) : null} */}
            {/* {stealer.OS ? (
              <div className="popupLabel">
                <Icon fontSize="medium">computer</Icon>
                <span>{t("stealers.operative_system")}: </span>
                <span style={{ fontWeight: "bold" }}>{stealer.OS}</span>
              </div>
            ) : null} */}
            {stealer.IPAddress || stealer.IP ? (
              <div className="popupLabel">
                <LanIcon fontSize="medium" style={{ marginRight: "10px" }} />
                <span style={{ fontWeight: "bold" }}>{t("stealers.ip_address")}</span>
                <span>{stealer.IPAddress || stealer.IP}</span>
              </div>
            ) : null}
            {/* stealer.machineID ? (
              <div className="popupLabel">
                <ImportantDevicesIcon fontSize="medium" style={{ marginRight: "10px" }} />
                <span>{t("stealers.machine_id")}: </span>
                <span
                  style={{
                    fontWeight: "bold",
                    maxWidth: "calc(100% - 170px)",
                    display: "block",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {stealer.machineID}
                </span>
              </div>
            ) : null} */}
            {stealer.machineName ? (
              <div className="popupLabel">
                <DevicesIcon fontSize="medium" style={{ marginRight: "10px" }} />
                <span>{t("stealers.machine_name")}: </span>
                <span style={{ fontWeight: "bold" }}>{stealer.machineName}</span>
              </div>
            ) : null}
            {stealer.domains && stealer.domains.length > 0 ? (
              <div className="popupLabel">
                <LanguageIcon fontSize="medium" style={{ marginRight: "10px" }} />
                <span>{t("stealers.urls")}: </span>
                <button
                  type="button"
                  style={{ fontWeight: "bold" }}
                  className="emply-label-button"
                  onClick={() => {
                    setShowDomains(true);
                  }}
                >
                  {getDomainShown()}
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
          {/* {stealer.path ? (
            <div className="popupLabel" style={{ marginTop: "15px" }}>
              <FolderIcon fontSize="medium" style={{ marginRight: "10px" }} />
              <span>{t("stealers.path")}: </span>
              <span style={{ fontWeight: "bold" }}>{stealer.path}</span>
            </div>
          ) : null} */}
          {user && user.id && stealer && stealer.id ? (
            <RequestReport
              stealer={stealer.id}
              user={user.id}
              clients={stealer.results?.map((e) => e.client)}
              role={user.role}
            />
          ) : null}
          {stealer.passwords && stealer.passwords.length > 0 ? (
            <TableContentFull elements={stealer.passwords} type="passwords" />
          ) : null}
          {stealer.creditcards && stealer.creditcards.length > 0 ? (
            <TableCreditCards elements={stealer.creditcards} />
          ) : null}
          {stealer.isCombolist ? (
            <TableContentFull
              elements={[
                {
                  strength: stealer.passwordStrength,
                  length: stealer.realPassword?.length,
                  password: stealer.realPassword,
                  URL: `${stealer.domain}${stealer.port ? `:${stealer.port}` : ""}`,
                  username: stealer.username,
                },
              ]}
              type="passwords"
            />
          ) : null}
          {stealer.cookies && stealer.cookies.length > 0 ? (
            <TableContentFull elements={stealer.cookies} type="cookies" />
          ) : null}
          {reasonToShow === ReasonToShow.getText(ReasonToShow.IPS, t) ? (
            <div
              className="boxes-border-radius"
              style={{
                padding: "12px",
                color: "white",
                fontSize: "small",
                background: "var(--blue-color)",
                textAlign: "justify",
                marginBottom: "20px",
              }}
            >
              {t("stealers.ip_paragraph")}
            </div>
          ) : null}
          {stealer.reason &&
          stealer.reason.includes("password") &&
          stealer.passwords &&
          stealer.passwords.length > 0 &&
          client &&
          client.domains &&
          client.domains.length > 0 ? (
            <>
              <div className="popupLabel" style={{ marginTop: "50px" }}>
                <Icon fontSize="medium" style={{ marginRight: "10px" }}>
                  visibility
                </Icon>
                <img src={Report} alt="report-img" className="details-pages-icons" />
                <span>{t("stealers.credentials")}: </span>
              </div>
              <div className="detailsStealerTable">{getPassword(stealer.passwords)}</div>
            </>
          ) : null}
          {stealer?.results?.results?.cookies?.length &&
          stealer.cookie &&
          stealer.cookie.length > 0 &&
          client &&
          client.domains &&
          client.domains.length > 0 ? (
            <>
              <div className="popupLabel" style={{ marginTop: "50px" }}>
                <CookieIcon fontSize="medium" style={{ marginRight: "10px" }} />
                <span>{t("stealers.cookies")}: </span>
              </div>
              <div className="detailsStealerTable">{getCookieToView(stealer.cookie)}</div>
            </>
          ) : null}
          {user && user.role && user.role === "admin" && stealerFromS3 ? (
            <div>
              <button
                type="button"
                className="request-report-button"
                onClick={() => {
                  setOpenStealerFromS3(true);
                }}
              >
                {t("stealers.open")}
              </button>
            </div>
          ) : null}
          {user && user.role && user.role !== "admin" ? (
            <div style={{ marginTop: "50px" }}>
              {!stealer.managedBy ||
              (stealer.managedBy && stealer.managedBy.length < 1) ||
              (stealer.managedBy &&
                stealer.managedBy.length > 0 &&
                user &&
                user.id &&
                !stealer.managedBy.includes(user.id)) ? (
                <button
                  type="button"
                  className="request-report-button"
                  onClick={() => {
                    if (stealer.id) {
                      stealerManaged(stealer.id)
                        .then((resManaged) => {
                          if (
                            resManaged &&
                            resManaged.managedBy &&
                            resManaged.managedBy.length > 0
                          ) {
                            const newStealer = { ...stealer, managedBy: resManaged.managedBy };
                            setStealer(newStealer);
                          }
                        })
                        .catch((errManaged) => {
                          // eslint-disable-next-line
                            console.log("error setting stealer managed", errManaged);
                        });
                    }
                  }}
                >
                  {t("stealers.manage")}
                </button>
              ) : (
                <span style={{ color: "green" }}>{t("stealers.already_managed")}</span>
              )}
            </div>
          ) : null}
          {/* bottone per la modifica dello stealer */}
          {errorRetrieveFileFromS3 ? (
            <div className="stealer-details-notes error-get-file-from-s3">
              {t("stealers.error_retrieve_file_from_s3")}
            </div>
          ) : null}
        </>
      ) : (
        <h4>Invalid id</h4>
      )}
    </DashboardLayout>
  );
}

export default Stealers;
