import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useMaterialUIController } from "context";

// @mui material components
import Grid from "@mui/material/Grid";
import StatisticsCounter from "components/StatisticsCounter";

// Icons images
import iconStolenCookies from "assets/images/stealers/icon_stolen_cookies.png";
import iconStolenCredentials from "assets/images/stealers/icon_stolen_credentials.png";
import iconStolenPasswords from "assets/images/stealers/icon_stolen_passwords.png";
import iconYtd from "assets/images/stealers/icon_ytd.png";

// API imports
import getStealersCounters from "api/statistics/getStealersCounters";
import countStealersClient from "api/statistics/countStealersClient";
import countStealersLastPeriodClient from "api/statistics/countStealersLastPeriodClient";
import ServerError from "utilities/ServerError";
import MDBox from "components/MDBox";

function StealersStatistics({ columns, boxShadow, border, padding }) {
  const [passwordsCount, setPasswordsCount] = useState(0);
  const [cookiesCount, setCookiesCount] = useState(0);
  const [stealersCount, setStealersCount] = useState(0);
  const [stealersLastPeriodCount, setStealersLastPeriodCount] = useState(0);
  const [serverError, setServerError] = useState(false);
  const [showServerError, setShowServerError] = useState(false);
  const { t } = useTranslation();

  const [controller] = useMaterialUIController();
  const { client } = controller;

  const lastClient = useRef();

  // Convert number to abbreviation
  const convertMilionNum = (str) => {
    const newStr = str.replace(/(.)(?=(\d{3})+$)/g, "$1.");
    if (newStr.length >= 9) {
      const arr = newStr.split(".");
      const newNum = `${arr[0]}.${arr[1][0]}M`;
      return newNum;
    }
    return newStr;
  };

  function refreshCountersClient() {
    if (client?.cookies?.length > 0 || client?.passwords?.length > 0) {
      const domainsToSearch = [];

      if (client.cookies?.length > 0) {
        client.cookies.forEach((cookieUrl) => {
          if (cookieUrl.url) {
            domainsToSearch.push(cookieUrl.url);
          }
        });
      }

      if (client.passwords?.length > 0) {
        domainsToSearch.concat(client.passwords);
      }

      Promise.allSettled([
        getStealersCounters(domainsToSearch),
        countStealersClient(domainsToSearch),
        countStealersLastPeriodClient()
      ]).then((results) => {
        
        if (results.some(e => e.status === "rejected")) {
          setServerError(true);
        }

        if (results[0].status === "fulfilled") {
          const stealersCounters = results[0].value;
          setPasswordsCount(stealersCounters.passwords);
          setCookiesCount(stealersCounters.cookies);
        } else {
          console.log("error while counting stolen credentials", results[0].reason);
        }

        if (results[1].status === "fulfilled") {
          const stealersClient = results[1].value;
          setStealersCount(stealersClient.counter);
        } else {
          console.log("error while counting stolen credentials", results[1].reason);
        }
        
        if (results[2].status === "fulfilled") {
          const stealersLastPeriodClient = results[2].value;
          setStealersLastPeriodCount(stealersLastPeriodClient.counter);
        } else {
          console.log("error while counting stolen credentials", results[2].reason);
        }

      }).catch(err => {
        setServerError(true);
        console.error(err);
      });
    }
  }

  useEffect(() => {
    const newClient = JSON.stringify(client);
    if (newClient !== "\"*\"" && lastClient.current !== newClient) {
      lastClient.current = newClient;
      refreshCountersClient();
    }
  }, [client]);

  useEffect(() => {
    const myTimeout = setTimeout(() => {
      setShowServerError(true);
    }, 1000);

    return () => clearTimeout(myTimeout);
  }, []);

  return (
    <>
      {!serverError ? (
        <>
          {stealersCount >= 0 ? (
            <Grid item xs={12} md={6} xl={columns}>
              <StatisticsCounter
                counter={convertMilionNum(String(stealersCount))}
                color="#54c1fb"
                label1={t("stealers.ytd_label1")}
                label2={t("stealers.ytd_label2")}
                image={<img alt="YTD" title="YTD" src={iconYtd} />}
                boxShadow={boxShadow}
                border={border}
                padding={padding}
              />
            </Grid>
          ) : null}
          {stealersLastPeriodCount >= 0 ? (
            <Grid item xs={12} md={6} xl={columns}>
              <StatisticsCounter
                counter={convertMilionNum(String(stealersLastPeriodCount))}
                color="#a019ce"
                label1={t("stealers.last_period_label1")}
                label2={t("stealers.last_period_label2")}
                image={
                  <img
                    alt="Stolen credentials last period"
                    title="Stolen credentials last period"
                    src={iconStolenCredentials}
                  />
                }
                boxShadow={boxShadow}
              />
            </Grid>
          ) : null}
          {passwordsCount >= 0 ? (
            <Grid item xs={12} md={6} xl={columns}>
              <StatisticsCounter
                counter={convertMilionNum(String(passwordsCount))}
                color="#15d2b0"
                label1={t("stealers.passwords_label1")}
                label2={t("stealers.passwords_label2")}
                image={
                  <img alt="Stolen passwords" title="Stolen passwords" src={iconStolenPasswords} />
                }
                boxShadow={boxShadow}
                border={border}
                padding={padding}
              />
            </Grid>
          ) : null}
          {cookiesCount >= 0 ? (
            <Grid item xs={12} md={6} xl={columns}>
              <StatisticsCounter
                counter={convertMilionNum(String(cookiesCount))}
                color="#2d75da"
                label1={t("stealers.cookies_label1")}
                label2={t("stealers.cookies_label2")}
                image={<img alt="Stolen cookies" title="Stolen cookies" src={iconStolenCookies} />}
                boxShadow={boxShadow}
              />
            </Grid>
          ) : null}
        </>
      ) : (
        <MDBox className="server-error-container">{showServerError && <ServerError />}</MDBox>
      )}
    </>
  );
}

StealersStatistics.propTypes = {
  columns: PropTypes.number,
  boxShadow: PropTypes.string,
  border: PropTypes.string,
  padding: PropTypes.string,
};

StealersStatistics.defaultProps = {
  columns: 0,
  boxShadow:
    "0rem 0.25rem 0.375rem -0.0625rem rgb(0 0 0 / 10%), 0rem 0.125rem 0.25rem -0.0625rem rgb(0 0 0 / 6%);",
  border: "",
  padding: "",
};

export default StealersStatistics;
