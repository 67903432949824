const reports = {
  en: {
    translation: {
      date: "Date",
      type: "Type",
      details: "Details",
      no_reports_yet: "No requests of reports yet",
      pending: "Pending",
      no_stealer: "No stealer",
      user: "User",
      show: "Show",
    },
  },
  it: {
    translation: {
      date: "Data",
      type: "Tipo",
      details: "Dettagli",
      no_reports_yet: "Non ci sono ancora richieste di report",
      pending: "In attesa",
      no_stealer: "Nessuno stealer",
      user: "Utente",
      show: "Visualizza",
    },
  },
};

export default reports;
