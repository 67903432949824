const passwordRecovery = {
  en: {
    translation: {
      reset_password: "Reset password",
      check_your_email: "Check your email",
      email_sent_message:
        "If there is an account associated with this email address, you will receive an email shortly with instructions to reset your password.",
      email_not_sent: "I did not receive the email",
      email_not_sent_message:
        "If you do not receive the email immediately, please allow some time before making a new request, as there is a limit to the number of allowed requests",
    },
  },

  it: {
    translation: {
      reset_password: "Resetta password",
      check_your_email: "Controlla la tua email",
      email_sent_message:
        "Se un account registrato è associato a questo indirizzo email, riceverai a breve un messaggio con le istruzioni per reimpostare la password",
      email_not_sent: "Non ho ricevuto l'email",
      email_not_sent_message:
        "Se non ricevi l'email immediatamente, attendi un po' di tempo prima di fare una nuova richiesta, poiché c'è un limite al numero di richieste consentite",
    },
  },
};

export default passwordRecovery;
