// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { useState } from "react";
import MDInput from "components/MDInput";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";

function Autocomplete({ onSelect, list, label, defaultValue = "" }) {
  const [inputValue, setInputValue] = useState(defaultValue || "");
  const [listToShow, setListToShow] = useState([]);
  const [indexSelected, setIndexSelected] = useState(0);
  return (
    <div className="autocomplete-container">
      <MDInput
        type="input"
        label={label}
        fullWidth
        value={inputValue}
        onChange={(e) => {
          setInputValue(e.target.value);
          if (e.target.value) {
            const newList = []
              .concat(list)
              .filter(el => !!el)
              .filter((el) =>
                el.label
                  ? el.label.toLowerCase().includes(e.target.value.toLowerCase())
                  : el.toLowerCase().includes(e.target.value.toLowerCase())
              );
            // newList.filter((el) => el.toLowerCase().includes(e.target.value.toLowerCase()));
            setListToShow(newList);
          } else {
            setListToShow([]);
            onSelect("");
          }
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            if (indexSelected >= 0 && listToShow[indexSelected]) {
              // onSelect(listToShow[indexSelected]);
              if (listToShow[indexSelected].value) {
                onSelect(listToShow[indexSelected].value);
              } else {
                onSelect(listToShow[indexSelected]);
              }
              setInputValue(
                listToShow[indexSelected].label
                  ? listToShow[indexSelected].label
                  : listToShow[indexSelected]
              );
              setListToShow([]);
            }
          } else if (e.key === "ArrowDown") {
            if (indexSelected + 1 < listToShow.length) {
              setIndexSelected(indexSelected + 1);
            } else {
              setIndexSelected(0);
            }
          } else if (e.key === "ArrowUp") {
            if (indexSelected > 0) {
              setIndexSelected(indexSelected - 1);
            } else if (listToShow.length > 0) {
              setIndexSelected(listToShow.length - 1);
            } else {
              setIndexSelected(0);
            }
          } else if (e.key === "Escape") {
            setListToShow([]);
            onSelect("");
            setInputValue("");
          }
        }}
      />
      {(listToShow && listToShow.length > 0) || inputValue ? (
        <IconButton
          onClick={() => {
            setListToShow([]);
            onSelect("");
            setInputValue("");
          }}
          style={{ position: "absolute", right: "0", top: "5px" }}
          size="small"
          disableRipple
        >
          <Icon fontSize="medium">close</Icon>
        </IconButton>
      ) : null}
      {listToShow && listToShow.length > 0 ? (
        <div className="autocomplete-list-container">
          {listToShow.map((option, index) => (
            <button
              className={`autocomplete-list-option ${
                indexSelected === index ? "option-selected" : ""
              }`}
              type="button"
              onClick={() => {
                if (option.value) {
                  onSelect(option.value);
                  setInputValue(option.label);
                } else {
                  onSelect(option);
                  setInputValue(option);
                }
                setListToShow([]);
              }}
              key={`autocomplete-list-${label}-${index.toString()}`}
            >
              {option.label ? option.label : option}
            </button>
          ))}
        </div>
      ) : null}
    </div>
  );
}

// Typechecking props for the Autocomplete
Autocomplete.propTypes = {
  onSelect: PropTypes.func.isRequired,
  list: PropTypes.arrayOf(PropTypes.any).isRequired,
  label: PropTypes.string,
};

Autocomplete.defaultProps = {
  label: "",
};

export default Autocomplete;
