
/**
 * Returns if an email address is valid or not.
 * @param {String} string
 * @returns {Boolean} validation
 */
function validateEmail(email) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

function validateUrlOrEmail(email) {
  return String(email)
    .match(
      /^([a-z0-9.@#$^!?:\/\-]+)$/
    );
};

function validateCC(number) {
  return number.length <= 6 && String(number).match(/^([0-9]+)$/);
};

export { validateEmail, validateUrlOrEmail, validateCC }
