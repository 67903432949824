const user = {
    en: {
      translation: {
        hello: "Hello",
        email: "Email",
        no_clients: "There are no customers linked to your user",
        you_have: "You have",
        customers: "customers",
        customer: "Customer",
        change_password: "Change password",
        vat_id: "Vat ID",
        service: "Service",
        credit_cards: "Credit cards monitored",
        combolists: "Combolists monitored",
        emails_linked: "Emails to send notifications",
        cookies: "Cookies monitored",
        cookies_client: "Cookies clients monitored",
        domains_to_monitor_passwords: "Domains to monitor passwords",
        domains_to_monitor_passwords_url: "Domains and url to monitor passwords",
        domains_to_monitor_passwords_apps: "Apps domains to monitor passwords",
        usernames_to_monitor: "Usernames to look for",
        ransomware_to_monitor: "Ransomwares to look for",
        password: "Password",
        confirm_password: "Confirm password",
        old_password: "Old password",
        change_password_success_text: "Your request of changing password was succesfully",
        change_password_error_text: "It seems like your old password is not correct, please, try again",
        add_credential: "Add credential",
        add_credential_explanation: "Add one of your email to monitor if there are results in the combolists. You can monitor a limited number of credentials, depending on your profile",
        new_credential: "New credential",
        wrong_email: "Please, add a correct email",
        add_credential_success_title: "Request added",
        add_credential_success_text: "Good job, we sent a message to the address you entered, confirm it and we will monitor your email, we will need some days to process potential previous compromises",
        email_already_used: "It seems like the email you entered is already under control, if it is not linked to your account, please contact our support",
        change_password_empty_field: "This field cannot be left empty",
        change_password_password_mismatch: "Please enter the same password",
      },
    },
    it: {
      translation: {
        hello: "Ciao",
        email: "Email",
        no_clients: "Non ci sono ancora clienti associati al tuo utente",
        you_have: "Hai",
        customers: "clienti",
        customer: "Cliente",
        change_password: "Cambia password",
        vat_id: "Partita IVA",
        service: "Servizio",
        credit_cards: "Carte di credito monitorate",
        combolists: "Combolists monitorate",
        emails_linked: "Email per le notifiche",
        cookies: "Cookies monitorati",
        cookies_client: "Cookies con clienti monitorati",
        domains_to_monitor_passwords: "Domini per monitorare le password",
        domains_to_monitor_passwords_url: "Domini con url per monitorare le password",
        domains_to_monitor_passwords_apps: "Domini delle app per monitorare le password",
        usernames_to_monitor: "Username da cercare",
        ransomware_to_monitor: "Ransomware da monitorare",
        password: "Password",
        confirm_password: "Conferma password",
        old_password: "Vecchia password",
        change_password_success_text: "La tua richiesta di cambio password è andata a buon fine",
        change_password_error_text: "Sembra che la tua vecchia password non sia corretta, per favore, riprova",
        add_credential: "Aggiungi credenziale",
        add_credential_explanation: "Aggiungi una delle tue email da monitorare nelle combolists. Puoi monitorare un numero limitato di credenziali, in base al tuo profilo",
        new_credential: "Nuova credenziale",
        wrong_email: "Per favore, aggiungi un indirizzo email corretto",
        add_credential_success_title: "Richiesta aggiunta",
        add_credential_success_text: "Complimenti, abbiamo mandato un messaggio all'indirizzo che hai inserito, segui le istruzioni del messaggio e inizieremo a monitorare l'indirizzo email che hai appena aggiunto, ci servirà qualche giorno per trovare eventuali compromissioni passate.",
        email_already_used: "It seems like the email you entered is already under control, if it is not linked to your account, please contact our support",
        change_password_empty_field: "Questo campo non può essere vuoto",
        change_password_password_mismatch: "Per favore inserisci la stessa password",
      },
    },
  };
  
  export default user;
  