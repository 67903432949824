import React, { useEffect, useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";

// External components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Translation
import { useTranslation } from "react-i18next";

// APIs
import getDarkWebThreats from "api/darkweb/getDarkWebThreats";

const DarkWebMonitoring = () => {
  const { t } = useTranslation();
  const [data, setData] = useState(null);

  const getData = async () => {
    try {
      const { results } = await getDarkWebThreats();
      setData(results);
    } catch (e) {
      console.log("error --->", e);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* Title */}
      <h2 className="page-title">Dark web Monitoring</h2>

      {/* Table */}
      <div className="table_users_list">
        <table className="table-content" cellSpacing="0" cellPadding="0">
          <thead>
            <tr>
              <th style={{ textAlign: "center" }}>{t("darkWeb.date")}</th>
              <th style={{ textAlign: "center" }}>{t("darkWeb.name")}</th>
              <th style={{ textAlign: "center" }}>{t("darkWeb.description")}</th>
              <th style={{ textAlign: "center" }}>{t("darkWeb.victim_nation")}</th>
              <th style={{ textAlign: "center" }}>{t("darkWeb.attack_type")}</th>
              <th style={{ textAlign: "center" }}>{t("darkWeb.details")}</th>
            </tr>
          </thead>
          <tbody>
            {data && data.length > 0
              ? data.map((threat) => (
                  <tr key={threat.id}>
                    <td>{moment(threat.date).format("DD/MM/YYYY")}</td>
                    <td>{threat.threatActor.name}</td>
                    <td style={{ maxWidth: "300px " }}>{threat.shortDescription}</td>
                    <td>{threat.victim?.country || "N/A"}</td>
                    <td>{threat.attackType}</td>
                    <td>
                      <Link to={`/darkweb/threat/${threat.id}`}>{t("darkWeb.details")}</Link>
                    </td>
                  </tr>
                ))
              : null}
          </tbody>
        </table>
      </div>
    </DashboardLayout>
  );
};

export default DarkWebMonitoring;
