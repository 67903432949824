import config from "config/config";
import getTokens from "api/getTokens";

const  { baseUrl, clientUrl } = config;

/* *********************

********************** */

export default function logout(refreshToken, user) {
  const endpoint = `${baseUrl}/v1/auth/logout`;

  let refresh = refreshToken ? refreshToken.token : "";
  if (!refresh) {
    const tokens = getTokens();
    if (tokens && tokens.refresh && tokens.refresh.token) {
      refresh = tokens.refresh.token;
    }
  }
  const options = {
    method: "POST",
    mode: "cors",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `${clientUrl || ""}`,
      "Access-Control-Allow-Credentials": true,
    },
    body: JSON.stringify({
      refreshToken: refresh,
      user,
    }),
  };
  return (
    fetch(endpoint, options)
      .then((response) => {
        if ((response && response.status === 200) || (response && response.status === 204)) {
          localStorage.removeItem("refresh");
          localStorage.removeItem("access");
          localStorage.removeItem("userid");
          localStorage.removeItem("mfa");
          return true;
        }
        return false;
      })
      .catch((e) => {
        if (e.message === "Network request failed") {
          // updateNetworkFailed(true);
        }
      })
  );
}
