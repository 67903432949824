const errors = {
  en: {
    translation: {
      something_goes_wrong: "Ops, something went wrong!",
      check_input: "Check the data you wrote or try again in a moment",
      passwords_not_matching: "Passwords are not matching",
      too_many_request: "Too many request!",
      try_later: "Please, wait some minutes and then try again",
    },
  },

  it: {
    translation: {
      something_goes_wrong: "Ops, qualcosa è andato storto!",
      check_input: "Per favore, controlla i dati inseriti e riprova",
      passwords_not_matching: "Le passwords non coincidono",
      too_many_request: "Troppe richieste!",
      try_later: "Per favore, aspetta qualche minuto e riprova più tardi",
    },
  },
};

export default errors;
