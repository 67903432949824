const configuration = {
  development: {
    baseUrl: "http://localhost:3008",
    clientUrl: "http://localhost:8080",
  },
  staging: {
    baseUrl: "https://api.srv01.defenda.dev",
    clientUrl: "https://frontend-dev.srv01.defenda.dev",
  },
  production: {
    baseUrl: "https://api.defendasolutions.it",
    clientUrl: "https://dash.defendasolutions.it",
  },
  demo: {
    baseUrl: "https://demo-api.defenda.dev",
    clientUrl: "https://demo.defenda.dev",
  },
};

export default configuration;

