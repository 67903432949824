const login = {
  en: {
    translation: {
      signIn: "Sign in",
      forgotPassword: "Forgot password?",
    },
  },
  it: {
    translation: {
      signIn: "Accedi",
      forgotPassword: "Password dimenticata?",
    },
  },
};

export default login;
