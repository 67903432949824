/* eslint no-unused-vars: ["warn"] */
import i18next from "i18next";

import resources from "./resources";

// eslint-disable-next-line
i18next.init(resources, (err, t) => {
  // initialized and ready to go!
});

export default i18next;
