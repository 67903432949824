const statistics = {
  en: {
    translation: {
      attacks: "Attacks",
      monday_short: "M",
      tuesday_short: "T",
      wednesday_short: "W",
      thursday_short: "T",
      friday_short: "F",
      saturday_short: "S",
      sunday_short: "S",
      january_short: "J",
      february_short: "F",
      march_short: "M",
      april_short: "A",
      may_short: "M",
      june_short: "J",
      july_short: "J",
      august_short: "A",
      september_short: "S",
      october_short: "O",
      november_short: "N",
      december_short: "D",
      last_import: "Last data import:",
      stealers: "Stealers",
      stealer: "Stealer",
      ransomware: "Ransomware",
      attacks_per_hour: "Attacks per hour",
      attacks_per_day: "Attacks per day",
      attacks_per_month: "Attacks per month",
      attacks_per_country: "Attacks per country",
      legenda: "Legenda",
      threats: "Threats",
      operative_systems: "Operative systems",
      countries: "Countries",
      country: "Country",
      start_statistics: "Start statistics date",
      end_statistics: "End statistics date",
      stealers_complete_incomplete: "Stealers complete/incomplete",
      completes: "Completes",
      incompletes: "Incompletes",
      countries_details: "Countries details",
      always: "Always",
      last_year: "Last year",
      2022: "2022",
      2021: "2021",
      2020: "2020",
      custom: "Custom",
    },
  },
  it: {
    translation: {
      attacks: "Attacchi",
      monday_short: "L",
      tuesday_short: "M",
      wednesday_short: "M",
      thursday_short: "G",
      friday_short: "V",
      saturday_short: "S",
      sunday_short: "D",
      january_short: "G",
      february_short: "F",
      march_short: "M",
      april_short: "A",
      may_short: "M",
      june_short: "G",
      july_short: "L",
      august_short: "A",
      september_short: "S",
      october_short: "O",
      november_short: "N",
      december_short: "D",
      last_import: "Ultima importazione dati:",
      stealers: "Stealers",
      stealer: "Stealer",
      ransomware: "Ransomware",
      attacks_per_hour: "Attacchi per ora",
      attacks_per_day: "Attacchi per giorno",
      attacks_per_month: "Attacchi per mese",
      attacks_per_country: "Attacchi per paese",
      legenda: "Legenda",
      threats: "Minacce",
      operative_systems: "Sistemi operativi",
      countries: "Paesi",
      country: "Paese",
      start_statistics: "Data inizio statistiche",
      end_statistics: "Data fine statistiche",
      stealers_complete_incomplete: "Stealers completi/incompleti",
      completes: "Completi",
      incompletes: "Incompleti",
      countries_details: "Dettaglio paesi",
      always: "Sempre",
      last_year: "Scorso anno",
      2022: "2022",
      2021: "2021",
      2020: "2020",
      custom: "Personalizza",
    },
  },
};

export default statistics;
