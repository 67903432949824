import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import SweetAlert from "react-bootstrap-sweetalert";
import PropTypes from "prop-types";

function YesNoPopup({title, text, noCallback, yesCallback}) {
  const { t } = useTranslation();

  useEffect(() => {

  }, []);

  return (
    <SweetAlert
      style={{ display: "block", position: "relative", textAlign: "left" }}
      title={title}
      onConfirm={yesCallback}
      onCancel={noCallback}
      confirmBtnText={t("stealers.yes")}
      confirmBtnCssClass="popupCloseButton"
      confirmBtnStyle={{ boxShadow: "none" }}
      cancelBtnText={t("stealers.no")}
      cancelBtnCssClass="popupCloseButton"
      cancelBtnStyle={{ boxShadow: "none" }}
      showCancel={true}
      closeOnClickOutside={false}
    >
      {text}
    </SweetAlert>
  );
}

// Typechecking props for the MDTypography
YesNoPopup.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default YesNoPopup;

