// @mui material components
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import getMessages from "api/messages/getMessages";
import getMessagesTypes from "api/messages/getMessagesTypes";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import SweetAlert from "react-bootstrap-sweetalert";
// import MDInput from "components/MDInput";
import moment from "moment";
// eslint-disable-next-line
import Tooltip from "@mui/material/Tooltip";
import Datetime from "react-datetime";
import { useMaterialUIController } from "context";

// Material Dashboard 2 PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Pagination from "layouts/dashboards/stealers/pagination";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ServerError from "utilities/ServerError";

function Messages() {
  const [messages, setMessages] = useState([]);
  // eslint-disable-next-line
  const [pages, setPages] = useState(0);
  // eslint-disable-next-line
  const [page, setPage] = useState(1);
  const [type, setType] = useState("");
  const [status, setStatus] = useState("error");
  const [messageTypes, setMessageTypes] = useState("");
  const [messageDetail, setMessageDetail] = useState(null);
  const [limit, setLimit] = useState(10);
  const [showFilters, setShowFilters] = useState(false);
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [isRequestReady, setIsRequestReady] = useState(false);
  const [orderBy, setOrderBy] = useState("dateReverse");
  const [serverError, setServerError] = useState({
    stealersGrid: false,
    stealersTable: false,
  });

  // eslint-disable-next-line
  const [controller, dispatch] = useMaterialUIController();
  const { t } = useTranslation();

  function setRequest(res) {
    if (res.results && res.results.length > 0) {
      setMessages(res.results);
      setIsRequestReady(true);
      if (serverError && (serverError.stealersGrid || serverError.stealersTable)) {
        setServerError({
          stealersGrid: false,
          stealersTable: false,
        });
      }
    }
    if (res && res.length > 0) {
      setMessages(res);
      setIsRequestReady(true);
      if (serverError && (serverError.stealersGrid || serverError.stealersTable)) {
        setServerError({
          stealersGrid: false,
          stealersTable: false,
        });
      }
    } else if (res.length === 0 || (res.results && res.results.length === 0)) {
      setMessages([]);
      setIsRequestReady(true);
    }
  }

  function refreshAttacks() {
    setIsRequestReady(false);
    getMessages(page, limit, type, status, dateFrom, dateTo, orderBy)
      .then((res) => {
        setPages(res.totalPages);

        setRequest(res);
      })
      .catch((err) => {
        // eslint-disable-next-line
        console.log("error", err);
        setServerError({ ...serverError, stealersTable: true });
      });
  }

  useEffect(() => {
    getMessagesTypes().then((typesOptions) => {
      if (typesOptions && typesOptions.length > 0) {
        setMessageTypes([""].concat(typesOptions));
      }
    });
  }, []);

  useEffect(() => {
    refreshAttacks();
  }, [limit, page, type, status, dateFrom, dateTo, orderBy]);

  function getCalendar(value, changeFunction, label) {
    return (
      <div style={{ position: "relative", marginRight: "10px" }}>
        <Datetime
          inputProps={{
            placeholder: label,
            value,
          }}
          closeOnSelect
          timeFormat={false}
          value={value}
          onChange={(e) => {
            changeFunction(moment(e).format("L"));
          }}
        />
        {value ? (
          <IconButton
            onClick={() => {
              changeFunction("");
            }}
            style={{ position: "absolute", right: "0", top: "6px" }}
            size="small"
            disableRipple
          >
            <Icon fontSize="medium">close</Icon>
          </IconButton>
        ) : null}
      </div>
    );
  }

  function getSelect(options, editFunction, defaultValue) {
    return (
      <select
        onChange={(e) => {
          editFunction(e.target.value);
        }}
        defaultValue={defaultValue}
        className="selectDefault"
        style={{
          width: "100%",
          padding: "0.75rem",
          borderRadius: "0.375rem",
          borderColor: "#d2d6da",
          marginBottom: "30px",
          display: "relative",
        }}
      >
        {options.map((el, index) => (
          <option value={el} key={`option-role-${index.toString()}`}>
            {el || t("messages.all")}
          </option>
        ))}
      </select>
    );
  }

  function getStatus(rowStatus) {
    switch (rowStatus) {
      case "error":
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Icon style={{ color: "red", marginRight: "10px" }} fontSize="medium">
              error
            </Icon>
            {rowStatus}
          </div>
        );
      case "pending":
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Icon style={{ color: "yellow", marginRight: "10px" }} fontSize="medium">
              pending
            </Icon>
            {rowStatus}
          </div>
        );
      case "fulfilled":
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Icon style={{ color: "green", marginRight: "10px" }} fontSize="medium">
              done
            </Icon>
            {rowStatus}
          </div>
        );
      default:
        return rowStatus;
    }
  }

  function getDetailsContent() {
    let color = "#344767";
    switch (messageDetail.status) {
      case "error":
        color = "red";
        break;
      case "fulfilled":
        color = "green";
        break;
      case "pending":
        color = "yellow";
        break;
      default:
        color = "#344767";
    }
    return (
      <div>
        {messageDetail.lastAttempt ? (
          <div>
            {t("messages.date")}:{" "}
            <b>
              {moment(messageDetail.lastAttempt).format("DD")}/
              {moment(messageDetail.lastAttempt).format("MM")}/
              {moment(messageDetail.lastAttempt).format("YYYY")} -{" "}
              {moment(messageDetail.lastAttempt).format("HH")}:
              {moment(messageDetail.lastAttempt).format("mm")}:
              {moment(messageDetail.lastAttempt).format("ss")}
            </b>
          </div>
        ) : null}
        {messageDetail.status ? (
          <div>
            {t("messages.status")}: <b style={{ color }}>{messageDetail.status}</b>
          </div>
        ) : null}
        {messageDetail.type ? (
          <div>
            {t("messages.type")}: <b>{messageDetail.type}</b>
          </div>
        ) : null}
        {messageDetail.errorsNumber ? (
          <div>
            {t("messages.errors_number")}: <b>{messageDetail.errorsNumber}</b>
          </div>
        ) : null}
        {messageDetail.errorMessage ? (
          <div>
            {t("messages.error_message")}: <b>{messageDetail.errorMessage}</b>
          </div>
        ) : null}
        {messageDetail.to ? (
          <div>
            {t("messages.to")}: <b>{messageDetail.to}</b>
          </div>
        ) : null}
        {messageDetail.payload && messageDetail.payload.subject ? (
          <div>
            {t("messages.subject")}: <b>{messageDetail.payload.subject}</b>
          </div>
        ) : null}
        {messageDetail.payload && messageDetail.payload.message ? (
          <div>
            {t("messages.message")}: <b>{messageDetail.payload.message}</b>
          </div>
        ) : null}
        {messageDetail.payload && messageDetail.payload.text ? (
          <div>
            {t("messages.text")}: <b>{messageDetail.payload.text}</b>
          </div>
        ) : null}
        {messageDetail.payload && messageDetail.payload.url ? (
          <div>
            {t("messages.url")}: <b>{messageDetail.payload.url}</b>
          </div>
        ) : null}
        {messageDetail.payload && messageDetail.payload.contentType ? (
          <div>
            {t("messages.content_type")}: <b>{messageDetail.payload.contentType}</b>
          </div>
        ) : null}
      </div>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {messageDetail && messageDetail.id ? (
        <SweetAlert
          style={{ display: "block" }}
          title={t("messages.message_details")}
          onConfirm={() => {
            setMessageDetail(null);
          }}
          confirmBtnText="Ok"
          // confirmBtnCssClass={classes.button + " " + classes.success}
          confirmBtnCssClass="popupCloseButton"
          confirmBtnStyle={{
            boxShadow: "none",
          }}
        >
          {getDetailsContent()}
        </SweetAlert>
      ) : null}
      <h2 className="page-title">{t("stealers.stolen_credentials")}</h2>
      <div style={{ margin: "30px 0", width: "100%", textAlign: "left" }}>
        <IconButton
          onClick={() => {
            setShowFilters(!showFilters);
          }}
          style={{ marginRight: "15px" }}
          className="default-button"
          size="small"
          disableRipple
        >
          {t("filter")}{" "}
          {showFilters ? (
            <ArrowDropUpIcon style={{ marginLeft: "10px" }} fontSize="medium" />
          ) : (
            <ArrowDropDownIcon style={{ marginLeft: "10px" }} fontSize="medium" />
          )}
        </IconButton>
        <IconButton
          onClick={() => {
            refreshAttacks();
          }}
          className="default-button"
          style={{ marginRight: "15px" }}
          size="small"
          disableRipple
        >
          {t("refresh")}{" "}
          <Icon style={{ marginLeft: "10px" }} fontSize="medium">
            replay
          </Icon>
        </IconButton>
      </div>
      {!serverError.stealersTable ? (
        <>
          {showFilters ? (
            <div style={{ marginBottom: "30px" }}>
              <div>
                <div className="label-filters" style={{ marginBottom: "10px" }}>
                  {t("search")}:
                </div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div style={{ display: "flex", maxWidth: "600px" }}>
                    {getCalendar(dateFrom, setDateFrom, t("stealers.date_from"))}
                    {getCalendar(dateTo, setDateTo, t("stealers.date_to"))}
                    {getSelect(["", "error", "pending", "fulfilled"], setStatus, status)}
                    {messageTypes && messageTypes.length > 0
                      ? getSelect(messageTypes, setType, type)
                      : null}
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      style={{
                        lineHeight: "16px",
                        marginRight: "15px",
                        fontSize: "0.8em",
                        marginLeft: "10px",
                      }}
                    >
                      {t("stealers.elements_displayed")}
                    </div>
                    <select
                      onChange={(e) => {
                        setLimit(e.target.value);
                      }}
                      value={limit}
                      className="selectDefault"
                      style={{
                        padding: "0.75rem",
                        borderRadius: "0.375rem",
                        borderColor: "#d2d6da",
                      }}
                    >
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          <div className="popupInfoContainer">
            <div>
              <div className="table_users_list">
                <table className="table-content" cellSpacing="0" cellPadding="0">
                  <thead>
                    <tr>
                      <th
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          if (orderBy === "dateReverse") {
                            setOrderBy("date");
                          } else {
                            setOrderBy("dateReverse");
                          }
                        }}
                      >
                        {orderBy === "date" ? (
                          <ArrowDropDownIcon
                            style={{ marginBottom: "-7px", marginRight: "15px" }}
                            fontSize="medium"
                          />
                        ) : null}
                        {orderBy === "dateReverse" ? (
                          <ArrowDropUpIcon
                            style={{ marginBottom: "-7px", marginRight: "15px" }}
                            fontSize="medium"
                          />
                        ) : null}
                        {t("messages.date")}
                      </th>
                      <th>{t("messages.info")}</th>
                      <th>{t("messages.type")}</th>
                      <th>{t("messages.status")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isRequestReady ? (
                      <>
                        {messages && messages.length > 0 ? (
                          <>
                            {messages.map((el, index) => (
                              <tr key={`message-${index.toString()}-${el.id.toString()}`}>
                                <td>
                                  {el.lastAttempt
                                    ? `${moment(el.lastAttempt).format("DD")}/${moment(
                                        el.lastAttempt
                                      ).format("MM")}/${moment(el.lastAttempt).format("YYYY")}`
                                    : ""}
                                </td>
                                <td>
                                  <IconButton
                                    onClick={() => {
                                      setMessageDetail(el);
                                    }}
                                    size="small"
                                    disableRipple
                                  >
                                    <Icon fontSize="medium">info</Icon>
                                  </IconButton>
                                </td>
                                <td>{el.type || ""}</td>
                                <td>{el.status ? getStatus(el.status) : ""}</td>
                              </tr>
                            ))}
                          </>
                        ) : (
                          <tr className="table-tr-no-results">
                            <td />
                            <td />
                            <td className="table-no-results-box">{t("messages.no_message_yet")}</td>
                            <td />
                          </tr>
                        )}
                      </>
                    ) : (
                      <tr>
                        <td />
                        <td />
                        <td
                          style={{
                            width: "100%",
                            display: "block",
                            padding: "10px 0",
                            position: "relative",
                          }}
                        >
                          <div className="loader" />
                        </td>
                        <td />
                        <td />
                      </tr>
                    )}
                  </tbody>
                </table>
                {pages && pages > 1 ? (
                  <Pagination setPage={setPage} pages={pages} active={page} />
                ) : null}
              </div>
            </div>
          </div>
        </>
      ) : (
        <ServerError />
      )}
    </DashboardLayout>
  );
}

export default Messages;
