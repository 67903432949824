import config from "config/config";

const { baseUrl, clientUrl } = config;

/* *********************
  email
  password
********************** */

export default function login(BODY) {
  const endpoint = `${baseUrl}/v1/auth/login`;
  // console.log("devo chiamare questo", endpoint);
  const options = {
    method: "POST",
    mode: "cors",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `${clientUrl || ""}`,
      "Access-Control-Allow-Credentials": true,
    },
    body: JSON.stringify(BODY),
  };
  // console.log('login endpoint', {endpoint, BODY });
  return fetch(endpoint, options)
    .then((response) => {
      if (response.status === 429) {
        return { status: 429, message: "Too many requests" };
      }

      if (response.status !== 200 && response.status !== 201 && response.status !== 204) {
        throw new Error();
      }
      return response.json();
    })
    .then((responseJson) => {
      // eslint-disable-next-line
      if (responseJson.status === 429) {
        return responseJson;
      }

      // console.log("login responseJson", responseJson);
      if (responseJson && responseJson.tokens && responseJson.tokens.access) {
        localStorage.setItem("refresh", JSON.stringify(responseJson.tokens.refresh));
        localStorage.setItem("access", JSON.stringify(responseJson.tokens.access));
        localStorage.setItem("userid", JSON.stringify(responseJson.user.id));

        return responseJson;
      }
      return responseJson;
    })
    .catch((e) => {
      if (e.message === "Network request failed") {
        // updateNetworkFailed(true);
      }
      if (e.status === 422) {
        // user not verified
        // console.log('User not verified');
        const result = {
          status: "user_not_verified",
          success: false,
        };
        throw new Error(JSON.stringify(result));
      } else {
        // console.log(`Error occurred while signing in: ${JSON.stringify(e)}`);
        const result = {
          status: "wrong_username_and_password",
          success: false,
        };
        throw new Error(JSON.stringify(result));
      }
    });
}
