function getType(payload) {
  if (payload) {
    if (payload.isCombolist) {
      return "phising";
    }
    return "malware";
  }
  return "";
}

export default getType;
