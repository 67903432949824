import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React from "react";
import PropTypes from "prop-types";

const ServerError = ({ message }) => (
  <MDBox bgColor="primary" borderRadius="md" py={1} px={2} sx={{ textAlign: "center" }}>
    <MDTypography color="white">A server error occurred!</MDTypography>
    {message ? (
      <MDTypography color="white" fontSize="small">
        {message}
      </MDTypography>
    ) : null}
  </MDBox>
);

ServerError.propTypes = {
  message: PropTypes.string,
};

ServerError.defaultProps = {
  message: "",
};

export default ServerError;
