import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import SweetAlert from "react-bootstrap-sweetalert";

function TokenExpiredPopup() {
  const [tokenExpired, setTokenExpired] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const timer = setTimeout(() => setTokenExpired(true), 3300000);
    return () => clearTimeout(timer);
  }, []);

  if (tokenExpired) {
    return (
      <SweetAlert
        style={{ display: "block", position: "relative", textAlign: "left" }}
        title={t("token_expired")}
        onConfirm={() => {
          window.location.reload();
        }}
        confirmBtnText={t("refresh")}
        confirmBtnCssClass="popupCloseButton"
        confirmBtnStyle={{
          boxShadow: "none",
        }}
      >
        {t("token_expired_text")}
      </SweetAlert>
    );
  }
  return null;
}

export default TokenExpiredPopup;
