// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import print from "api/print/print";
import getRequestsByStealerAndUser from "api/reportsrequests/getRequestsByStealerAndUser";
import createRequest from "api/reportsrequests/createRequest";
import SweetAlert from "react-bootstrap-sweetalert";

// Import images
import Report from "assets/images/credentials-details-icons/report.png";
import YesNoPopup from "components/YesNoPopup";

// eslint-disable-next-line
function RequestReport({ stealer, user, clients, role }) {
  // eslint-disable-next-line
  const [request, setRequest] = useState(null);
  const { t } = useTranslation();
  const [instantReport, setInstantReport] = useState(false);

  useEffect(() => {
    if (stealer && user) {
      getRequestsByStealerAndUser(stealer, user)
        .then((res) => {
          if (res && res.id) {
            setRequest(res);
          }
          // console.log("res", res);
          // if (res && res.length > 0) {
          //   const itemFound = res.find((resIn) => resIn.user === user);
          //   if (itemFound) {
          //     setRequest(itemFound);
          //   }
          // }
        })
        .catch((err) => {
          // eslint-disable-next-line
          console.log("error while getting report request", err);
        });
    }
  }, []);

  function requestReport(instantPrint = false) {
    const bodyToSend = {
      date: new Date(),
      status: "pending",
      dateLastUpdate: new Date(),
      user,
      stealer,
      clients,
      history: [],
      instantPrint: instantPrint,
    };

    createRequest(bodyToSend)
      .then((res) => {
        setRequest(res);
        // console.log("res creataaaaaaaaa", res);
      })
      .catch((err) => {
        // eslint-disable-next-line
        console.log("error while getting report request", err);
      });
  }

  return (
    <div className="boxes-border-radius request-report-container">
      <img src={Report} alt="report-img" className="report-icon" />
      {request ? (
        <div style={{ width: "100%" }}>
          {request.status === "pending" ? (
            <div className="request-report-text-container">
              <div className="request-report-title">{t("stealers.already_requested_pending")}</div>
              <div className="request-report-text">
                {t("stealers.already_requested_pending_text")}
              </div>
            </div>
          ) : (
            <div className="request-report-text-button-container">
              <div className="request-report-text-container">
                <div className="request-report-title">{t("stealers.report_ready")}</div>
                <div className="request-report-text">{t("stealers.report_ready_subtitle")}</div>
              </div>
              <div className="request-report-button-container">
                <button
                  type="button"
                  className="request-report-button"
                  onClick={() => {
                    // eslint-disable-next-line
                    print(request.pathS3.replaceAll("/", "_"), request.indexKey)
                      .then((res) => res.blob())
                      .then((blob) => {
                        const fileURL = URL.createObjectURL(blob);
                        // Open the URL on new Window
                        const pdfWindow = window.open();
                        pdfWindow.location.href = fileURL;
                        // IF WE WANT TO DOWNLOAD THE FILE INSTEAD OF OPENING IT IN NEW TAB
                        // const url = window.URL.createObjectURL(blob);
                        // const a = document.createElement("a");
                        // a.style.display = "none";
                        // a.href = url;
                        // // the filename you want
                        // a.download = "report-pdf";
                        // document.body.appendChild(a);
                        // a.click();
                        // document.body.removeChild(a);
                        // window.URL.revokeObjectURL(url);
                      })
                      .catch((err) => {
                        // eslint-disable-next-line
                        console.log("errore frontend print", err);
                      });
                  }}
                >
                  {t("stealers.open")}
                </button>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="request-report-text-button-container">
          <div className="request-report-text-container">
            <div className="request-report-title">{t("stealers.request_details")}</div>
            <div className="request-report-text">{t("stealers.request_details_text")}</div>
          </div>
          <div className="request-report-button-container">
            <button
              type="button"
              className="request-report-button"
              onClick={() => {
                
                if (role === "admin") {
                  setInstantReport(true);
                } else {
                  requestReport(false);
                }
              }}
            >
              {t("stealers.request")}
            </button>
          </div>
        </div>
      )}
      {instantReport ? (
        <YesNoPopup 
          title={t("stealers.instant_report")}
          text={t("stealers.instant_report_confirm")}
          noCallback={() => {
            requestReport(false);
            setInstantReport(false);
          }}
          yesCallback={() => {
            requestReport(true);
            setInstantReport(false);
          }}
        />
      ) : null}
    </div>
  );
}

// Typechecking props for the RequestReport
RequestReport.propTypes = {
  stealer: PropTypes.string.isRequired,
  user: PropTypes.string.isRequired,
};

export default RequestReport;
