// @mui material components
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import getRansomwares from "api/ransomware/getAllRansomwares";
import getAllSectors from "api/ransomware/getAllSectors";
import getAllGangs from "api/ransomware/getAllGangs";
import addRansomware from "api/ransomware/addRansomware";
import uploadFile from "api/ransomware/uploadRansomwareImage";
// import getRansomwaresBySites from "api/ransomware/getRansomwaresBySites";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Grid from "@mui/material/Grid";
import moment from "moment";
import Tooltip from "@mui/material/Tooltip";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import SweetAlert from "react-bootstrap-sweetalert";
import Datetime from "react-datetime";
import { useMaterialUIController } from "context";
import Pagination from "layouts/dashboards/stealers/pagination";
import Autocomplete from "layouts/dashboards/stealers/autocomplete";
// import UploadRansomwareImage from "layouts/dashboards/ransomwares/uploadImage";
import EditRansomwareComponent from "layouts/dashboards/ransomwares/editRansomware";
import StatisticsCounter from "components/StatisticsCounter";
// import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ImageIcon from "@mui/icons-material/Image";
import EditIcon from "@mui/icons-material/Edit";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import countLastYearRansomware from "api/statistics/countLastYearRansomware";
import countLastMonthRansomware from "api/statistics/countLastMonthRansomware";

// Material Dashboard 2 PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import countryCode from "config/countryCode";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import iconVictims from "assets/images/ransomware/icon_victims.png";
import iconCalendar from "assets/images/ransomware/icon_calendar.png";
import iconCountry from "assets/images/ransomware/icon_country.png";

// Csv downloader
import CsvDownloader from "react-csv-downloader";

// Icons
import DownloadIcon from "@mui/icons-material/Download";
import ServerError from "utilities/ServerError";

function Ransomwares() {
  const [attacks, setAttacks] = useState([]);
  const [originalAttacks, setOriginalAttacks] = useState([]);
  const [popup, setPopup] = useState(false);
  const [pages, setPages] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [nation, setNation] = useState("");
  const [domain] = useState("");
  const [victim, setVictim] = useState("");
  const [allSectors, setAllSectors] = useState([]);
  const [dateFrom, setDateFrom] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  const [dateTo, setDateTo] = useState("");
  const [gang, setGang] = useState("");
  const [sector, setSector] = useState("");
  const [allGangs, setAllGangs] = useState([]);
  const [adding, setAdding] = useState(false);
  const [additionVictim, setAdditionVictim] = useState("");
  const [additionVictimUrl, setAdditionVictimUrl] = useState("");
  const [additionGang, setAdditionGang] = useState("");
  const [additionCountry, setAdditionCountry] = useState("");
  const [additionSector, setAdditionSector] = useState("");
  const [additionDate, setAdditionDate] = useState("");
  const [additionCompanySize, setAdditionCompanySize] = useState("");
  const [additionIsHidden, setAdditionIsHidden] = useState("");
  const [showHidden, setShowHidden] = useState(false);
  const [customFilters, setCustomFilters] = useState(false);
  const [viewElement, setViewElement] = useState(null);
  const [isRequestReady, setIsRequestReady] = useState(false);
  const [editing, setEditing] = useState(false);
  const [orderBy, setOrderBy] = useState("dateReverse");
  const [popupSuccess, setPopupSuccess] = useState(false);
  const [editingElement, setEditingElement] = useState(null);
  const [ransomwareCounter, setRansomwareCounter] = useState(0);
  const [ransomwareMonthCounter, setRansomwareMonthCounter] = useState(0);
  const [ransomwareCountryCounter, setRansomwareCountryCounter] = useState(0);
  const [image, setImage] = useState("");
  const [imageToView, setImageToView] = useState("");
  const [serverError, setServerError] = useState({
    ransomwaresCounters: false,
    ransomwaresTable: false,
  });
  // eslint-disable-next-line
  const [controller, dispatch] = useMaterialUIController();
  const { client, user } = controller;
  const { t } = useTranslation();

  function setRequest(res) {
    if (res.results && res.results.length > 0) {
      setOriginalAttacks(res.results);
      setAttacks(res.results);
      setIsRequestReady(true);
    }
    if (res && res.length > 0) {
      setOriginalAttacks(res);
      setAttacks(res);
      setIsRequestReady(true);
    } else if (res.length === 0 || (res.results && res.results.length === 0)) {
      setOriginalAttacks([]);
      setAttacks([]);
      setIsRequestReady(true);
    }
  }

  function refreshAttacks() {
    setIsRequestReady(false);
    // if (!client || client === "*") {
    getRansomwares(
      page,
      limit,
      nation,
      domain,
      victim,
      gang,
      sector,
      showHidden,
      dateFrom,
      dateTo,
      orderBy,
      customFilters
    )
      .then((res) => {
        setPages(res.totalPages);
        setRequest(res);
      })
      .catch((err) => {
        setServerError({ ...serverError, ransomwaresTable: true });
        // eslint-disable-next-line no-console
        console.log("error", err);
      });
  }

  useEffect(() => {
    refreshAttacks();
  }, [
    client,
    page,
    limit,
    domain,
    nation,
    victim,
    gang,
    sector,
    showHidden,
    dateFrom,
    dateTo,
    orderBy,
    customFilters,
  ]);

  useEffect(() => {
    getAllSectors()
      .then((res) => {
        setAllSectors(res);
      })
      // eslint-disable-next-line
      .catch((err) => console.log("error", err));
    getAllGangs()
      .then((res) => res.filter((el) => el))
      .then((gangs) => {
        setAllGangs(gangs);
      })
      // eslint-disable-next-line
      .catch((err) => console.log("error getting gangs", err));
    countLastYearRansomware()
      .then((res) => {
        setRansomwareCounter(String(res.counter).replace(/(.)(?=(\d{3})+$)/g, "$1."));
        setRansomwareCountryCounter(String(res.counterCountry).replace(/(.)(?=(\d{3})+$)/g, "$1."));
      })
      .catch((err) => {
        // eslint-disable-next-line
        console.log("error while counting ransomware", err);
        setServerError({ ...serverError, ransomwaresCounters: true });
      });
    countLastMonthRansomware()
      .then((res) => {
        setRansomwareMonthCounter(String(res.counter).replace(/(.)(?=(\d{3})+$)/g, "$1."));
      })
      .catch((err) => {
        // eslint-disable-next-line
        console.log("error while counting ransomware", err);
        setServerError({ ...serverError, ransomwaresCounters: true });
      });
  }, []);

  function getInput(label, value, operation) {
    setPage(1);
    return (
      <div style={{ marginBottom: "20px" }}>
        <MDInput
          type="input"
          label={label}
          fullWidth
          value={value}
          onChange={(e) => {
            operation(e.target.value);
          }}
        />
      </div>
    );
  }

  function getCalendar(value, changeFunction, label) {
    return (
      <div style={{ position: "relative", marginRight: "10px" }}>
        <Datetime
          inputProps={{
            placeholder: label,
            value,
          }}
          closeOnSelect
          timeFormat={false}
          value={value}
          onChange={(e) => {
            setPage(1);
            changeFunction(moment(e).format("L"));
          }}
        />
        {value ? (
          <IconButton
            onClick={() => {
              setPage(1);
              changeFunction("");
            }}
            style={{ position: "absolute", right: "0", top: "6px" }}
            size="small"
            disableRipple
          >
            <Icon fontSize="medium">close</Icon>
          </IconButton>
        ) : null}
      </div>
    );
  }

  function readFile(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = () => reject();
    });
  }

  function getAdding() {
    return (
      <SweetAlert
        style={{ display: "block", position: "relative", textAlign: "left", overflowY: "auto" }}
        title={t("add")}
        onConfirm={() => {
          const newObject = {
            victim: additionVictim || "",
            victim_url: additionVictimUrl || "",
            gang: additionGang || "",
            country: additionCountry || "",
            sector: additionSector || "",
            detection: additionDate || "",
            company_size: additionCompanySize || "",
            image: image || "",
            isHidden: additionIsHidden || false,
          };
          addRansomware(newObject)
            .then(() => {
              setAdditionVictim("");
              setAdditionVictimUrl("");
              setAdditionGang("");
              setAdditionCountry("");
              setAdditionSector("");
              setAdditionDate("");
              setAdditionCompanySize("");
              setAdditionIsHidden("");
              setPopupSuccess(true);
              setImage("");
              refreshAttacks();
              setAdding(false);
            })
            // eslint-disable-next-line
            .catch((err) => console.log("error", err));
        }}
        onCancel={() => {
          setAdding(false);
        }}
        closeOnClickOutside
        showCancel
        cancelBtnText={t("cancel")}
        confirmBtnText={t("save")}
        confirmBtnCssClass="popupCloseButton"
        confirmBtnStyle={{
          boxShadow: "none",
        }}
        cancelBtnCssClass="popupCloseButton"
        cancelBtnStyle={{
          boxShadow: "none",
        }}
      >
        <div style={{ textAlign: "left" }}>
          <div style={{ marginBottom: "20px" }}>
            <MDInput
              type="input"
              label={t("ransomware.victim")}
              fullWidth
              value={additionVictim}
              onChange={(e) => {
                setAdditionVictim(e.target.value);
              }}
            />
          </div>
          <div style={{ marginBottom: "20px" }}>
            <MDInput
              type="input"
              label={t("ransomware.site")}
              fullWidth
              value={additionVictimUrl}
              onChange={(e) => {
                setAdditionVictimUrl(e.target.value);
              }}
            />
          </div>
          <div style={{ marginBottom: "20px" }}>
            <MDInput
              type="input"
              label={t("ransomware.attack_group")}
              fullWidth
              value={additionGang}
              onChange={(e) => {
                setAdditionGang(e.target.value);
              }}
            />
          </div>
          <div style={{ marginBottom: "20px" }}>
            <MDInput
              type="input"
              label={t("ransomware.sector")}
              fullWidth
              value={additionSector}
              onChange={(e) => {
                setAdditionSector(e.target.value);
              }}
            />
          </div>
          {/* DETECTION */}
          <div style={{ position: "relative", marginBottom: "20px" }}>
            <Datetime
              inputProps={{
                placeholder: t("ransomware.detection"),
                value: additionDate,
              }}
              closeOnSelect
              timeFormat={false}
              value={additionDate}
              onChange={(e) => {
                setAdditionDate(moment(e).format("L"));
              }}
            />
            {additionDate ? (
              <IconButton
                onClick={() => {
                  setAdditionDate("");
                }}
                style={{ position: "absolute", right: "0", top: "6px" }}
                size="small"
                disableRipple
              >
                <Icon fontSize="medium">close</Icon>
              </IconButton>
            ) : null}
          </div>
          {/* COUNTRY */}
          <Autocomplete
            label={t("ransomware.nation")}
            onSelect={selectAutocompleteAddition}
            list={Object.keys(countryCode).map((el) => ({
              label: t(`countryCode.${el}`),
              value: el,
            }))}
          />
          {/* COMPANY SIZE */}
          <select
            onChange={(e) => {
              if (e.target.value === "-") {
                setAdditionCompanySize("");
              } else {
                setAdditionCompanySize(e.target.value);
              }
            }}
            value={additionCompanySize}
            className="selectDefault"
            style={{
              padding: "0.75rem",
              borderRadius: "0.375rem",
              borderColor: "#d2d6da",
              marginBottom: "30px",
              marginTop: "20px",
            }}
          >
            <option value="-">-</option>
            <option value="L">L ({t("companySize.L")})</option>
            <option value="M">M ({t("companySize.M")})</option>
            <option value="U">U ({t("companySize.U")})</option>
            <option value="S">S ({t("companySize.S")})</option>
            <option value="P">P ({t("companySize.P")})</option>
          </select>
          {/* ISHIDDEN */}
          <div>
            <input
              type="checkbox"
              style={{ marginBottom: "30px" }}
              value={additionIsHidden}
              onChange={() => {
                setAdditionIsHidden(!additionIsHidden);
              }}
            />{" "}
            <span style={{ fontSize: "14px" }}>Is Hidden</span>
          </div>
          {/* <UploadRansomwareImage /> */}
          <div style={{ fontSize: "16px" }}>Image:</div>
          <input
            type="file"
            name="myImage"
            onChange={(event) => {
              const file = event.target.files[0];
              let newFileName = "";
              const opt = {
                filename: "",
                type: "",
              };
              if (file.name) {
                opt.filename = file.name;
                newFileName = file.name;
                const split = file.name.split(".");
                if (split.length > 1) {
                  opt.type = split[split.length - 1];
                }
              }
              readFile(file).then((aaa) => {
                const [dataApp, datas] = aaa.split(";", 2);
                const [enc, base64] = datas.split(",", 2);
                uploadFile(base64, opt)
                  // uploadFile(aaa, opt)
                  .then((resUp) => {
                    if (resUp && resUp.data && resUp.data.s3Key) {
                      setImage(resUp.data.s3Key);
                    }
                  })
                  .catch((resUpErr) => {
                    // eslint-disable-next-line
                      console.log("error upload file", resUpErr);
                  });
              });
              // setSelectedImage(event.target.files[0]);
            }}
          />
          {image ? (
            <div className="ransomware_image_container">
              <img
                className="ransomware_image"
                alt="Ransomware image"
                title="Ransomware image"
                src={`${process.env.REACT_APP_S3_RANSOMWARE_CDN}/${image}`}
              />
              <button
                className="delete_image_ransomware_button"
                type="button"
                onClick={() => {
                  setImage("");
                }}
              >
                <Icon fontSize="medium">close</Icon>
              </button>
            </div>
          ) : null}
        </div>
        <div className="popupButtonEdit">
          <Tooltip title={t("close")} placement="top">
            <IconButton
              onClick={() => {
                setAdding(false);
              }}
              size="small"
              disableRipple
            >
              <Icon fontSize="medium">close</Icon>
            </IconButton>
          </Tooltip>
        </div>
      </SweetAlert>
    );
  }

  const selectAutocomplete = (element) => {
    setPage(1);
    setNation(element);
  };

  const selectAutocompleteAddition = (element) => {
    setAdditionCountry(element);
  };

  const selectSector = (element) => {
    setPage(1);
    setSector(element);
  };

  const selectGang = (element) => {
    setPage(1);
    setGang(element);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <h2 className="page-title">{t("ransomware.ransomware_attacks")}.</h2>
      {!(serverError.ransomwaresCounters && serverError.ransomwaresTable) ? (
        <>
          <MDBox py={2}>
            <MDBox>
              {!serverError.ransomwaresCounters ? (
                <Grid container spacing={3}>
                  {ransomwareCounter ? (
                    <Grid item xs={12} md={6} xl={4}>
                      <StatisticsCounter
                        counter={ransomwareCounter.toString()}
                        color="#a019ce"
                        label1={t("ransomware.victims_label1")}
                        label2={t("ransomware.victims_label2")}
                        image={
                          <img
                            alt="Ransomware victims"
                            title="Ransomware victims"
                            src={iconVictims}
                          />
                        }
                      />
                    </Grid>
                  ) : null}
                  {ransomwareMonthCounter ? (
                    <Grid item xs={12} md={6} xl={4}>
                      <StatisticsCounter
                        counter={ransomwareMonthCounter.toString()}
                        color="#54c1fb"
                        label1={t("ransomware.calendar_label1")}
                        label2={t("ransomware.calendar_label2")}
                        image={
                          <img
                            alt="Ransomware last 30 days"
                            title="Ransomware last 30 days"
                            src={iconCalendar}
                          />
                        }
                      />
                    </Grid>
                  ) : null}
                  {ransomwareCountryCounter ? (
                    <Grid item xs={12} md={6} xl={4}>
                      <StatisticsCounter
                        counter={ransomwareCountryCounter.toString()}
                        color="#15d2b0"
                        label1={t("ransomware.country_label1")}
                        /* eslint no-nested-ternary: 0 */
                        label2={`${t("ransomware.country_label2")} ${
                          user && user.language
                            ? user.language === "en"
                              ? t(`countryCode.GB`)
                              : t(`countryCode.${user.language.toUpperCase()}`)
                            : ""
                        } ${t("ransomware.country_label3")}`}
                        image={
                          <img
                            alt="Ransomware per country"
                            title="Ransomware per country"
                            src={iconCountry}
                          />
                        }
                      />
                    </Grid>
                  ) : null}
                </Grid>
              ) : (
                <>
                  <ServerError />
                </>
              )}
            </MDBox>
          </MDBox>
          <div style={{ margin: "30px 0", width: "100%", textAlign: "left" }}>
            <IconButton
              onClick={() => {
                setShowFilters(!showFilters);
              }}
              style={{ marginRight: "15px" }}
              className="default-button"
              size="small"
              disableRipple
            >
              {t("filter")}{" "}
              {showFilters ? (
                <ArrowDropUpIcon style={{ marginLeft: "10px" }} fontSize="medium" />
              ) : (
                <ArrowDropDownIcon style={{ marginLeft: "10px" }} fontSize="medium" />
              )}
            </IconButton>
            <IconButton
              onClick={() => {
                refreshAttacks();
              }}
              className="default-button"
              style={{ marginRight: "15px" }}
              size="small"
              disableRipple
            >
              {t("refresh")}{" "}
              <Icon style={{ marginLeft: "10px" }} fontSize="medium">
                replay
              </Icon>
            </IconButton>
            {attacks && attacks.length ? (
              <div className="csv-container-downloader">
                <CsvDownloader
                  datas={attacks.map((att) => ({
                    date: att.detection
                      ? `${moment(att.detection).format("DD")}/${moment(att.detection).format(
                          "MM"
                        )}/${moment(att.detection).format("YYYY")}`
                      : "",
                    victim: att.victim ? att.victim : "",
                    attack_group: att.gang || att.group || "",
                    industry: att.sector ? att.sector : "",
                    country: att.country ? att.country : "",
                  }))}
                  columns={[
                    {
                      id: "date",
                      displayName: t("ransomware.csv_date"),
                    },
                    {
                      id: "victim",
                      displayName: t("ransomware.victim"),
                    },
                    {
                      id: "attack_group",
                      displayName: t("ransomware.attack_group"),
                    },
                    {
                      id: "industry",
                      displayName: t("ransomware.sector"),
                    },
                    {
                      id: "country",
                      displayName: t("ransomware.country"),
                    },
                  ]}
                  filename={`ransomware ${moment().format("YYYY-MM-DD")}`}
                  separator=";"
                  text={t("ransomware.download_csv_btn")}
                >
                  <MDButton
                    disableRipple
                    disableElevation
                    type="button"
                    variant="text"
                    sx={{ color: "#2D75DA", padding: "0px" }}
                  >
                    <DownloadIcon className="csv-download-icon" />
                    {t("ransomware.download_csv_btn")}
                  </MDButton>
                </CsvDownloader>
              </div>
            ) : null}
            {user && user.role && user.role === "admin" ? (
              <IconButton
                onClick={() => {
                  setAdding(true);
                }}
                className="default-button"
                style={{ marginLeft: "15px" }}
                size="small"
                disableRipple
              >
                {t("add")}{" "}
                <Icon style={{ marginLeft: "10px" }} fontSize="medium">
                  add
                </Icon>
              </IconButton>
            ) : null}
          </div>
          {showFilters ? (
            <div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <div className="label-filters" style={{ marginBottom: "10px" }}>
                    {t("search")}:
                  </div>
                  <div style={{ display: "flex" }}>
                    <MDInput
                      type="input"
                      label={t("ransomware.victim")}
                      sx={{ marginRight: "10px" }}
                      fullWidth
                      onChange={(e) => {
                        setVictim(e.target.value);
                        setPage(1);
                      }}
                    />
                    {/* <MDInput
                  type="input"
                  label={t("ransomware.site")}
                  sx={{ marginRight: "10px" }}
                  fullWidth
                  onChange={(e) => {
                    setDomain(e.target.value);
                  }}
                /> */}
                    {/* <MDInput
                      type="input"
                      label={t("ransomware.attack_group")}
                      sx={{ marginRight: "10px" }}
                      fullWidth
                      onChange={(e) => {
                        setGang(e.target.value);
                        setPage(1);
                      }}
                    /> */}
                    <Autocomplete
                      label={t("ransomware.attack_group")}
                      onSelect={selectGang}
                      list={allGangs}
                    />
                    <Autocomplete
                      label={t("ransomware.sector")}
                      onSelect={selectSector}
                      list={allSectors}
                    />
                    <Autocomplete
                      label={t("ransomware.nation")}
                      onSelect={selectAutocomplete}
                      list={Object.keys(countryCode).map((el) => ({
                        label: t(`countryCode.${el}`),
                        value: el,
                      }))}
                    />
                    {getCalendar(dateFrom, setDateFrom, t("ransomware.date_from"))}
                    {getCalendar(dateTo, setDateTo, t("ransomware.date_to"))}
                  </div>
                </div>
                <div style={{ textAlign: "right" }}>
                  <div style={{ fontSize: "16px", marginBottom: "10px" }}>{t("displayed")}:</div>
                  <select
                    onChange={(e) => {
                      setLimit(e.target.value);
                    }}
                    value={limit}
                    className="selectDefault"
                    style={{
                      padding: "0.75rem",
                      borderRadius: "0.375rem",
                      borderColor: "#d2d6da",
                      marginBottom: "30px",
                    }}
                  >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                  </select>
                </div>
              </div>
              <div style={{ marginBottom: "50px" }}>
                <IconButton
                  onClick={() => {
                    setCustomFilters(!customFilters);
                  }}
                  className={customFilters ? "default-button reverse" : "default-button"}
                  style={{ marginRight: "15px" }}
                  size="small"
                  disableRipple
                >
                  {t("ransomware.custom_filters")}{" "}
                  <Icon style={{ marginLeft: "10px" }} fontSize="medium">
                    personal
                  </Icon>
                </IconButton>
                <input
                  type="checkbox"
                  style={{ marginBottom: "30px", marginLeft: "30px" }}
                  value={showHidden}
                  onChange={() => {
                    setShowHidden(!showHidden);
                  }}
                />{" "}
                <span style={{ fontSize: "14px" }}>{t("ransomware.show_hidden")}</span>
              </div>
            </div>
          ) : null}
          <div className="popupInfoContainer">
            {popup ? (
              <SweetAlert
                style={{ display: "block", position: "relative", textAlign: "left" }}
                title=""
                onConfirm={() => {
                  setPopup(false);
                  setViewElement(false);
                }}
                confirmBtnText={editing ? t("save") : t("close")}
                confirmBtnCssClass="popupCloseButton"
                confirmBtnStyle={{
                  boxShadow: "none",
                }}
              >
                <div className="popupInfoTitleContainer">
                  <Icon fontSize="medium">dangerous</Icon>
                  <h2>{viewElement.victim ? viewElement.victim : ""}</h2>
                </div>
                {viewElement.country ? (
                  <div className="popupLabel">
                    <Icon fontSize="medium">flag</Icon>
                    <span style={{ fontWeight: "bold" }}>{viewElement.country}</span>
                  </div>
                ) : null}
                {viewElement.victim_url ? (
                  <div className="popupLabel">
                    <Icon fontSize="medium">language</Icon>
                    <span style={{ fontWeight: "bold" }}>{viewElement.victim_url}</span>
                  </div>
                ) : null}
                {viewElement.detection ? (
                  <div className="popupLabel">
                    <Icon fontSize="medium">event</Icon>
                    <span style={{ fontWeight: "bold" }}>
                      {moment(new Date(viewElement.detection)).format("DD/MM/YYYY")}
                    </span>
                  </div>
                ) : null}
                {viewElement.gang || viewElement.group ? (
                  <div className="popupLabel">
                    <Icon fontSize="medium">clear</Icon>
                    <span style={{ fontWeight: "bold" }}>
                      {viewElement.gang || viewElement.group}
                    </span>
                  </div>
                ) : null}
                {viewElement.sector ? (
                  <div className="popupLabel">
                    <Icon fontSize="medium">flag</Icon>
                    <span style={{ fontWeight: "bold" }}>{viewElement.sector}</span>
                  </div>
                ) : null}
                {editing ? null : (
                  <div className="popupExtraInfo">
                    {viewElement.status ? (
                      <div className="popupLabel">
                        <Icon fontSize="medium">moving</Icon>
                        <span style={{ fontWeight: "bold" }}>{viewElement.status}</span>
                      </div>
                    ) : null}
                    {viewElement.operator ? (
                      <div className="popupLabel">
                        <Icon fontSize="medium">person</Icon>
                        <span style={{ fontWeight: "bold" }}>{viewElement.operator}</span>
                        {viewElement.managementDate ? (
                          <span style={{ display: "flex" }}>
                            <Icon fontSize="medium">event</Icon>
                            <span style={{ fontWeight: "bold", lineHeight: "24px" }}>
                              {moment(viewElement.managementDate).format("l")}
                            </span>
                          </span>
                        ) : null}
                      </div>
                    ) : null}
                    {viewElement.note ? (
                      <div className="popupLabel">
                        <Icon fontSize="medium">assignment</Icon>
                        <span style={{ fontWeight: "bold" }}>{viewElement.note}</span>
                      </div>
                    ) : null}
                  </div>
                )}
                <div className="popupButtonEdit">
                  <Tooltip title={t("close")} placement="top">
                    <IconButton
                      onClick={() => {
                        setEditing(false);
                        setPopup(false);
                      }}
                      size="small"
                      disableRipple
                    >
                      <Icon fontSize="medium">close</Icon>
                    </IconButton>
                  </Tooltip>
                </div>
              </SweetAlert>
            ) : null}
            {adding ? getAdding() : null}
            {popupSuccess ? (
              <SweetAlert
                style={{ display: "block" }}
                title={t("ransomware.record_updated")}
                success
                onConfirm={() => {
                  setPopupSuccess(false);
                }}
                confirmBtnText={t("ok")}
                confirmBtnCssClass="popupCloseButton"
                confirmBtnStyle={{
                  boxShadow: "none",
                }}
              >
                {t("ransomware.record_updated_text")}
              </SweetAlert>
            ) : null}
            {editingElement && editingElement.id ? (
              <EditRansomwareComponent
                element={editingElement}
                closeFunction={() => {
                  setEditingElement(null);
                }}
                successFunction={() => {
                  setEditingElement(null);
                  refreshAttacks();
                  setPopupSuccess(true);
                }}
              />
            ) : null}
            {imageToView ? (
              <SweetAlert
                style={{ display: "block" }}
                title=""
                onConfirm={() => {
                  setImageToView("");
                }}
                confirmBtnText={t("ok")}
                confirmBtnCssClass="popupCloseButton"
                confirmBtnStyle={{
                  boxShadow: "none",
                }}
              >
                <img
                  alt="Ransomware"
                  title="Ransomware"
                  src={`${process.env.REACT_APP_S3_RANSOMWARE_CDN}/${imageToView}`}
                  className="ransomware_big_image"
                />
              </SweetAlert>
            ) : null}
            <MDBox mb={2} display="flex" justifyContent="center">
              <MDBox display="flex" justifyContent="center" textAlign="center">
                <ReportProblemIcon fontSize="medium" />
                <MDTypography fontSize="medium" ml={1}>
                  {t("ransomware.disclamer")}
                </MDTypography>
              </MDBox>
            </MDBox>
            {!serverError.ransomwaresTable ? (
              <>
                {isRequestReady ? (
                  <div>
                    {originalAttacks && originalAttacks.length > 0 ? (
                      <div className="table_users_list">
                        <table
                          className="table-content ransomwares"
                          cellSpacing="0"
                          cellPadding="0"
                        >
                          <thead>
                            <tr>
                              <th
                                style={{
                                  cursor: "pointer",
                                  width: "10%",
                                }}
                                onClick={() => {
                                  if (orderBy === "dateReverse") {
                                    setOrderBy("date");
                                  } else {
                                    setOrderBy("dateReverse");
                                  }
                                }}
                              >
                                {orderBy === "date" ? (
                                  <ArrowDropDownIcon
                                    style={{ marginBottom: "-7px", marginRight: "15px" }}
                                    fontSize="medium"
                                  />
                                ) : null}
                                {orderBy === "dateReverse" ? (
                                  <ArrowDropUpIcon
                                    style={{ marginBottom: "-7px", marginRight: "15px" }}
                                    fontSize="medium"
                                  />
                                ) : null}
                                {t("date")}
                              </th>
                              <th
                                style={{
                                  width: "20%",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  if (orderBy === "victim") {
                                    setOrderBy("victimReverse");
                                  } else {
                                    setOrderBy("victim");
                                  }
                                }}
                              >
                                {orderBy === "victim" ? (
                                  <ArrowDropDownIcon
                                    style={{ marginBottom: "-7px", marginRight: "15px" }}
                                    fontSize="medium"
                                  />
                                ) : null}
                                {orderBy === "victimReverse" ? (
                                  <ArrowDropUpIcon
                                    style={{ marginBottom: "-7px", marginRight: "15px" }}
                                    fontSize="medium"
                                  />
                                ) : null}
                                {t("ransomware.victim")}
                              </th>
                              {/* <th
                            style={{
                              width: "20%",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              if (orderBy === "victim_url") {
                                setOrderBy("victim_urlReverse");
                              } else {
                                setOrderBy("victim_url");
                              }
                            }}
                          >
                            {orderBy === "victim_url" ? (
                              <ArrowDropDownIcon
                                style={{ marginBottom: "-7px", marginRight: "15px" }}
                                fontSize="medium"
                              />
                            ) : null}
                            {orderBy === "victim_urlReverse" ? (
                              <ArrowDropUpIcon
                                style={{ marginBottom: "-7px", marginRight: "15px" }}
                                fontSize="medium"
                              />
                            ) : null}
                            {t("ransomware.site")}
                          </th> */}
                              <th
                                style={{
                                  width: "20%",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  if (orderBy === "group") {
                                    setOrderBy("groupReverse");
                                  } else {
                                    setOrderBy("group");
                                  }
                                }}
                              >
                                {orderBy === "group" ? (
                                  <ArrowDropDownIcon
                                    style={{ marginBottom: "-7px", marginRight: "15px" }}
                                    fontSize="medium"
                                  />
                                ) : null}
                                {orderBy === "groupReverse" ? (
                                  <ArrowDropUpIcon
                                    style={{ marginBottom: "-7px", marginRight: "15px" }}
                                    fontSize="medium"
                                  />
                                ) : null}
                                {t("ransomware.attack_group")}
                              </th>
                              <th
                                style={{
                                  width: "10%",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  if (orderBy === "sector") {
                                    setOrderBy("sectorReverse");
                                  } else {
                                    setOrderBy("sector");
                                  }
                                }}
                              >
                                {orderBy === "sector" ? (
                                  <ArrowDropDownIcon
                                    style={{ marginBottom: "-7px", marginRight: "15px" }}
                                    fontSize="medium"
                                  />
                                ) : null}
                                {orderBy === "sectorReverse" ? (
                                  <ArrowDropUpIcon
                                    style={{ marginBottom: "-7px", marginRight: "15px" }}
                                    fontSize="medium"
                                  />
                                ) : null}
                                {t("ransomware.sector")}
                              </th>
                              <th
                                style={{
                                  width: "10%",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  if (orderBy === "country") {
                                    setOrderBy("countryReverse");
                                  } else {
                                    setOrderBy("country");
                                  }
                                }}
                              >
                                {orderBy === "country" ? (
                                  <ArrowDropDownIcon
                                    style={{ marginBottom: "-7px", marginRight: "15px" }}
                                    fontSize="medium"
                                  />
                                ) : null}
                                {orderBy === "countryReverse" ? (
                                  <ArrowDropUpIcon
                                    style={{ marginBottom: "-7px", marginRight: "15px" }}
                                    fontSize="medium"
                                  />
                                ) : null}
                                {t("ransomware.country")}
                              </th>
                              {user && user.role && user.role === "admin" ? (
                                <th
                                  style={{
                                    width: "5%",
                                  }}
                                >
                                  {t("edit")}
                                </th>
                              ) : null}
                            </tr>
                          </thead>
                          <tbody style={{ fontSize: "14px" }}>
                            {attacks.map((el, index) => (
                              <tr key={`ransomware-${index.toString()}`}>
                                <td>
                                  {moment(el.detection).format("DD")}/
                                  {moment(el.detection).format("MM")}/
                                  {moment(el.detection).format("YYYY")}
                                  {el.image ? (
                                    <IconButton
                                      onClick={() => {
                                        setImageToView(el.image);
                                      }}
                                      className="blue-color"
                                      size="small"
                                      disableRipple
                                    >
                                      <ImageIcon />
                                    </IconButton>
                                  ) : null}
                                </td>
                                <td>
                                  <div className="ransomware-table-victims-container">
                                    {el.victim}
                                  </div>
                                  {el.victim_url && el.victim_url !== "unknown" ? (
                                    <IconButton
                                      onClick={() => {
                                        if (el.victim_url.indexOf("http") !== 0)
                                          el.victim_url = `//${el.victim_url}`;

                                        window.open(el.victim_url, "_blank");
                                      }}
                                      className="blue-color"
                                      size="small"
                                      disableRipple
                                    >
                                      <OpenInNewIcon />
                                    </IconButton>
                                  ) : null}
                                </td>
                                {/* <td>{el.victim_url ? el.victim_url : ""}</td> */}
                                <td>{el.gang || el.group || ""}</td>
                                <td style={{ maxWidth: "200px" }}>{el.sector ? el.sector : ""}</td>
                                <td>{el.country ? t(`countryCode.${el.country}`) : ""}</td>
                                {user && user.role && user.role === "admin" ? (
                                  <td>
                                    <IconButton
                                      onClick={() => {
                                        setEditingElement(el);
                                      }}
                                      className="blue-color"
                                      size="small"
                                      disableRipple
                                    >
                                      <EditIcon />
                                    </IconButton>
                                  </td>
                                ) : null}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        {pages && pages > 1 ? (
                          <Pagination setPage={setPage} pages={pages} active={page} />
                        ) : null}
                      </div>
                    ) : (
                      <div>{t("ransomware.no_ransomware_yet")}</div>
                    )}
                  </div>
                ) : (
                  <div className="loader" />
                )}
              </>
            ) : (
              <ServerError />
            )}
          </div>
        </>
      ) : (
        <MDBox className="server-error-container">
          <ServerError />
        </MDBox>
      )}
    </DashboardLayout>
  );
}

export default Ransomwares;
